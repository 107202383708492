<template>
  <v-content class="pa-0">
    <v-row justify="center" no-gutters>
      <v-col cols="12">

        <v-card-actions class="pa-0">
          <h3> <strong> Nuevo concepto </strong></h3>
          <v-spacer></v-spacer>
          <v-btn color="error" fab small @click="$emit('modal', false)"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-actions>

        <v-row class="mt-3">


          <v-col cols="12">
              <v-select v-model="prodserv" :items="[{desc:'Servicio', val:'servicio'}, {desc:'Producto', val:'producto'}]" item-title="desc" item-value="val" label="Producto/Servicio" density="compact" variant="outlined"></v-select>
              <!-- <v-text-field v-model="prodserv" label="Producto o servicio" density="compact" variant="outlined"></v-text-field> -->
          </v-col>


          <v-col cols="12">
            
            <v-combobox
              v-model="concepto"
              :label="'Buscar '+ prodserv"
              :items="prodserv == 'servicio' ? servicios : productos"
              :item-title="prodserv == 'servicio' ? 'nombre_servicio' : 'nombre_producto'"
              :item-value="prodserv == 'servicio' ? 'id_servicio ' : 'id_producto'"
              :no-data-text="'No se han encontraron'+( prodserv == 'servicio'  ? 'servicios' : 'productos') "
              density="compact" variant="outlined"
            ></v-combobox>
          </v-col>

          <v-col cols="12">
             <v-textarea v-model="descripcion" :label="'Descripción del '+prodserv" density="compact" variant="outlined"></v-textarea>
          </v-col>

          <v-col cols="12" v-show="prodserv == 'producto'">
              <v-text-field type="number" v-model="cantidad" label="Cantidad" density="compact" variant="outlined"></v-text-field>
          </v-col>

          <!-- <v-col cols="12">
            <v-select v-model="unidad" label="Unidad" density="compact" variant="outlined" :items="['Pieza', 'Servicio']"></v-select>
          </v-col> -->

        </v-row>

        <v-card-actions class="pa-0 mt-3">
          <v-spacer></v-spacer>
          <v-btn :disabled="saving" persistent :loading="saving" dark center class="white--text" color="green"
            @click="nuevoConcepto()">
            Agregar
          </v-btn>
        </v-card-actions>

      </v-col>
    </v-row>
  </v-content>
</template>

<script>

import { mapState } from "vuex";
import moment from 'moment';

import DetePikerInput from "@/components/DatePikerInput.vue";

export default {

  components: {
    DetePikerInput
  },

  setup() {
   
    },

  props: {
      is_new:Boolean,
      id_levantamiento: String,
      cargarItems: Function
  },

  data() {
    return {
      
      saving: false,

      prodserv:'servicio',
      id_prodserv:null,
      item:null,

      servicios:[],
      productos:[],

      unidad:'Servicio',
      concepto:'',
      descripcion:'',
      cantidad:1

    };
  },

  async created() {
    await this.$axios
    .get("consulta.prodserv")
    .then((r) => {
      this.servicios=r.data.servicios;
      this.productos=r.data.productos;
    });
  },
  computed: {
    ...mapState("Session", ['session_key', 'user','permisos', 'loggedin']),

    // isItemValid() {
    //   return this.prodserv == 'servicios' ? this.servicios.includes(this.concepto) : this.productos.includes(this.concepto);
    // },

  },
  mounted() {
  },
  watch: {

   prodserv(){
    this.unidad = this.prodserv == 'servicio' ? 'Servicio' : 'Pieza'
   }
  },

  methods: {
    // checkSelectedItem() {
    //   if (!this.isItemValid) {
    //     this.item = this.concepto;
    //   }
    // },
    getIdProdServ(){
      if(typeof this.concepto == 'object'){
        return this.prodserv == 'servicio' ? this.concepto.id_servicio : this.concepto.id_producto;
      } else{
        return null;
      }
    },
    async nuevoConcepto() {

      this.saving=true;
      

      // if (this.prodserv == null) {
      //   window.showAlert({
      //     activo: true,
      //     texto: "Debes ingresar la descripción de un producto o servicio",
      //     color: "error",
      //     vertical: true
      //   });
      //   return false;
      // }
      
      if (this.concepto == '') {
        window.showAlert({
          activo: true,
          texto: "Debes ingresar un "+this.prodserv,
          color: "error",
          vertical: true
        });
        return false;
      }

      // if (this.descripcion == '') {
      //   window.showAlert({
      //     activo: true,
      //     texto: "Debes ingresar una descripción de "+this.prodserv,
      //     color: "error",
      //     vertical: true
      //   });
      //   return false;
      // }

      

      if (this.cantidad == null || parseFloat(this.cantidad) < 1 ) {
        this.showAlert({
          activo: true,
          texto: "Por favor, ingresa una cantidad válida",
          color: "error",
          vertical: true
        });
        return false;
      }
      if (this.unidad == '') {
        window.showAlert({
          activo: true,
          texto: "Debes ingresar la unidad",
          color: "error",
          vertical: true
        });
        return false;
      }

      var me = this;
      
      
      let payload = {
          id_levantamiento:this.id_levantamiento,
          prodserv:this.prodserv,
          id_prodserv:this.getIdProdServ(),
          concepto:(typeof this.concepto == 'string' ? this.concepto : this.concepto.nombre_servicio),
          descripcion:this.descripcion,
          cantidad:this.cantidad,
          unidad:this.unidad 
      }
      if(this.is_new){
        me.$emit('modal', false);
        me.$emit('agregarItem', payload);
      }else {
        this.saving = true;
        await this.$axios
        .post("levantamientos.additem", payload)
        .then(() => {
          window.showAlert({
            activo: true,
            texto: "Concepto agregado con éxito!",
            color: "success",
            vertical: true
          });

          this.cargarItems();

          me.$emit('modal', false);

        })
        .catch((e) => {
          console.log(e);
          window.showAlert({
            activo: true,
            texto: "Lo sentimos! Algo salio mal...",
            color: "error",
            vertical: true
          });
        }).finally(() => {
          this.saving = false;
        });
      }
      
    },
  },
};
</script>

<style>
.disabled {
  pointer-events: none;
  color: #bfcbd9;
  cursor: not-allowed;
  background-image: none;
  background-color: #eef1f6;
  border-color: #d1dbe5;
}
</style>