<template>
    <v-content class="pa-0">
          <v-row justify="center" no-gutters>
              <v-col cols="12">
  
                  <v-card-actions class="pa-0" >
                      <h3> <strong> NUEVA UNIDAD </strong></h3> 
                      <v-spacer></v-spacer>
                      <v-btn color="error" fab small @click="$emit('modal',false)" ><v-icon>mdi-close</v-icon></v-btn>
                  </v-card-actions>
  
                  <v-row class="mt-3">
                    <!--tipo de unidad, clave unidad, nombre, desc, foto,numero serie,   -->
                      <v-col cols="12" >
                        <v-select
                        v-model="payload.id_tipo_unidad" :items="tipo_unidades" item-title="tipo_unidad_nombre" item-value="id_tipo_unidad"  dense
                            hide-details  placeholder="Tipo de unidades " outlined append-icon="mdi-circle-slice-5"
                        ></v-select>
                          
                      </v-col>
  
                      <v-col cols="12">
                          <v-text-field
                              v-model="payload.clave_unidad" label="Clave de la unidad" placeholder="Clave de la unidad" append-icon="domain"
                              hide-details dense filled 
                          ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                          <v-text-field
                              v-model="payload.desc_unidad" label="Descripción de la unidad" placeholder="Número de serie" append-icon="domain"
                              hide-details dense filled 
                          ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                          <v-text-field
                              v-model="payload.numero_serie" label="Número de serie" placeholder="Número de serie" append-icon="domain"
                              hide-details dense filled 
                          ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <v-file-input type="file" @change="handleFileChange"  accept="image/png, image/jpeg"  label="Fotografía de la unidad"></v-file-input>
                      </v-col>
                  </v-row>
  
                  <v-card-actions class="pa-0 mt-3">
                      <v-spacer></v-spacer>
                       <v-btn  
                           :disabled="saving" 
                           persistent 
                           :loading="saving"
                           dark center 
                           class="white--text" 
                           color="blue" 
                           @click="add()">
              AGEGAR UNIDAD
            </v-btn> 
                  </v-card-actions>
  
              </v-col>
          </v-row>
      </v-content >
  </template>
  
  <script>
  import { ref } from 'vue';

  export default {
    
    setup() {
        const foto = ref(null);
        const handleFileChange = (event) => {
            foto.value = event.target.files[0];
        };
        return {
            foto,
            handleFileChange,
        };
    },

    computed: {  },
    components: { },
  
    props: {
      showAlert: Function,
      consulta: Function,
      cliente: Object
    },
  
    data() {
      return {
        saving:false,
        
        tipo_unidades:[],
        payload: {
            id_tipo_unidad:null,
            clave_unidad:null,
            desc_unidad:null,
            numero_serie:null
        }
      };
    },
  
    async created() {
        
        await this.$axios
        .get("consulta.catalogos")
        .then((r) => {
            const obj = new Proxy(r.data, {
                get(target, property) {
                // Implementa el comportamiento deseado para acceder a la propiedad
                if (property in target) {
                    return target[property]; // Devuelve el valor de la propiedad si existe
                } else {
                    return undefined; // Devuelve undefined si la propiedad no existe
                }
                },
                set(target, property, value) {
                target[property] = value;
                return true;
                }
            });
            this.tipo_unidades = obj.tipo_unidades;
            this.tipo_unidades.unshift({
                id_tipo_unidad:null,
                tipo_unidad_nombre:this.tipo_unidades.length > 0 ? "Seleccionar un tipo de unidad" : "Aún no hay tipo de unidades registrados"
            });
        });

    },
  
    mounted() {
  
    },
    
    methods: {
      async add() {
  
        var me = this;
        this.saving = true;

        const formData = new FormData();
        //this.payload = JSON.parse(JSON.stringify(this.payload));
        for (const key in this.payload) {
            if (this.payload.hasOwnProperty(key)) {
                formData.append(key, this.payload[key]);
            }
        }
        formData.append('foto', this.foto);
        formData.append('id_cliente', this.cliente.id_usr);
      
        await this.$axios
          .post("unidades.create", formData)
          .then((r) => {
            //alert...and reload table of 
  
            this.showAlert({
              activo: true, 
              texto: "Unidad agregada con éxito", 
              color: "success", 
              vertical: true
            });
  
            this.consulta();
  
            me.$emit('modal',false);
  
          })
          .catch((e) => {
            console.log(e);
            this.showAlert({
              activo: true, 
              texto:"Lo sentimos! Algo salio mal...", 
              color: "error", 
              vertical: true
            });
          }).finally( () => {
            this.saving = false;
            //close modal?
          } );
      },
    },
  };
  </script>