<template>
    <div>


        <div class="card_contenedor">
            <v-row no-gutters>
                <v-col cols="12" md="7">
                    <div class="center_div">
                        <span class="title_card_gris">FRECUENCIA DE OPERACIÓN</span>
                    </div>
                    <v-sheet class="pa-2">
                        <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                            variant="outlined" label="Ciclos por hora" class="ml-3"
                            v-model="item_lev.fabr_esp_ciclo_hora"></v-text-field>
                        <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                            variant="outlined" label="Duración del ciclo más largo" class="ml-3"
                            v-model="item_lev.fabr_esp_ciclo_mas_lg"></v-text-field>
                        <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                            variant="outlined" label="Frecuencia de uso" class="ml-3"
                            v-model="item_lev.fabr_esp_frec_uso"></v-text-field>
                    </v-sheet>
                </v-col>
                <v-col cols="12" md="5">
                    <v-sheet class="pa-2">
                        <div class="center_div">
                            <span class="title_card_gris">CARACTERISTICAS DE LA PIEZA</span>
                        </div>


                        <v-row no-gutters>
                            <v-col cols="12" md="3">
                                <v-checkbox :readonly="item_lev.estatus_cot != null" density="comfortable"
                                    v-model="item_lev.fabr_esp_carct_pieza" value="1" label="Humedad"></v-checkbox>
                                <v-checkbox :readonly="item_lev.estatus_cot != null" density="comfortable"
                                    v-model="item_lev.fabr_esp_carct_pieza" value="2" label="Con polvo"
                                    class="mg_tn"></v-checkbox>
                                <v-checkbox :readonly="item_lev.estatus_cot != null" density="comfortable"
                                    v-model="item_lev.fabr_esp_carct_pieza" value="3" label="Seca"
                                    class="mg_tn"></v-checkbox>
                            </v-col>

                            <v-col cols="12" md="3">
                                <v-checkbox :readonly="item_lev.estatus_cot != null" density="comfortable"
                                    v-model="item_lev.fabr_esp_carct_pieza" value="4" label="Pintada"></v-checkbox>
                                <v-checkbox :readonly="item_lev.estatus_cot != null" density="comfortable"
                                    v-model="item_lev.fabr_esp_carct_pieza" value="5" label="Caliente"
                                    class="mg_tn"></v-checkbox>
                                <v-checkbox :readonly="item_lev.estatus_cot != null" density="comfortable"
                                    v-model="item_lev.fabr_esp_carct_pieza" value="6" label="Metalica"
                                    class="mg_tn"></v-checkbox>
                            </v-col>

                            <v-col cols="12" md="3">
                                <v-checkbox :readonly="item_lev.estatus_cot != null" density="comfortable"
                                    v-model="item_lev.fabr_esp_carct_pieza" value="7" label="Cristal"></v-checkbox>
                                <v-checkbox :readonly="item_lev.estatus_cot != null" density="comfortable"
                                    v-model="item_lev.fabr_esp_carct_pieza" value="8" label="Cerámica"
                                    class="mg_tn"></v-checkbox>
                                <v-checkbox :readonly="item_lev.estatus_cot != null" density="comfortable"
                                    v-model="item_lev.fabr_esp_carct_pieza" value="9" label="Plástica"
                                    class="mg_tn"></v-checkbox>
                            </v-col>

                            <v-col cols="12" md="3">
                                <v-checkbox :readonly="item_lev.estatus_cot != null" density="comfortable"
                                    v-model="item_lev.fabr_esp_carct_pieza" value="10" label="Uretano"></v-checkbox>
                                <v-checkbox :readonly="item_lev.estatus_cot != null" density="comfortable"
                                    v-model="item_lev.fabr_esp_carct_pieza" value="11" label="Madera"
                                    class="mg_tn"></v-checkbox>
                                <v-checkbox :readonly="item_lev.estatus_cot != null" density="comfortable"
                                    v-model="item_lev.fabr_esp_carct_pieza" value="12" label="Con aceite"
                                    class="mg_tn"></v-checkbox>
                            </v-col>
                        </v-row>

                        <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                            variant="outlined" label="Temperatura de la pieza °C" class="ml-3"
                            v-model="item_lev.fabr_esp_temp_piz_c"></v-text-field>


                    </v-sheet>
                </v-col>
            </v-row>
        </div>


        <div class="card_contenedor">
            <div class="center_div">
                <span class="title_card_gris">GEOMETRÍA DE LA PIEZA</span>
            </div>
            <br />

            <v-row no-gutters>
                <v-col cols="12" md="6">
                    <v-table style="margin: 5px;">
                        <thead>
                            <tr>
                                <th class="text-center header_table_uno text-white">
                                    MOD.
                                </th>
                                <th class="text-center header_table_uno text-white">
                                    PESO (KG)
                                </th>
                                <th class="text-center header_table_uno text-white">
                                    LARGO (mm) A
                                </th>

                                <th class="text-center header_table_uno text-white">
                                    ANCHO (mm) B
                                </th>
                                <th class="text-center header_table_uno text-white">
                                    ESPESOR (mm) C
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>
                                    <v-text-field :readonly="item_lev.estatus_cot != null" variant="outlined"
                                        density="compact" class="mt-2" v-model="item_lev.fabr_esp_ps_uno"></v-text-field>
                                </td>
                                <td>
                                    <v-text-field :readonly="item_lev.estatus_cot != null" variant="outlined"
                                        density="compact" class="mt-2" v-model="item_lev.fabr_esp_lg_uno"></v-text-field>
                                </td>
                                <td>
                                    <v-text-field :readonly="item_lev.estatus_cot != null" variant="outlined"
                                        density="compact" class="mt-2" v-model="item_lev.fabr_esp_anc_uno"></v-text-field>
                                </td>
                                <td>
                                    <v-text-field :readonly="item_lev.estatus_cot != null" variant="outlined"
                                        density="compact" class="mt-2" v-model="item_lev.fabr_esp_esp_uno"></v-text-field>
                                </td>
                            </tr>

                            <tr>
                                <td>2</td>
                                <td>
                                    <v-text-field :readonly="item_lev.estatus_cot != null" variant="outlined"
                                        density="compact" class="mt-2" v-model="item_lev.fabr_esp_ps_dos"></v-text-field>
                                </td>
                                <td>
                                    <v-text-field :readonly="item_lev.estatus_cot != null" variant="outlined"
                                        density="compact" class="mt-2" v-model="item_lev.fabr_esp_lg_dos"></v-text-field>
                                </td>
                                <td>
                                    <v-text-field :readonly="item_lev.estatus_cot != null" variant="outlined"
                                        density="compact" class="mt-2" v-model="item_lev.fabr_esp_anc_dos"></v-text-field>
                                </td>
                                <td>
                                    <v-text-field :readonly="item_lev.estatus_cot != null" variant="outlined"
                                        density="compact" class="mt-2" v-model="item_lev.fabr_esp_esp_dos"></v-text-field>
                                </td>
                            </tr>


                            <tr>
                                <td>3</td>
                                <td>
                                    <v-text-field :readonly="item_lev.estatus_cot != null" variant="outlined"
                                        density="compact" class="mt-2" v-model="item_lev.fabr_esp_ps_tres"></v-text-field>
                                </td>
                                <td>
                                    <v-text-field :readonly="item_lev.estatus_cot != null" variant="outlined"
                                        density="compact" class="mt-2" v-model="item_lev.fabr_esp_lg_tres"></v-text-field>
                                </td>
                                <td>
                                    <v-text-field :readonly="item_lev.estatus_cot != null" variant="outlined"
                                        density="compact" class="mt-2" v-model="item_lev.fabr_esp_anc_tres"></v-text-field>
                                </td>
                                <td>
                                    <v-text-field :readonly="item_lev.estatus_cot != null" variant="outlined"
                                        density="compact" class="mt-2" v-model="item_lev.fabr_esp_esp_tres"></v-text-field>
                                </td>
                            </tr>


                            <tr>
                                <td>4</td>
                                <td>
                                    <v-text-field :readonly="item_lev.estatus_cot != null" variant="outlined"
                                        density="compact" class="mt-2" v-model="item_lev.fabr_esp_ps_cuatro"></v-text-field>
                                </td>
                                <td>
                                    <v-text-field :readonly="item_lev.estatus_cot != null" variant="outlined"
                                        density="compact" class="mt-2" v-model="item_lev.fabr_esp_lg_cuatro"></v-text-field>
                                </td>
                                <td>
                                    <v-text-field :readonly="item_lev.estatus_cot != null" variant="outlined"
                                        density="compact" class="mt-2"
                                        v-model="item_lev.fabr_esp_anc_cuatro"></v-text-field>
                                </td>
                                <td>
                                    <v-text-field :readonly="item_lev.estatus_cot != null" variant="outlined"
                                        density="compact" class="mt-2"
                                        v-model="item_lev.fabr_esp_esp_cuatro"></v-text-field>
                                </td>
                            </tr>


                            <tr>
                                <td>5</td>
                                <td>
                                    <v-text-field :readonly="item_lev.estatus_cot != null" variant="outlined"
                                        density="compact" class="mt-2" v-model="item_lev.fabr_esp_ps_cinco"></v-text-field>
                                </td>
                                <td>
                                    <v-text-field :readonly="item_lev.estatus_cot != null" variant="outlined"
                                        density="compact" class="mt-2" v-model="item_lev.fabr_esp_lg_cinco"></v-text-field>
                                </td>
                                <td>
                                    <v-text-field :readonly="item_lev.estatus_cot != null" variant="outlined"
                                        density="compact" class="mt-2" v-model="item_lev.fabr_esp_anc_cinco"></v-text-field>
                                </td>
                                <td>
                                    <v-text-field :readonly="item_lev.estatus_cot != null" variant="outlined"
                                        density="compact" class="mt-2" v-model="item_lev.fabr_esp_esp_cinco"></v-text-field>
                                </td>
                            </tr>

                            <tr>
                                <td>6</td>
                                <td>
                                    <v-text-field :readonly="item_lev.estatus_cot != null" variant="outlined"
                                        density="compact" class="mt-2" v-model="item_lev.fabr_esp_ps_seis"></v-text-field>
                                </td>
                                <td>
                                    <v-text-field :readonly="item_lev.estatus_cot != null" variant="outlined"
                                        density="compact" class="mt-2" v-model="item_lev.fabr_esp_lg_seis"></v-text-field>
                                </td>
                                <td>
                                    <v-text-field :readonly="item_lev.estatus_cot != null" variant="outlined"
                                        density="compact" class="mt-2" v-model="item_lev.fabr_esp_anc_seis"></v-text-field>
                                </td>
                                <td>
                                    <v-text-field :readonly="item_lev.estatus_cot != null" variant="outlined"
                                        density="compact" class="mt-2" v-model="item_lev.fabr_esp_esp_seis"></v-text-field>
                                </td>
                            </tr>


                            <tr>
                                <td>7</td>
                                <td>
                                    <v-text-field :readonly="item_lev.estatus_cot != null" variant="outlined"
                                        density="compact" class="mt-2" v-model="item_lev.fabr_esp_ps_siete"></v-text-field>
                                </td>
                                <td>
                                    <v-text-field :readonly="item_lev.estatus_cot != null" variant="outlined"
                                        density="compact" class="mt-2" v-model="item_lev.fabr_esp_lg_siete"></v-text-field>
                                </td>
                                <td>
                                    <v-text-field :readonly="item_lev.estatus_cot != null" variant="outlined"
                                        density="compact" class="mt-2" v-model="item_lev.fabr_esp_anc_siete"></v-text-field>
                                </td>
                                <td>
                                    <v-text-field :readonly="item_lev.estatus_cot != null" variant="outlined"
                                        density="compact" class="mt-2" v-model="item_lev.fabr_esp_esp_siete"></v-text-field>
                                </td>
                            </tr>



                        </tbody>
                    </v-table>
                    <span style="color: #757575;"><strong>Nota:</strong> En caso de no ser geometría estándar anexar planos
                        y especificaciones.</span>
                </v-col>
                <v-col cols="12" md="6">
                    <div class="center_element">
                        <v-img :src="require('@/assets/ImgFormatos/FormatosEspeciales/img_fab_esp_1.png')" width="380px"
                            height="380px" />
                    </div>
                    <v-row no-gutters>
                        <v-col cols="12" md="4">
                            <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                                variant="outlined" label="A = Largo (mm)" class="ml-3"
                                v-model="item_lev.fabr_esp_lg_cero"></v-text-field>

                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                                variant="outlined" label="B = Ancho (mm)" class="ml-3"
                                v-model="item_lev.fabr_esp_an_cero"></v-text-field>

                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                                variant="outlined" label="C = Espesor (mm)" class="ml-3"
                                v-model="item_lev.fabr_esp_esp_cero"></v-text-field>

                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>


        <div class="card_contenedor">
            <div class="center_div">
                <span class="title_card_gris">DESCRIPCIÓN DE LA OPERACIÓN</span>
            </div>
            <br />

            <v-sheet class="pa-4">
                <v-row no-gutters>
                    <v-col cols="12" md="12">
                        <span> (Ej.: la pieza se toma horizontalmente de un rack de abasto y se traslada a un centro de
                            maquinado, la pieza se entrega en posición vertical).</span>
                        <v-textarea :readonly="item_lev.estatus_cot != null" rows="3" variant="outlined"
                            v-model="item_lev.fabr_esp_desc_operac" label="Descripción de la operación"></v-textarea>
                    </v-col>
                </v-row>
            </v-sheet>

            <v-row no-gutters>
                <v-col cols="12" md="6">
                    <v-sheet class="pa-4">
                        <div class="center_element" style="margin: 10px;">
                            <span class="text-h5">Alimentación Neumática</span>
                        </div>

                        <div class="center_element">
                            <v-img :src="require('@/assets/ImgFormatos/formato1_maq1.png')" width="200px" height="100px" />
                            <v-checkbox :readonly="item_lev.estatus_cot != null" v-model="item_lev.alimentacion_neumatica"
                                label="" value="1"></v-checkbox>
                        </div>
                        <br />
                        <div class="center_element">
                            <strong>Nota:</strong> Presión de trabajo 0.6 Mpa
                        </div>
                    </v-sheet>
                </v-col>

                <v-col cols="12" md="6">
                    <v-sheet class="pa-2">
                        <div class="center_element" style="margin: 10px;">
                            <span class="text-h5">Alimentación Eléctrica</span>
                        </div>
                        <div class="center_element">
                            <div>
                                <v-img :src="require('@/assets/ImgFormatos/FormatosEspeciales/img_fab_esp_2.png')"
                                    width="330px" height="270px" />
                            </div>
                            <div>
                                <v-checkbox :readonly="item_lev.estatus_cot != null"
                                    v-model="item_lev.alimentacion_electrica" label="480 v" value="480v"></v-checkbox>
                                <v-checkbox :readonly="item_lev.estatus_cot != null"
                                    v-model="item_lev.alimentacion_electrica" label="220 v" value="220v"></v-checkbox>
                                <v-checkbox :readonly="item_lev.estatus_cot != null"
                                    v-model="item_lev.alimentacion_electrica" label="110 v" value="110v"></v-checkbox>
                            </div>
                        </div>
                    </v-sheet>
                </v-col>
            </v-row>
        </div>

        <div class="card_contenedor">
            <div class="center_div">
                <span class="title_card_gris">DESCRIPCIÓN DE LA OPERACIÓN</span>
            </div>
            <br />

            <v-row no-gutters>
                <v-col cols="12" md="2">
                    <v-checkbox :readonly="item_lev.estatus_cot != null" v-model="item_lev.fabr_esp_desc_oper_chk"
                        label="Corrosivo" value="1"></v-checkbox>
                </v-col>
                <v-col cols="12" md="2">
                    <v-checkbox :readonly="item_lev.estatus_cot != null" v-model="item_lev.fabr_esp_desc_oper_chk"
                        label="Exterior" value="2"></v-checkbox>
                </v-col>
                <v-col cols="12" md="2">
                    <v-checkbox :readonly="item_lev.estatus_cot != null" v-model="item_lev.fabr_esp_desc_oper_chk"
                        label="Alta Temperatura" value="3"></v-checkbox>
                </v-col>
                <v-col cols="12" md="2">
                    <v-checkbox :readonly="item_lev.estatus_cot != null" v-model="item_lev.fabr_esp_desc_oper_chk"
                        label="Humedo" value="4"></v-checkbox>
                </v-col>
                <v-col cols="12" md="2">
                    <v-checkbox :readonly="item_lev.estatus_cot != null" v-model="item_lev.fabr_esp_desc_oper_chk"
                        label="Grado Alimenticio" value="5"></v-checkbox>
                </v-col>
            </v-row>

        </div>

        <div class="card_contenedor">
            <div class="center_div">
                <span class="title_card_gris">DIMENSIONAMIENTO</span>
            </div>
            <br />

            <v-row no-gutters>
                <v-col cols="12" md="6">
                    <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                        variant="outlined" label="A = Altura toma (mm)" class="ml-3"
                        v-model="item_lev.fabr_esp_a_alt_toma"></v-text-field>
                    <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                        variant="outlined" label="B = Altura Entrega (mm)" class="ml-3"
                        v-model="item_lev.fabr_esp_b_alt_entr"></v-text-field>
                    <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                        variant="outlined" label="C = Paño de riel a piso (mm)" class="ml-3"
                        v-model="item_lev.fabr_esp_c_pa_rp"></v-text-field>

                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                        variant="outlined" label="D = Altura min. equipo (mm)" class="ml-3"
                        v-model="item_lev.fabr_esp_d_alt_min_eq"></v-text-field>
                    <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                        variant="outlined" label="E = Altura max. equipo (mm)" class="ml-3"
                        v-model="item_lev.fabr_esp_e_alt_max_eq"></v-text-field>

                </v-col>

            </v-row>

            <div style="display: flex; justify-content: center;">
                <v-img :src="require('@/assets/ImgFormatos/FormatosEspeciales/img_fab_esp_3.png')" width="800px"
                    height="600px" />
            </div>

        </div>



        <div class="card_contenedor">
            <div class="center_div">
                <span class="title_card_gris">POSICIÓN PIEZAS</span>
            </div>
            <br />
            <v-row no-gutters>
                <v-col cols="12" md="4">
                    <v-img :src="require('@/assets/ImgFormatos/FormatosEspeciales/img_fab_esp_4.png')" width="800px"
                        height="600px" />
                </v-col>
                <v-col cols="12" md="8">
                    <v-row no-gutters>
                        <v-col cols="12" md="6">
                            <div class="center_div">
                                <span class="title_card_gris">POSICIÓN PIEZA - TOMA</span>
                            </div>


                            <v-row no-gutters>
                                <v-col cols="12" md="6">
                                    <v-img :src="require('@/assets/ImgFormatos/FormatosEspeciales/img_fab_esp_5.png')"
                                        width="800px" height="600px" />
                                </v-col>
                                <v-col cols="12" md="6">
                                    <br />
                                    <br />
                                    <br />
                                    <v-checkbox :readonly="item_lev.estatus_cot != null"
                                        v-model="item_lev.fabr_esp_uno_toma" label="Vertical" value="1"></v-checkbox>
                                    <br />
                                    <br />
                                    <br />
                                    <v-checkbox :readonly="item_lev.estatus_cot != null"
                                        v-model="item_lev.fabr_esp_uno_toma" label="Horizontal" value="2"></v-checkbox>
                                    <br />
                                    <br />
                                    <br />
                                    <v-checkbox :readonly="item_lev.estatus_cot != null"
                                        v-model="item_lev.fabr_esp_uno_toma" label="Ángulo (°)" value="3"></v-checkbox>
                                </v-col>
                            </v-row>

                        </v-col>
                        <v-col cols="12" md="6">
                            <div class="center_div">
                                <span class="title_card_gris">POSICIÓN PIEZA -ENTREGA</span>
                            </div>

                            <v-row no-gutters>
                                <v-col cols="12" md="6">
                                    <v-img :src="require('@/assets/ImgFormatos/FormatosEspeciales/img_fab_esp_6.png')"
                                        width="800px" height="600px" />
                                </v-col>
                                <v-col cols="12" md="6">
                                    <br />
                                    <br />
                                    <br />
                                    <v-checkbox :readonly="item_lev.estatus_cot != null"
                                        v-model="item_lev.fabr_esp_dos_toma" label="Horizontal" value="1"></v-checkbox>
                                    <br />
                                    <br />
                                    <br />
                                    <v-checkbox :readonly="item_lev.estatus_cot != null"
                                        v-model="item_lev.fabr_esp_dos_toma" label="Vertical" value="2"></v-checkbox>
                                    <br />
                                    <br />
                                    <br />
                                    <v-checkbox :readonly="item_lev.estatus_cot != null"
                                        v-model="item_lev.fabr_esp_dos_toma" label="Ángulo (°)" value="3"></v-checkbox>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <br />

            <v-row no-gutters>
                <v-col cols="12" md="10">


                    <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                        variant="outlined" label="DISEÑADOR" class="ml-3" v-model="item_lev.fabr_esp_dising"></v-text-field>
                    <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                        variant="outlined" label="ASESOR" class="ml-3" v-model="item_lev.fabr_esp_asesor"></v-text-field>
                    <v-row no-gutters>
                        <v-col cols="12" md="8">
                            <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                                variant="outlined" label="FECHA" class="ml-3" v-model="item_lev.fabr_esp_fecha"
                                type="date"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                                variant="outlined" label="REV." class="ml-3" v-model="item_lev.fabr_esp_rev"></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="2">
                    <v-checkbox :readonly="item_lev.estatus_cot != null" v-model="item_lev.fabr_esp_rev_es" label="COMPLEJO"
                        value="1"></v-checkbox>
                    <v-checkbox :readonly="item_lev.estatus_cot != null" v-model="item_lev.fabr_esp_rev_es" label="ESPECIAL"
                        value="2"></v-checkbox>
                    <v-checkbox :readonly="item_lev.estatus_cot != null" v-model="item_lev.fabr_esp_rev_es" label="BÁSICO"
                        value="3"></v-checkbox>
                </v-col>
            </v-row>
            <div style="display:flex; justify-content: center;">
                <p style="color:#757575;">Llenado por CARVAJAL CRANES HOIST SAPI DE CV</p>
            </div>

        </div>





    </div>
</template>
<script>

export default {
    props: {
        is_new: Boolean,
        restore_lev: Object,
        visita: Object,
        id_tipo_unidad: String,
        cargarLevantamientos: Function,
        data_unidad_nombre: Object,
    },
    data() {
        return {
            item_lev: {
                fabr_esp_carct_pieza: [],
                fabr_esp_desc_oper_chk: []
            },
            rules: [
                (value) => {
                    if (value) return true;

                    return "Este campo es obligatorio";
                },
            ],
        }
    },
    created() {
        this.item_lev = this.restore_lev;
        this.item_lev.fabr_esp_carct_pieza = this.restore_lev.fabr_esp_carct_pieza == null ? [] : (this.restore_lev.fabr_esp_carct_pieza.includes(';') ? this.restore_lev.fabr_esp_carct_pieza.split(";") : this.restore_lev.fabr_esp_carct_pieza);
        this.item_lev.fabr_esp_desc_oper_chk = this.restore_lev.fabr_esp_desc_oper_chk == null ? [] : (this.restore_lev.fabr_esp_desc_oper_chk.includes(';') ? this.restore_lev.fabr_esp_desc_oper_chk.split(";") : this.restore_lev.fabr_esp_desc_oper_chk);
    },
    methods: {

    }
}
</script>

<style scoped>
.card_contenedor {

    margin-top: 20px;
    border: solid 2px #000;
    width: 100%;
    border-radius: 5px;
}

.title_card {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 5px;
    padding-top: 5px;
    letter-spacing: 2px;
    background-color: #FFD600;
}

.title_card_gris {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 5px;
    padding-top: 5px;
    letter-spacing: 2px;
    background-color: #757575;
    color: #FFFFFF;
}



.center_div {

    width: 100%;
    top: -25px;
    display: flex;
    justify-content: center;
}

.center_element {
    display: flex;
    justify-content: center
}


.color_letter {
    color: #000000;
    padding: 4px;
    background-color: #FFD600;
}

.color_spacer {
    color: #000000;
    padding: 4px;

}

.space_container {
    margin-top: -20px;
}

.mg_tn {
    margin-top: -30px;
}

.nota_contenedor {
    padding: 10px;
    border: solid 2px #000000;
    font-size: 18px;
    color: #5F5F5F;
    font-weight: bold;
}

.title_card_2 {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 5px;
    padding-top: 5px;
    letter-spacing: 2px;
    background-color: #0D3961;
    color: #fff
}

.imagen-opaca {
    opacity: 0.2;
}

.header_table_uno {
    border: solid 1px #757575;
    background-color: #757575;
    color: #ffffff;
}

.body_tabla_uno {
    border: solid 1px #757575;
}</style>