<template>
  <div>
    <v-row class="pa-0 ma-3" justify="center">
      <v-col cols="12">
        <h3> ORDENES DE SERVICIO </h3>
        <v-card class="mt-3" outlined>
          <v-card-actions>
            <v-text-field v-model="search" append-icon="search" label="Buscar orden de servicio" single-line
              hide-details></v-text-field>
            <v-spacer></v-spacer>
            
            <v-btn small class="gray" icon dark @click="consulta()"><v-icon>mdi-refresh</v-icon> </v-btn>
          </v-card-actions>

          <v-data-table v-model:items-per-page="itemsPerPage" :headers="headers" :items="items" :search="search"
            fixed-header hide-default-footer :loading="Loading" loading-text="Cargando... Por favor espere."
            no-data-text="Aún no hay móduos registrados" dense>
            <template v-slot:item.action="{ item }">
                <v-btn small text color="warning" dark
                @click="infoOT(item.selectable.id_ot)"><v-icon> mdi-send</v-icon> </v-btn>
            </template>

            <template v-slot:item.estatus="{ item }">
              <v-chip :color="GetEstusColor(item.selectable.estatus)">

                {{ GetEstatus(item.selectable.estatus) }}
              </v-chip>
            </template>
          </v-data-table>

        </v-card>

      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  props: {
      visita: Object,
      creator: Boolean,
      showAlert: Function,
      hasPermission: Function
  },
  components:{
      
  },
  computed: {
      ...mapState("Session", ['session_key', 'user', 'permisos', 'loggedin']),
  },
  data() {
      return {
        search: '',
        itemsPerPage: 100,
        items: [],
        Loading: false,
        headers: [
          { title: '#', align: 'left', key: 'id_ot' },
          { title: 'Servicio', align: 'left', key: 'nombre_servicio' },
          { title: 'Comentarios', align: 'left', key: 'comentarios' },
          { title: 'Tipo unidad', align: 'left', key: 'nombre_tipo_unidad' },
          { title: 'Unidad', align: 'left', key: 'nombre_unidad' },
          { title: 'Estatus', align: 'left', key: 'estatus' },
          { title: '', align: 'right', key: 'action', sortable: false },

        ],
      }
  },
  async created() {
    await this.consulta();
  },
  mounted() {
  },
  watch: {
    
  },
  methods: {
    
    infoOT(item) {
      this.$router.push({ name: 'VistaOrdenSelected', params: { id_ot: item } });

    },

    async consulta() {
      
      let datapost = {
        id_visita: this.visita.id_visita
      }

      this.Loading = true;
      await this.$axios.post('obtener.ordenes.servicio', datapost).then((res) => {
        this.items = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.Loading = false;
      });
    },


    GetEstusColor(e) {
      let c = "";
      switch (e) {
        case 0:
          c = "blue accent-4";
          break;
        case 1:
          c = "orange accent-4";
          break;
          c = "";
        case 2:
          c = "green accent-4";
          break;
        default:
          c = "blue accent-4";
          break;
      }
      return c;
    },
    GetEstatus(e) {
      let c = "";
      switch (e) {
        case 0:
          c = "Pendiente";
          break;
        case 1:
          c = "Programada";
          break;
          c = "";
        case 2:
          c = "Finalizada";
          break;
        default:
          c = "Pendiente";
          break;
      }
      return c;
    }

  }

}
</script>