<template>
  <div>
    <div class="container">
      <div>
        <v-progress-circular
          :size="70"
          :width="7"
          color="#FFB506"
          indeterminate
        ></v-progress-circular>
      </div>
    </div>
  </div>
</template>
    <script>
export default {
  data() {
    return {
      text: "",
    };
  },
  methods: {},
};
</script>
    <style>
.container {
  display: flex;
  align-items: center; /* Centra verticalmente */
  justify-content: center; /* Centra horizontalmente */
  height: 100vh; /* Ajusta la altura del contenedor al 100% del viewport */
}

.wrapper {
  background: rgb(191, 28, 127);
  background: radial-gradient(
    circle,
    rgba(191, 28, 127, 1) 38%,
    rgba(0, 150, 203, 1) 100%
  );
  width: 100%;
  height: 150vh;
}

.login_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.centrado {
  width: 85%;
  padding: 10px;
  position: relative;
}

.centrado::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.input_login {
  color: white;
}

@import url("https://fonts.googleapis.com/css?family=Exo:400,700");

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}
</style>