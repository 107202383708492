// Styles

import '@mdi/font/css/materialdesignicons.css'

//import '@peach/css/1105b53.css'
//import '@peach/css/sidebar.css'
//import 'custom/css/buttons.css'

import 'vuetify/styles'
import { createVuetify } from 'vuetify'

import { VDataTable } from 'vuetify/labs/VDataTable'
import { VDatePicker } from 'vuetify/labs/VDatePicker'
import { VuetifyDateAdapter } from 'vuetify/labs/date/adapters/vuetify'
import { VStepper } from 'vuetify/labs/VStepper'

import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

export default createVuetify({
  components : {
    ...components,
    VDataTable,
    VDatePicker,
    VStepper
  },
  date: {
    adapter: VuetifyDateAdapter,
  },
  directives,
})
