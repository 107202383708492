<template>
    <div>

        <v-text-field
            v-model="date_input"
            :label="label_dp"
            prepend-icon="mdi-calendar"
            readonly
           @click="dialog=true"
           :variant="get_variant"
           :density="get_density"
          ></v-text-field>

        <v-dialog
      v-model="dialog"
      width="auto"
    >
      <v-card>
        <v-locale-provider locale="es">
        <v-date-picker  v-model="date" cancel-text="cancelar" ok-text="Aceptar" title="" input-text="Fecha" @click:cancel="cancel_action()" @click:save="retornar_fecha()"></v-date-picker>
        </v-locale-provider>
      </v-card>
    </v-dialog>
    </div>
</template>
<script>
import moment from 'moment';



export default {
    props:["label_dp","fecha_default", "det_variant", "det_density"],
    
    data(){
        return {
            dialog:false,
            date: this.fecha_default!=null?moment(this.fecha_default).format('YYYY-MM-DD'):moment().format('YYYY-MM-DD'),
            date_input: this.fecha_default!=null?this.fecha_default:moment().format('YYYY-MM-DD'),
            get_variant: this.det_variant!=null?this.det_variant:"filled",
            get_density:this.det_density!=null?this.det_density:"default"
  
      
        }
    },

    watch:{
        date(){
           
            this.date_input=moment(this.date).format('YYYY-MM-DD');

            this.$emit('ObtenerFechaDP',  this.date_input);
          
        }
    },
    methods:{
        cancel_action(){
            this.dialog=false 
        },
        retornar_fecha(){
            console.log("aqui debera de retornar la fecha", this.date)
            this.$emit('ObtenerFechaDP', this.date);
            this.dialog=false;
        }
    }
}
</script>