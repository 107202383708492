<template>
<!--         
<v-row class="mt-5 mb-5" style="border:1px solid black; border-radius:5px;">
    <v-col cols="12" sm="12" lg="12">
        <center>
            <h3>
                Complementos
            </h3>
        </center>

        
        <v-row>
            
            <v-col>
                <v-checkbox v-model="item_lev.requiere_control" label="Botonera" value="botonera"></v-checkbox>
            </v-col>
            <v-col>
                <v-checkbox v-model="item_lev.requiere_control" label="Control Remoto" value="remoto"></v-checkbox>
            </v-col>
        </v-row>
    </v-col>
</v-row> -->
<!--         v-if="(typeof item_lev.requiere_control !== 'undefined')" -->

<div class="card_contenedor" >
    <v-row no-gutters  v-if="data_unidad_nombre=='POLIPASTO ELECTRICO' || data_unidad_nombre=='POLIPASTO NEUMATICO'">
        <v-col cols="12" md="4">
            <v-sheet class="pa-5">
                <v-text-field :rules="rules" density="compact" variant="outlined" label="Número de serie"
                    v-model="item_lev.pp_numero_serie"></v-text-field>
            </v-sheet>
        </v-col>
        <v-col cols="12" md="4">
            <v-sheet class="pa-5">
                <v-select label="Tipo de ambiente" variant="outlined" density="compact" :items="['Ambiente marino','Ambiente seco']" v-model="item_lev.pp_tipo_ambiente"></v-select>
            </v-sheet>
        </v-col>
        <v-col cols="12" md="4">
            <v-sheet class="pa-5">
                <v-select label="Tipo de trolley" variant="outlined" density="compact" :items="['Manual','Electrica', 'Neumatica']" v-model="item_lev.pp_tipo_trolley"></v-select>
            </v-sheet>
        </v-col>
    </v-row>

 
    <v-row no-gutters >
        <v-col cols="12" md="4">
           
            <v-sheet class="pa-5">
                <!--  este campo se debera de habilitar solo si ex polipasto electrico -->
                    <v-checkbox density="comfortable" v-model="item_lev.pp_caja_recep_cadena" value="1"
                    label="Caja receptora de cadena" v-if="data_unidad_nombre=='POLIPASTO ELECTRICO'"></v-checkbox>
            </v-sheet>
        </v-col>
        <v-col cols="12" md="4">
            <v-sheet class="pa-5">
                <v-select label="Protección resistente a medio ambiente" variant="outlined" density="compact" :items="['SI','NO']" v-model="item_lev.pp_proct_reci_med_amb"></v-select>
            </v-sheet>
        </v-col>
        <v-col cols="12" md="4">
            <v-sheet class="pa-5">
                <v-select label="Protección resistente a accido" variant="outlined" density="compact" :items="['SI','NO']" v-model="item_lev.pp_proct_reci_accid"></v-select>
            </v-sheet>
        </v-col>
    </v-row>
    <v-row no-gutters >
        <v-col cols="12" md="6">
            <v-sheet class="pa-5">
                <v-text-field :rules="rules" density="compact" variant="outlined" label="Longitud de caja receptora"
                    v-model="item_lev.pp_long_caja_recep"></v-text-field>
            </v-sheet>
        </v-col>
        <v-col cols="12" md="6">
            <v-sheet class="pa-5">
                <v-text-field :rules="rules" density="compact" variant="outlined" label="Metros de cable botonera"
                    v-model="item_lev.pp_mtr_cable_botonera"></v-text-field>
            </v-sheet>
        </v-col>
        
    </v-row>
    <!--   Campos de polipasto neumatico -->
    <v-row no-gutters v-if="data_unidad_nombre=='POLIPASTO NEUMATICO'">
        <v-col cols="12" md="6">
            <v-sheet class="pa-5">
                <v-select label="Protección resistente a medio ambiente" variant="outlined" density="compact" :items="['GANCHO','ARGOLLA']" v-model="item_lev.pp_tipo_suspencion"></v-select>                  
            </v-sheet>
        </v-col>
        <v-col cols="12" md="6">
            <v-sheet class="pa-5">
                <v-select label="Anti chispa" variant="outlined" density="compact" :items="['SI','NO']" v-model="item_lev.pp_incl_ant_chispa"></v-select>
            </v-sheet>
        </v-col>
    </v-row>
</div>

<!-- MMMMM -->

<!-- <div class="card_contenedor" v-if="(typeof item_lev.requiere_control !== 'undefined')">
    <div class="center_div">
        <span class="title_card">BOTONERA/CONTROL REMOTO</span>
    </div>
    <br />
    <v-row no-gutters style="margin-top:-20px;">
        <v-col cols="12" md="5">
            <v-sheet class="pa-5">
                <v-text-field :rules="rules" density="compact" variant="outlined" label="Marca"
                    v-model="item_lev.bot_control_r_marca"></v-text-field>
                <v-text-field :rules="rules" density="compact" variant="outlined" label="Modelo"
                    v-model="item_lev.bot_control_r_modelo"></v-text-field>
                <v-text-field :rules="rules" density="compact" variant="outlined" label="Número de movimientos"
                    v-model="item_lev.bot_control_r_no_movim"></v-text-field>
                <v-text-field :rules="rules" density="compact" variant="outlined" label="Velocidades"
                    v-model="item_lev.bot_control_r_velocidades"></v-text-field>
                <v-row no-gutters>
                    <v-col cols="12" md="6">
                        <v-text-field :rules="rules" density="compact" variant="outlined" label="Incluye cable Mando"
                            v-model="item_lev.bot_control_r_incluye_cable_mando"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field :rules="rules" density="compact" variant="outlined" label="¿Cuantos metros?" class="ml-2"
                            v-model="item_lev.bot_control_r_cuantos_metros"></v-text-field>
                    </v-col>
                </v-row>

            </v-sheet>
        </v-col>
        <v-col cols="12" md="4">
            <v-sheet class="pa-2">
                <br />
                <v-text-field :rules="rules" density="compact" variant="outlined" label="Selector para palipasto (A/B)"
                    v-model="item_lev.bot_control_r_selector_pp_ab"></v-text-field>
                <v-text-field :rules="rules" density="compact" variant="outlined" label="Incluye paro de emergencia?"
                    v-model="item_lev.bot_control_r_incluye_paro_emerg"></v-text-field>
                <v-text-field :rules="rules" density="compact" variant="outlined" label="Instalación o solo suministro"
                    v-model="item_lev.bot_control_r_intalacion_o_suministro"></v-text-field>
            </v-sheet>
        </v-col>
        <v-col cols="12" md="3">
            <v-sheet class="pa-2">
                <div class="center_element">
                    <v-img :src="require('@/assets/ImgFormatos/formato_1_maq_4.png')" width="300px"
                        height="300px" />
                </div>
            </v-sheet>
        </v-col>
    </v-row>
</div>

<div class="center_element" v-if="(typeof item_lev.requiere_control !== 'undefined')">
    <small>Indicar si solo se suministra en planta o requiere instalacion por parte de CARVAJAL CRANES & HOSIT SAPI
        DE CV</small>
</div> -->

<!-- accesorios de carga, botonera, control ? -->
<!-- <div class="card_contenedor">
    <v-row no-gutters>
        <v-col cols="12" md="7">
            <div class="center_div">
                <span class="title_card">ESLINGAS</span>
            </div>
            <v-sheet class="pa-2">

                <v-row no-gutters>
                    <v-col cols="12" md="3">
                        <v-checkbox density="comfortable" v-model="item_lev.esligas_tipo_estiga" value="1"
                            label="Poliéster"></v-checkbox>
                        <v-checkbox density="comfortable" v-model="item_lev.esligas_tipo_estiga" value="2"
                            label="Cuero" class="mg_tn"></v-checkbox>
                        <v-checkbox density="comfortable" v-model="item_lev.esligas_tipo_estiga" value="3"
                            label="Cable acerado" class="mg_tn"></v-checkbox>
                        <v-checkbox density="comfortable" v-model="item_lev.esligas_tipo_estiga" value="4"
                            label="Cadena" class="mg_tn"></v-checkbox>
                        <v-checkbox density="comfortable" v-model="item_lev.esligas_tipo_estiga" value="5"
                            label="Maya" class="mg_tn"></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="5">
                        <v-text-field :rules="rules" density="compact" variant="outlined" v-model="item_lev.estigas_longitud"
                            label="Longitud"></v-text-field>
                        <v-text-field :rules="rules" density="compact" variant="outlined" v-model="item_lev.estigas_cap_max_carga"
                            label="Cap. Max Carga"></v-text-field>
                        <v-text-field :rules="rules" density="compact" variant="outlined"
                            v-model="item_lev.estigas_con_recubrimiento" label="Con recubrimiento"></v-text-field>
                        <v-text-field :rules="rules" density="compact" variant="outlined" v-model="item_lev.estigas_con_accesorios"
                            label="Con accesorios"></v-text-field>
                        <v-text-field :rules="rules" density="compact" variant="outlined" v-model="item_lev.estigas_notas"
                            label="Notas"></v-text-field>
                    </v-col>


                    <v-col cols="12" md="4">
                        <div class="center_element">
                            <v-img :src="require('@/assets/ImgFormatos/formato_1_maq_5.png')" width="300px"
                                height="300px" />
                        </div>
                    </v-col>
                </v-row>

            </v-sheet>
        </v-col>
        <v-col cols="12" md="5">
            <v-sheet class="pa-2">
                <div class="center_div">
                    <span class="title_card">CANCAMOS</span>
                </div>

                <v-row no-gutters>
                    <v-col cols="12" md="7" class="pa-1">
                        <v-text-field :rules="rules" density="compact" variant="outlined" label="Medida"
                            v-model="item_lev.concamos_medida"></v-text-field>
                        <v-text-field :rules="rules" density="compact" variant="outlined" label="Estándar o milimetrico"
                            v-model="item_lev.concamos_estandar_o_milemetrico"></v-text-field>
                        <v-text-field :rules="rules" density="compact" variant="outlined" label="Fijo o giratorio"
                            v-model="item_lev.concamos_fijo_o_giratorio"></v-text-field>
                        <v-text-field :rules="rules" density="compact" variant="outlined" label="Marca"
                            v-model="item_lev.concamos_marca"></v-text-field>
                        <v-text-field :rules="rules" density="compact" variant="outlined" label="Notas"
                            v-model="item_lev.concamos_notas"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="center_element">
                            <v-img :src="require('@/assets/ImgFormatos/formato_1_maq_6.png')" width="300px"
                                height="300px" />
                        </div>
                    </v-col>
                </v-row>

            </v-sheet>
        </v-col>
    </v-row>
</div> -->

<div class="card_contenedor">
    <div class="center_div">
        <span class="title_card">POLIPASTO | TROLLEY</span>
    </div>
    <br />

    <v-row no-gutters>
        <v-col cols="12" md="3">
            <v-sheet class="pa-5">
                <v-checkbox density="comfortable" label="Garrucha / Manual" v-model="item_lev.poli_trol_tipo"
                    value="1"></v-checkbox>
                <v-checkbox density="comfortable" class="mg_tn" label="Eléctrico de cadena"
                    v-model="item_lev.poli_trol_tipo" value="2"></v-checkbox>
                <v-checkbox density="comfortable" class="mg_tn" label="Eléctrico de cable"
                    v-model="item_lev.poli_trol_tipo" value="3"></v-checkbox>
                <v-checkbox density="comfortable" class="mg_tn" label="Neumático" v-model="item_lev.poli_trol_tipo"
                    value="4"></v-checkbox>
                <v-checkbox density="comfortable" class="mg_tn" label="Cuerda" style="margin-left: 10px;"
                    v-model="item_lev.poli_trol_tipo" value="5"></v-checkbox>
                <v-checkbox density="comfortable" class="mg_tn" label="Botonera" style="margin-left: 10px;"
                    v-model="item_lev.poli_trol_tipo" value="6"></v-checkbox>
            </v-sheet>
        </v-col>

        <v-col cols="12" md="3">
            <v-sheet class="pa-5">
                <v-text-field :rules="rules" density="compact" variant="outlined" v-model="item_lev.poli_trol_monorriel_birriel"
                    label="Monorriel / Birriel"></v-text-field>
                <v-text-field :rules="rules" density="compact" variant="outlined" v-model="item_lev.poli_trol_cap_max_carga"
                    label="Cap. Max Carga"></v-text-field>
                <v-text-field :rules="rules" density="compact" variant="outlined" v-model="item_lev.poli_trol_altura"
                    label="Altura"></v-text-field>
                <v-text-field :rules="rules" density="compact" variant="outlined" v-model="item_lev.poli_trol_voltaje"
                    label="Voltaje"></v-text-field>
                <v-text-field :rules="rules" density="compact" variant="outlined" v-model="item_lev.poli_trol_tipo_ambiente"
                    label="Tipo de ambiente"></v-text-field>
            </v-sheet>
        </v-col>

        <v-col cols="12" md="3">
            <v-sheet class="pa-5">
                <v-text-field :rules="rules" density="compact" variant="outlined" v-model="item_lev.poli_trol_velocidades_uno_dos"
                    label="Velocidades (1-2)"></v-text-field>
                <v-text-field :rules="rules" density="compact" variant="outlined" v-model="item_lev.poli_trol_tipo_suspencion"
                    label="Tipo de suspención"></v-text-field>
                <v-text-field :rules="rules" density="compact" variant="outlined" v-model="item_lev.poli_trol_porta_cadena"
                    label="Porta cadena (S/N)"></v-text-field>
                <v-text-field :rules="rules" density="compact" variant="outlined" v-model="item_lev.poli_trol_altura_reducuda"
                    label="Altura reducida (S/N)"></v-text-field>
                <v-text-field :rules="rules" density="compact" variant="outlined" v-model="item_lev.poli_trol_marca"
                    label="Marca"></v-text-field>
            </v-sheet>
        </v-col>

        <v-col cols="12" md="3">
            <v-sheet class="pa-5">
                <v-text-field :rules="rules" density="compact" variant="outlined" label="Modelo"
                    v-model="item_lev.poli_trol_modelo"></v-text-field>
                <v-text-field :rules="rules" density="compact" variant="outlined" label="Velocidades"
                    v-model="item_lev.poli_trol_velocidades"></v-text-field>
                <div class="nota_contenedor">
                    En caso de solicitar un polipasto birriel, considerar el GAGE (medida entre Rieles de Puente
                    para el buen suministro al Cliente).
                </div>
            </v-sheet>
        </v-col>
    </v-row>
</div>

<div class="card_contenedor">
    <div class="center_div">
        <span class="title_card">SERVICIOS PREVENTIVOS Y CORRECTIVOS</span>
    </div>
    <br />


    <v-row no-gutters>
        <v-col cols="12" md="2">
            <v-sheet class="pa-5">
                <v-checkbox density="comfortable" v-model="item_lev.serv_prev_correc_tipo" value="1"
                    label="Grúa viajera monorriel"></v-checkbox>
                <v-checkbox density="comfortable" v-model="item_lev.serv_prev_correc_tipo" value="2"
                    label="Grúa viajera birriel" class="mg_tn"></v-checkbox>
                <v-checkbox density="comfortable" v-model="item_lev.serv_prev_correc_tipo" value="3"
                    label="Grúa bandera" class="mg_tn"></v-checkbox>
                <v-checkbox density="comfortable" v-model="item_lev.serv_prev_correc_tipo" value="4"
                    label="Grúa pórtico" class="mg_tn"></v-checkbox>
                <v-checkbox density="comfortable" v-model="item_lev.serv_prev_correc_tipo" value="5"
                    label="Grua semi pórtico" class="mg_tn"></v-checkbox>
            </v-sheet>
        </v-col>
        <v-col cols="12" md="2">
            <v-sheet class="pa-5">
                <v-checkbox density="comfortable" v-model="item_lev.serv_prev_correc_tipo_dos" value="1"
                    label="Polipasto eléctrico cadena"></v-checkbox>
                <v-checkbox density="comfortable" v-model="item_lev.serv_prev_correc_tipo_dos" value="2"
                    label="Polipasto eléctrico cable" class="mg_tn"></v-checkbox>
                <v-checkbox density="comfortable" v-model="item_lev.serv_prev_correc_tipo_dos" value="3"
                    label="Polipasto neumático" class="mg_tn"></v-checkbox>
                <v-checkbox density="comfortable" v-model="item_lev.serv_prev_correc_tipo_dos" value="4"
                    label="Trolley manual" class="mg_tn"></v-checkbox>
                <v-checkbox density="comfortable" v-model="item_lev.serv_prev_correc_tipo_dos" value="5"
                    label="Trolley eléctrica" class="mg_tn"></v-checkbox>
            </v-sheet>
        </v-col>
        <v-col cols="12" md="8">
            <v-sheet class="pa-5">
                <v-row no-gutters>
                    <v-col cols="12" md="3">
                        <v-text-field :rules="rules" density="compact" variant="outlined" label="Altura"
                            v-model="item_lev.serv_prev_correc_altura_uno"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field :rules="rules" density="compact" variant="outlined" label="Ancho"
                            v-model="item_lev.serv_prev_correc_ancho" style="margin-left:10px;"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field :rules="rules" density="compact" variant="outlined" label="Altura"
                            v-model="item_lev.serv_prev_correc_altura_dos" style="margin-left:10px;"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field :rules="rules" density="compact" variant="outlined" label="Voltaje"
                            v-model="item_lev.serv_prev_correc_voltaje" style="margin-left:10px;"></v-text-field>
                    </v-col>
                </v-row>

                <v-row no-gutters>
                    <v-col cols="12" md="3">
                        <v-text-field :rules="rules" density="compact" variant="outlined"
                            v-model="item_lev.serv_prev_correc_tipo_mca_sistema_elect"
                            label="Tipo/Mca de sistema eléctrico"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field :rules="rules" density="compact" variant="outlined"
                            v-model="item_lev.serv_prev_correc_no_lineas" label="Número de líneas"
                            style="margin-left:10px;"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field :rules="rules" density="compact" variant="outlined"
                            v-model="item_lev.serv_prev_correc_tierra" label="Tierra"
                            style="margin-left:10px;"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field :rules="rules" density="compact" variant="outlined"
                            v-model="item_lev.serv_prev_correc_amperaje" label="Amperaje"
                            style="margin-left:10px;"></v-text-field>
                    </v-col>
                </v-row>

                <v-row no-gutters>
                    <v-col cols="12" md="3">
                        <v-checkbox density="comfortable" label="Serv.: Días Hábiles"
                            v-model="item_lev.serv_prev_correc_dia_serv" value="1"></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-checkbox density="comfortable" label="Fin de Semana"
                            v-model="item_lev.serv_prev_correc_dia_serv" value="2"></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-checkbox density="comfortable" label="Día Inhábil"
                            v-model="item_lev.serv_prev_correc_dia_serv" value="3"></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field :rules="rules" density="compact" variant="outlined" label="Marca de polipasto"
                            v-model="item_lev.serv_prev_correc_marca_polipasto"></v-text-field>
                    </v-col>
                </v-row>

                <v-row no-gutters>
                    <v-col cols="12" md="6">
                        <v-text-field :rules="rules" density="compact" variant="outlined" label="Escalera de Servicio (S/N)"
                            v-model="item_lev.serv_prev_correc_escalera"></v-text-field>

                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field :rules="rules" density="compact" variant="outlined" label="Marca/Fabricante de Grúa Viajera"
                            v-model="item_lev.serv_prev_correc_marca_fabric_grua_viajera"
                            style="margin-left:10px;"></v-text-field>
                    </v-col>
                </v-row>

                <v-row no-gutters>
                    <v-col cols="12" md="3">
                        <v-checkbox density="comfortable" label="Préstamo Genie"
                            v-model="item_lev.serv_prev_correc_prestamo_renta" value="1"></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-checkbox density="comfortable" label="Renta Genie"
                            v-model="item_lev.serv_prev_correc_prestamo_renta" value="2"></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field :rules="rules" density="compact" variant="outlined"
                            label="TRABAJO SOBRE CONDICIONES ESPECIALES (S/N)"
                            v-model="item_lev.serv_prev_correc_trab_condiciones_espec"></v-text-field>
                    </v-col>
                </v-row>
            </v-sheet>
        </v-col>
    </v-row>
</div>


</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("Session", ['session_key', 'session_data', 'loggedin']),
    },
    props: {
        is_new: Boolean,
        restore_lev: Object,
        data_unidad_nombre: Object,
        visita: Object,
        id_tipo_unidad: String,
        cargarLevantamientos: Function
    },
    data() {
        return {
            item_lev: {},
            data_unidad_nombre:this.is_new==true?this.data_unidad_nombre:this.restore_lev.tipo_unidad_nombre,
            selectedImages: [],
            img_views: [],
            selectedFile: null,
            rules: [
                (value) => {
                    if (value) return true;

                    return "Este campo es obligatorio";
                },
            ],
            imagenUrl: null,
        }
    },

    watch:{
        data_unidad_nombre(){
            this.data_unidad_nombre = this.is_new==true?this.data_unidad_nombre:this.restore_lev.tipo_unidad_nombre
        }
    },
    created() {

        //id_tipo_unidad
        
        this.item_lev = this.restore_lev;
        this.item_lev.tipo_polipastos = this.restore_lev.tipo_polipastos == null ? [] : (this.restore_lev.tipo_polipastos.includes(";") ? this.restore_lev.tipo_polipastos.split(";") : this.restore_lev.tipo_polipastos);

        // if (!this.is_new) {
        //     this.item_lev = this.restore_lev;
        //     this.item_lev.tipo_polipastos = this.restore_lev.tipo_polipastos == null ? [] : this.restore_lev.tipo_polipastos.split(";");

        // } else {
        //     this.item_lev.tipo_polipastos = []
        // }

    },

    watch: {
       
    },
    methods: {


    }
}
</script>


<style scoped>
.card_contenedor {

    margin-top: 20px;
    border: solid 2px #000;
    width: 100%;
    border-radius: 5px;
}

.title_card {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 5px;
    padding-top: 5px;
    letter-spacing: 2px;
    background-color: #FFD600;
}


.center_div {

    width: 100%;
    top: -25px;
    display: flex;
    justify-content: center;
}

.center_element {
    display: flex;
    justify-content: center
}


.color_letter {
    color: #000000;
    padding: 4px;
    background-color: #FFD600;
}

.color_spacer {
    color: #000000;
    padding: 4px;

}

.space_container {
    margin-top: -20px;
}

.mg_tn {
    margin-top: -30px;
}

.nota_contenedor {
    padding: 10px;
    border: solid 2px #000000;
    font-size: 18px;
    color: #5F5F5F;
    font-weight: bold;
}

.title_card_2 {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 5px;
    padding-top: 5px;
    letter-spacing: 2px;
    background-color: #0D3961;
    color: #fff
}</style>