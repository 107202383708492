<template>
  <v-content class="pa-0">
		

				<v-card-actions class="pa-0" >
					<h3> <strong> {{ nuevoCliente ? "NUEVO " + (cliente.key_role ? cliente.key_role.toUpperCase():"CLIENTE"):'ACTUALIZAR DATOS DEL ' + (cliente.key_role ? cliente.key_role.toUpperCase():"CLIENTE") }}  </strong></h3> 
					<v-spacer></v-spacer>
					<v-btn color="error" fab small @click="$emit('modal',false)" ><v-icon>mdi-close</v-icon></v-btn>
				</v-card-actions>

			<v-row>
				<v-col cols="12" sm="6">
						<!-- select el select ya esta bien, revisare el backend, pero por mientras has lo mismo para estatus. vale-->
						<v-select
							label="Tipo cliente"
							v-model="cliente.rol_principal"
							:items="[{key_role:2,key_desc:'Cliente'}, {key_role:3,key_desc:'Prospecto'},]"
							item-value="key_role"
							item-title="key_desc"
							density="compact"
							filled
							hide-details
							></v-select>

						<!-- <v-text-field
							v-model="cliente.key_role" label="Key_role" placeholder="Ingresa el key_role prospecto o cliente" append-icon="phone"
							hide-details density="compact" 	outlined 
						></v-text-field> -->

						<!-- en vue el tipo de variable es muy importante, en la base de datos estatus es int, en vue  debe ser int tambien. Vale, ahora, ya revise el backedn ya quedo solucionado. falta lo de los filtros de cliente/rpospecto y agregar 2 campos más al registro, que serian: telefono extención, y celular.
						El del filtro como quedaria mejor? un select, puedes revisar un ejemplo que tengo en visitascomponent-->
					</v-col>

						<!-- Estus Cliente  -->
					<v-col cols="12" sm="6">
						<!-- select -->
						<v-select
							label="Estatus"
							v-model="cliente.estatus"
							:items="[{estatus:0,estatus_desc:'Inactivo'}, {estatus:1,estatus_desc:'Activo'},]"
							item-value="estatus"
							item-title="estatus_desc"
							density="compact"
							filled
							hide-details
							></v-select>
						<!--<v-text-field
							v-model="cliente.estatus" label="Estatus" placeholder="Ingresa estatus 0=inactivo/1=activo" append-icon="settings_phone"
							hide-details density="compact" outlined type="number"
						></v-text-field>-->
					</v-col>
					
					</v-row>


				<v-row class="mt-3">
					<!-- NOMBRE DEL CLIENTE -->
					<v-col cols="12" >
						<v-text-field
							v-model="cliente.nombre" label="Nombre" placeholder="Nombre o Razon Social del cliente" append-icon="person"
							hide-details density="compact" filled
						></v-text-field>
					</v-col>
					<!-- RAZON SOCIAL -->
					<v-col cols="12">
						<v-text-field
							v-model="cliente.rfc" label="RFC" placeholder="RFC" append-icon="domain"
							hide-details density="compact" filled 
						></v-text-field>
					</v-col>


					<v-col cols="12" >
						<v-text-field
							v-model="cliente.correo" label="Correo" placeholder="Ingresa un correo electrónico" append-icon="person_pin_circle"
							hide-details density="compact" filled
						></v-text-field>
					</v-col>

          <v-col cols="12" >
						<v-text-field
							v-model="cliente.usuario" label="Usuario" placeholder="Ingresa un nombre de usuario" append-icon="person_pin_circle"
							hide-details density="compact" filled
						></v-text-field>
					</v-col>
          <v-col cols="12" >
						<v-text-field type="password"
							v-model="cliente.contrasena" label="Contraseña" placeholder="Ingresa una clave de acceso" append-icon="person_pin_circle"
							hide-details density="compact" filled
						></v-text-field>
					</v-col>

          <!-- regimen fiscal -->

					<!-- DIRECCION DEL CLIENTE -->
					<v-col cols="12" >
						<v-text-field
							v-model="cliente.direccion" label="Dirección" placeholder="Calle #000, Colonia" append-icon="person_pin_circle"
							hide-details density="compact" filled
						></v-text-field>
					</v-col>

					<!-- CODIGO POSTAL -->
					<v-col cols="12" >
						<v-text-field
							v-model="cliente.codigo_postal" label="Código Postal" placeholder="C.P." append-icon="where_to_vote"
							hide-details density="compact" filled type="number"
						></v-text-field>
					</v-col>

          	<!--  CIUDADES -->
					<!-- <v-col cols="12" >
						<v-autocomplete
							:items="ciudades" v-model="ciudad" item-text="nombre" item-value="id" label="Ciudades" placeholder="Ciudad"
							density="compact" filled hide-details return-object color="celeste" append-icon="location_city"
						></v-autocomplete>
					</v-col> -->

          <!-- estado... -->

					<!-- <v-col cols="12" >
						<v-select
							:items="zonas"
							item-text="nombre"
							item-value="id"
							return-object
							label="Zona"
							placeholder="Zona del cliente"
							append-icon="pin_drop"
							density="compact"
							filled
							hide-details
							v-model="zona"
						></v-select>
					</v-col> -->

					<!-- TIPO DE CLIENTE -->
					<!-- <v-col cols="12" >
						<v-select
							v-model="tipo_cliente" :items="['Nacional','Internacional']" label="Tipo de Cliente" placeholder="Tipo de cliente"
							append-icon="gps_fixed" density="compact" filled hide-details
						></v-select>
					</v-col> -->
					<!-- NIVEL DEL CLIENTE -->
					<!-- <v-col cols="12" >
						<v-select
							:items="niveles"
							item-value="id"
							item-text="nombre"
							label="Nivel del cliente"
							placeholder="Tipo de cliente"
							append-icon="how_to_reg"
							density="compact"
							filled
							hide-details
							v-model="nivel"
							return-object
						></v-select>
					</v-col> -->
					<!-- RFC DEL CLIENTE -->
					<!-- <v-col cols="12" >
						<v-text-field
							v-model="rfc" label="RFC" placeholder="RFC perteneciente al cliente" append-icon="recent_actors"
							hide-details 	density="compact" filled
						></v-text-field>
					</v-col> -->
					<!--  DIAS DE FACTURACION -->

					<!-- <v-col cols="12" >
						<v-text-field
							v-model="diasFact" label="Dias limite de factura" placeholder="Días limite de factura"	append-icon="insert_drive_file"
							hide-details density="compact" filled	type="number"
						></v-text-field>
					</v-col> -->
					
					<!-- <v-col cols="12" >
						<v-select
							:items="carteras"
							item-text ="nombre"
							item-value ="id"
							return-object
							label="Carteras"
							placeholder="Cartera al que pertenece el cliente"
							append-icon="folder_shared"
							density="compact"
							filled
							hide-details
							v-model="cartera"
						></v-select>
					</v-col> -->

					<!-- TELEFONO 1 DEL CLIENTE -->
					<v-col cols="12" sm="6">
						<v-text-field
							v-model="cliente.telefono" label="Teléfono" placeholder="Número de contacto" append-icon="phone"
							hide-details density="compact" 	outlined type="number"
						></v-text-field>
					</v-col>
					<!-- EXTENCION 1 -->
					<v-col cols="12" sm="6">
						<v-text-field
							v-model="cliente.telefono_ext" label="Extención" placeholder="Extención" append-icon="settings_phone"
							hide-details density="compact" outlined type="number"
						></v-text-field>
					</v-col>

					<v-col cols="12">
						<v-text-field
							v-model="cliente.celular" label="Celular" placeholder="Celular" append-icon="settings_phone"
							hide-details density="compact" outlined type="number"
						></v-text-field>
					</v-col>

					
				
					
				</v-row>

        <!-- lista de contactos? -->

				<!-- //DIALOG PARA GUARDAR LA INFORMACION -->
				<v-card-actions class="pa-0 mt-3">
					<v-spacer></v-spacer>
					 <v-btn  
					 	:disabled="saving" 
						 persistent 
						 :loading="saving"
						 dark center 
						 class="white--text" 
						 color="success" 
						 @click="updcreated()">
             {{  nuevoCliente ? 'AGREGAR '+ (cliente.key_role ? cliente.key_role.toUpperCase():"CLIENTE") : 'ACTUALIZAR DATOS ' + (cliente.key_role ? cliente.key_role.toUpperCase():"CLIENTE") }}
          </v-btn> 
				</v-card-actions>

				<!-- <v-dialog v-model="dialog" hide-overlay persistent width="300">
					<v-card color="blue darken-4" dark >
						<v-card-text> <th style="font-size:17px;" align="center">{{ textDialog }}</th>
							<br>
							<v-progress-linear indeterminate color="white" class="mb-0" persistent></v-progress-linear>
						</v-card-text>
					</v-card>
				</v-dialog>

				<v-dialog v-model="Correcto" hide-overlay persistent width="350">
					<v-card color="success"  dark class="pa-3">
						<h3><strong>{{ textCorrecto }} </strong></h3>
					</v-card>
				</v-dialog> -->

		
	</v-content >
</template>

<script>
// import { mapGetters, mapState } from "vuex";
// import VueBarcode from "@chenfengyuan/vue-barcode";
export default {
  
  setup(){

  },

  computed: {
    // ...mapGetters("Login", ["getdatosUsuario"]),
    // ...mapState(["router"]),
  },

  // components: {
  //   VueBarcode,
  // },

  props: {
    cliente: Object,
    showAlert: Function,
    consultaClientes: Function,
		es_cita:Boolean,
		catalogos:Function
  },

  data() {
    return {
      nuevoCliente: Object.keys(this.cliente).length === 0 ? true : false,
      saving:false,
	  tipo_c:"CLIENTE"
    };
  },

  

  created() {
    // if(!this.nuevoCliente){
        // this.cliente = {...this.cliente, key_role:'cliente'};
        // const cliente = new Proxy(this.cliente, {
        //   get(target, property) {
        //     // Implementa el comportamiento deseado para acceder a la propiedad
        //     if (property in target) {
        //       return target[property]; // Devuelve el valor de la propiedad si existe
        //     } else {
        //       return undefined; // Devuelve undefined si la propiedad no existe
        //     }
        //   },
        //   set(target, property, value) {
        //     target[property] = value;
        //     return true;
        //   }
        // });

        // console.log(typeof cliente.key_role)
      // }
  },

  mounted() {
    // if(!this.nuevoCliente){
    //   // this.cliente = {...this.cliente, key_role:'cliente'};

    //   this.cliente = new Proxy(this.cliente, {
    //     get(target, property) {
    //       // Implementa el comportamiento deseado para acceder a la propiedad
    //       if (property in target) {
    //         return target[property]; // Devuelve el valor de la propiedad si existe
    //       } else {
    //         return undefined; // Devuelve undefined si la propiedad no existe
    //       }
    //     },
    //     set(target, property, value) {
    //       target[property] = value;
    //       return true;
    //     }
    //   });
    // }
  },
  
  methods: {



    async updcreated() {

      var me = this;
      this.saving = true;

      if(this.nuevoCliente && typeof this.cliente.rol_principal == 'undefined'){
        this.cliente.rol_principal = 2;
      }

      await this.$axios
        .post("usuarios.updcreated", this.cliente)
        .then((r) => {
          //alert...and reload table of 

          this.showAlert({
            activo: true, 
            texto: this.nuevoCliente ? "Cliente agregado con éxito!" : "Datos actualizados con éxito!", 
            color: "success", 
            vertical: true
          });

          if(this.nuevoCliente && this.es_cita==false){
            this.consultaClientes();
          }
		  if(this.es_cita==true){
			this.catalogos()
		  }

          me.$emit('modal',false);

        })
        .catch((e) => {
          console.log(e);
          this.showAlert({
            activo: true, 
            texto:"Lo sentimos! Algo salio mal...", 
            color: "error", 
            vertical: true
          });
        }).finally( () => {
          this.saving = false;
          //close modal?
        } );
    },
  },
};
</script>