<template>
    <div class="card_contenedor">
        <div class="center_div">
            <span class="title_card_2">DIMENSIONES</span>
        </div>
        <br />

        <v-sheet class="pa-5">
            <v-row no-gutters>
                <v-col cols="12" md="3">
                    <!-- multiple -->
                    <h3>Daños visibles</h3>
                    <v-checkbox :readonly="item_lev.estatus_cot != null" density="comfortable"
                        v-model="item_lev.dimen_danos_visibles" label="Desgaste" value="1"></v-checkbox>
                    <v-checkbox :readonly="item_lev.estatus_cot != null" density="comfortable"
                        v-model="item_lev.dimen_danos_visibles" label="Marcado" value="2" class="mg_tn"></v-checkbox>
                    <v-checkbox :readonly="item_lev.estatus_cot != null" density="comfortable"
                        v-model="item_lev.dimen_danos_visibles" label="Deformación" value="3" class="mg_tn"></v-checkbox>
                    <v-checkbox :readonly="item_lev.estatus_cot != null" density="comfortable"
                        v-model="item_lev.dimen_danos_visibles" label="Abertura de garganta" value="4"
                        class="mg_tn"></v-checkbox>
                    <v-checkbox :readonly="item_lev.estatus_cot != null" density="comfortable"
                        v-model="item_lev.dimen_danos_visibles" label="Oxidación" value="5" class="mg_tn"></v-checkbox>
                </v-col>
                <v-col cols="12" md="9">
                    <v-img :src="require('@/assets/ImgFormatos/formato_2_maq_3.png')" width="600px" height="300px" />
                </v-col>
            </v-row>
        </v-sheet>
    </div>


    <!-- NUEVOS CAMPOS QUE HABRA QUE COMPROBAR SI EXISTEN EN LA BASE DE DATOS -->


    <div class="card_contenedor">
        <div class="center_div">
            <span class="title_card_2">COMPOSICIÓN/DISEÑO</span>
        </div>
        <br />
        <v-row no-gutters>
            <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                label="1 = Ojal Simple" v-model="item_lev.comp_dising_ojal_simple" class="ml-2"></v-text-field>
            <v-text-field :rules="rules" class="ml-2" :readonly="item_lev.estatus_cot != null" density="compact"
                variant="outlined" label="2 = Ojal simple / Guardacabo o rosadera"
                v-model="item_lev.comp_dising_ojal_simple_guarda_cabo_r"></v-text-field>
            <v-text-field :rules="rules" class="ml-2" :readonly="item_lev.estatus_cot != null" density="compact"
                variant="outlined" label="3 = Ojal Simple / Gancho" v-model="item_lev.comp_dising_ojal_simp_ganc"></v-text-field>
            <v-text-field :rules="rules" class="ml-2 mr-2" :readonly="item_lev.estatus_cot != null" density="compact"
                variant="outlined" label="4 = Gancho / Gancho" v-model="item_lev.comp_dising_ganc_ganc"></v-text-field>
        </v-row>


        <v-row no-gutters>
            <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                label="5 = Ojal simple / ojal simple con gancho corredizo" v-model="item_lev.comp_dising_ojal_simp_ganc_corr"
                class="ml-2"></v-text-field>
            <v-text-field :rules="rules" class="ml-2" :readonly="item_lev.estatus_cot != null" density="compact"
                variant="outlined" label="6 = Conjunto 2 ramales" v-model="item_lev.comp_dising_conj_2_ram"></v-text-field>
            <v-text-field :rules="rules" class="ml-2 mr-2" :readonly="item_lev.estatus_cot != null" density="compact"
                variant="outlined" label="7 = Conjunto 4 ramales o mas"
                v-model="item_lev.comp_dising_conj_4_ram"></v-text-field>
        </v-row>


        <div class="center_element">
            <v-img :src="require('@/assets/ImgFormatos/FormatosAccesorios/img_acc_1.png')" width="600px" height="300px" />
        </div>

        <div class="center_element">
            <p style="color:#757575">La información que proporciono en este documento es referencial e informativa para
                realizar una correcta cotización de su aplicación.</p>
        </div>



        <div class="card_contenedor">
            <div class="center_div">
                <span class="title_card_2">TIPO DE CARGA</span>
            </div>
            <br />
            <v-sheet class="pa-5">
                <v-row no-gutters>
                    <v-col cols="12" md="6">
                        <div class="title_card_img text-h5 text-center mb-2 ml-2">1 RAMAL</div>
                        <v-row no-gutters>
                            <v-col cols="12" md="4">
                                <v-img :src="require('@/assets/ImgFormatos/FormatosAccesorios/img_acc_sm_1.png')"
                                    width="400px" height="300px" />
                                <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                                    variant="outlined" label="Cap. Max Carga" class="ml-3"
                                    v-model="item_lev.comp_dising_ram_uno_uno"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-img :src="require('@/assets/ImgFormatos/FormatosAccesorios/img_acc_sm_2.png')"
                                    width="400px" height="300px" />
                                <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                                    variant="outlined" label="Cap. Max Carga" class="ml-3"
                                    v-model="item_lev.comp_dising_ram_uno_dos"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-img :src="require('@/assets/ImgFormatos/FormatosAccesorios/img_acc_sm_3.png')"
                                    width="400px" height="300px" />
                                <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                                    variant="outlined" label="Cap. Max Carga" class="ml-3"
                                    v-model="item_lev.comp_dising_ram_uno_tres"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-row no-gutters>
                            <v-col cols="12" md="6">
                                <div class="title_card_img text-h5 text-center mb-2 mr-2">2 RAMAL</div>
                                <v-img :src="require('@/assets/ImgFormatos/FormatosAccesorios/img_acc_sm_4.png')"
                                    width="400px" height="300px" />
                                <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                                    variant="outlined" label="Cap. Max Carga" class="ml-3"
                                    v-model="item_lev.comp_dising_ram_dos_uno"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <div class="title_card_img text-h5 text-center mb-2 mr-2">3 Y 4 RAMAL</div>
                                <v-img :src="require('@/assets/ImgFormatos/FormatosAccesorios/img_acc_sm_5.png')"
                                    width="400px" height="300px" />
                                <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                                    variant="outlined" label="Cap. Max Carga" class="ml-3"
                                    v-model="item_lev.comp_dising_ram_tres_cuatro"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-sheet>
        </div>


        <div class="card_contenedor">
            <div class="center_div">
                <span class="title_card_2">DIMENSIONES</span>
            </div>
            <br />

            <v-sheet class="pa-5">
                <v-row no-gutters>
                    <v-col cols="12" md="6">
                        <div style="display: flex; justify-content: center;">
                            <v-img :src="require('@/assets/ImgFormatos/FormatosAccesorios/img_acc_3.png')" width="400px"
                                height="300px" />
                        </div>
                        <v-row no-gutters>
                            <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                                variant="outlined" label="1" class="ml-3"
                                v-model="item_lev.dimen_access_uno"></v-text-field>
                            <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                                variant="outlined" label="2" class="ml-3"
                                v-model="item_lev.dimen_access_dos"></v-text-field>
                            <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                                variant="outlined" label="3" class="ml-3"
                                v-model="item_lev.dimen_access_tres"></v-text-field>
                        </v-row>
                        <v-row no-gutters>
                            <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                                variant="outlined" label="4" class="ml-3"
                                v-model="item_lev.dimen_access_cuatro"></v-text-field>
                            <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                                variant="outlined" label="5" class="ml-3"
                                v-model="item_lev.dimen_access_cinco"></v-text-field>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                        <div style="display: flex; justify-content: center;">
                            <v-img :src="require('@/assets/ImgFormatos/FormatosAccesorios/img_acc_4.png')" width="400px"
                                height="300px" />
                        </div>
                        <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                            variant="outlined" label="Numero de brazos" class="ml-3"
                            v-model="item_lev.dimen_access_num_braz"></v-text-field>
                        <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                            variant="outlined" label="1" class="ml-3"
                            v-model="item_lev.dimen_access_num_braz_uno"></v-text-field>
                        <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                            variant="outlined" label="2" class="ml-3"
                            v-model="item_lev.dimen_access_num_braz_dos"></v-text-field>
                        <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                            variant="outlined" label="3" class="ml-3"
                            v-model="item_lev.dimen_access_num_braz_tres"></v-text-field>
                    </v-col>
                </v-row>
            </v-sheet>
        </div>


        <div class="card_contenedor">

            <div style="display: flex; justify-content: center; margin-top: 10px;">
                <v-img :src="require('@/assets/ImgFormatos/FormatosAccesorios/img_acc_5.png')" width="600px"
                    height="400px" />
            </div>

            <p style="color:#757575" class="ml-3"> PARA SOLICITAR ESTROBO TENEMOS QUE TOMAR EN CUENTA TODAS LAS SIG MEDIDAS
            </p>

            <v-row no-gutters>
                <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                    label="Diametro de argolla" class="ml-3" v-model="item_lev.dimen_access_d_argolla"></v-text-field>
                <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                    label="Longitud" class="ml-3" v-model="item_lev.dimen_access_long"></v-text-field>
            </v-row>
            <v-row no-gutters>

                <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                    label="Diametro del ojo" class="ml-3" v-model="item_lev.dimen_access_d_ojo"></v-text-field>
                <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                    label="Con rozadera o gancho" class="ml-3" v-model="item_lev.dimen_access_con_roza_ganc"></v-text-field>
                <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                    label="Tipo de gancho" class="ml-3" v-model="item_lev.dimen_access_tipo_ganc"></v-text-field>
            </v-row>

            <v-row no-gutters>
                <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                    label="La quijada del gancho" class="ml-3" v-model="item_lev.dimen_accss_quijida_gan"></v-text-field>
                <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                    label="Numero de brazos" class="ml-3" v-model="item_lev.dimen_accss_num_braz_q"></v-text-field>
                <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                    label="Medida de cada brazo" class="ml-3" v-model="item_lev.dimen_accss_med_ca_bra"></v-text-field>
            </v-row>
            <small class="ml-3">Nota: observar donde la van a colocar y definir abertura de quijada</small>
            <v-row no-gutters>

                <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                    label="Terminación de cada brazo" class="ml-3" v-model="item_lev.dimen_accss_tem_ca_bra"></v-text-field>
                <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                    label="Gancho / ojo etc" class="ml-3" v-model="item_lev.dimen_accss_gan_ojo_etc"></v-text-field>
            </v-row>

            <br />

            <div style="display: flex; justify-content: center; margin-top: 10px;">
                <v-img :src="require('@/assets/ImgFormatos/FormatosAccesorios/img_acc_6.png')" width="600px"
                    height="400px" />
            </div>

        </div>




    </div>

    <!-- NUEVOS CAMPOS QUE HABRA QUE COMPROBAR SI EXISTEN EN LA BASE DE DATOS -->



    <div class="card_contenedor">
        <div class="center_div">
            <span class="title_card_2">TIPO DE GANCHO</span>
        </div>
        <br />
        <v-sheet class="pa-5">
            <v-row no-gutters>
                <v-col cols="12" md="3">
                    <h3>Daños visibles</h3>
                    <v-checkbox :readonly="item_lev.estatus_cot != null" density="comfortable"
                        v-model="item_lev.tipo_gancho_danos_visibles" label="Desgaste" value="1"></v-checkbox>
                    <v-checkbox :readonly="item_lev.estatus_cot != null" density="comfortable"
                        v-model="item_lev.tipo_gancho_danos_visibles" label="Marcado" value="2" class="mg_tn"></v-checkbox>
                    <v-checkbox :readonly="item_lev.estatus_cot != null" density="comfortable"
                        v-model="item_lev.tipo_gancho_danos_visibles" label="Deformación" value="3"
                        class="mg_tn"></v-checkbox>
                    <v-checkbox :readonly="item_lev.estatus_cot != null" density="comfortable"
                        v-model="item_lev.tipo_gancho_danos_visibles" label="Abertura de garganta" value="4"
                        class="mg_tn"></v-checkbox>
                    <v-checkbox :readonly="item_lev.estatus_cot != null" density="comfortable"
                        v-model="item_lev.tipo_gancho_danos_visibles" label="Oxidación" value="5"
                        class="mg_tn"></v-checkbox>
                </v-col>
                <v-col cols="12" md="9">
                    <v-img :src="require('@/assets/ImgFormatos/formato_2_maq_4.png')" width="600px" height="300px" />
                </v-col>
            </v-row>
        </v-sheet>
    </div>

    <div class="card_contenedor">
        <div class="center_div">
            <span class="title_card_2">DIMENSIONES DE GANCHO DE CARGA</span>
        </div>
        <br />

        <div class="center_element">
            <v-img :src="require('@/assets/ImgFormatos/formato_2_maq_5.png')" width="600px" height="300px" />
        </div>
        <v-sheet class="pa-5">
            <v-row no-gutters>
                <v-col cols="12" md="1">


                </v-col>
                <v-col cols="12" md="2">
                    <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                        variant="outlined" label="A" class="ml-3" v-model="item_lev.dimen_gancho_carga_a"></v-text-field>
                    <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                        variant="outlined" label="AA" class="ml-3" v-model="item_lev.dimen_gancho_carga_aa"></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                    <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                        variant="outlined" label="B" class="ml-3" v-model="item_lev.dimen_gancho_carga_b"></v-text-field>
                    <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                        variant="outlined" label="C" class="ml-3" v-model="item_lev.dimen_gancho_carga_c"></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                    <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                        variant="outlined" label="D" class="ml-3" v-model="item_lev.dimen_gancho_carga_d"></v-text-field>
                    <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                        variant="outlined" label="J" class="ml-3" v-model="item_lev.dimen_gancho_carga_j"></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                    <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                        variant="outlined" label="L" class="ml-3" v-model="item_lev.dimen_gancho_carga_l"></v-text-field>
                    <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                        variant="outlined" label="Q" class="ml-3" v-model="item_lev.dimen_gancho_carga_q"></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                    <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                        variant="outlined" label="R" class="ml-3" v-model="item_lev.dimen_gancho_carga_r"></v-text-field>
                    <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                        variant="outlined" label="Y" class="ml-3" v-model="item_lev.dimen_gancho_carga_y"></v-text-field>
                </v-col>

                <v-col cols="12" md="1">

                </v-col>
            </v-row>
        </v-sheet>
    </div>
    <div class="card_contenedor">
        <div class="center_div">
            <span class="title_card_2">DIMENSIONES SEGURO DE GANCHO</span>
        </div>
        <br />

        <v-sheet class="pa-5">
            <v-row no-gutters>
                <v-col cols="12" md="6">
                    <div class="center_element">
                        <v-img :src="require('@/assets/ImgFormatos/formato_2_maq_6.png')" width="600px" height="300px" />
                    </div>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                        variant="outlined" label="B" class="ml-3" v-model="item_lev.dimen_seguro_gancho_b"></v-text-field>
                    <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                        variant="outlined" label="D" class="ml-3" v-model="item_lev.dimen_seguro_gancho_d"></v-text-field>
                    <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                        variant="outlined" label="E" class="ml-3" v-model="item_lev.dimen_seguro_gancho_e"></v-text-field>
                </v-col>
            </v-row>
        </v-sheet>
    </div>

    <div class="card_contenedor">
        <v-row no-gutters>
            <v-col cols="12" md="7">
                <div class="center_div">
                    <span class="title_card">ESLINGAS</span>
                </div>
                <v-sheet class="pa-2">

                    <v-row no-gutters>
                        <v-col cols="12" md="3">
                            <v-checkbox :readonly="item_lev.estatus_cot != null" density="comfortable"
                                v-model="item_lev.esligas_tipo_estiga" value="1" label="Poliéster"></v-checkbox>
                            <v-checkbox :readonly="item_lev.estatus_cot != null" density="comfortable"
                                v-model="item_lev.esligas_tipo_estiga" value="2" label="Cuero" class="mg_tn"></v-checkbox>
                            <v-checkbox :readonly="item_lev.estatus_cot != null" density="comfortable"
                                v-model="item_lev.esligas_tipo_estiga" value="3" label="Cable acerado"
                                class="mg_tn"></v-checkbox>
                            <v-checkbox :readonly="item_lev.estatus_cot != null" density="comfortable"
                                v-model="item_lev.esligas_tipo_estiga" value="4" label="Cadena" class="mg_tn"></v-checkbox>
                            <v-checkbox :readonly="item_lev.estatus_cot != null" density="comfortable"
                                v-model="item_lev.esligas_tipo_estiga" value="5" label="Maya" class="mg_tn"></v-checkbox>
                        </v-col>
                        <v-col cols="12" md="5">
                            <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                                variant="outlined" v-model="item_lev.estigas_longitud" label="Longitud"></v-text-field>
                            <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                                variant="outlined" v-model="item_lev.estigas_cap_max_carga"
                                label="Cap. Max Carga"></v-text-field>
                            <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                                variant="outlined" v-model="item_lev.estigas_con_recubrimiento"
                                label="Con recubrimiento"></v-text-field>
                            <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                                variant="outlined" v-model="item_lev.estigas_con_accesorios"
                                label="Con accesorios"></v-text-field>
                            <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                                variant="outlined" v-model="item_lev.estigas_notas" label="Notas"></v-text-field>
                        </v-col>


                        <v-col cols="12" md="4">
                            <div class="center_element">
                                <v-img :src="require('@/assets/ImgFormatos/formato_1_maq_5.png')" width="300px"
                                    height="300px" />
                            </div>
                        </v-col>
                    </v-row>

                </v-sheet>
            </v-col>
            <v-col cols="12" md="5">
                <v-sheet class="pa-2">
                    <div class="center_div">
                        <span class="title_card">CANCAMOS</span>
                    </div>

                    <v-row no-gutters>
                        <v-col cols="12" md="7" class="pa-1">
                            <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                                variant="outlined" label="Medida" v-model="item_lev.concamos_medida"></v-text-field>
                            <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                                variant="outlined" label="Estándar o milimetrico"
                                v-model="item_lev.concamos_estandar_o_milemetrico"></v-text-field>
                            <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                                variant="outlined" label="Fijo o giratorio"
                                v-model="item_lev.concamos_fijo_o_giratorio"></v-text-field>
                            <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                                variant="outlined" label="Marca" v-model="item_lev.concamos_marca"></v-text-field>
                            <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact"
                                variant="outlined" label="Notas" v-model="item_lev.concamos_notas"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="5">
                            <div class="center_element">
                                <v-img :src="require('@/assets/ImgFormatos/formato_1_maq_6.png')" width="300px"
                                    height="300px" />
                            </div>
                        </v-col>
                    </v-row>

                </v-sheet>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("Session", ['session_key', 'session_data', 'loggedin']),
    },
    props: {
        restore_lev: Object
    },
    data() {
        return {
            item_lev: {},

            rules: [
                (value) => {
                    if (value) return true;

                    return "Este campo es obligatorio";
                },
            ],
            imagenUrl: null,

            img_borrar: [],
        }
    },
    watch: {


    },
    created() {
        this.item_lev = this.restore_lev;
        this.item_lev.tipo_polipastos = this.restore_lev.tipo_polipastos == null ? [] : (this.restore_lev.tipo_polipastos.includes(';') ? this.restore_lev.tipo_polipastos.split(";") : this.restore_lev.tipo_polipastos);

        // if (!this.is_new) {
        //     this.item_lev = this.restore_lev;
        //     this.item_lev.tipo_polipastos = this.restore_lev.tipo_polipastos == null ? [] : (this.restore_lev.tipo_polipastos.includes(';') ? this.restore_lev.tipo_polipastos.split(";") : this.restore_lev.tipo_polipastos);

        // } else {
        //     this.item_lev.tipo_polipastos = []
        // }

    },



    methods: {

    }
}
</script>



<style scoped>
.card_contenedor {

    margin-top: 20px;
    border: solid 2px #000;
    width: 100%;
    border-radius: 5px;
}

.title_card {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 5px;
    padding-top: 5px;
    letter-spacing: 2px;
    background-color: #FFD600;
}


.center_div {

    width: 100%;
    top: -25px;
    display: flex;
    justify-content: center;
}

.center_element {
    display: flex;
    justify-content: center
}


.color_letter {
    color: #000000;
    padding: 4px;
    background-color: #FFD600;
}

.color_spacer {
    color: #000000;
    padding: 4px;

}

.space_container {
    margin-top: -20px;
}

.mg_tn {
    margin-top: -30px;
}

.nota_contenedor {
    padding: 10px;
    border: solid 2px #000000;
    font-size: 18px;
    color: #5F5F5F;
    font-weight: bold;
}

.title_card_2 {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 5px;
    padding-top: 5px;
    letter-spacing: 2px;
    background-color: #0D3961;
    color: #fff
}

.imagen-opaca {
    opacity: 0.2;
}

.title_card_img {
    padding: 10px;
    background-color: #1d4693;
    border-radius: 5px;
    width: 95%;
    color: #ffffff;
}
</style>