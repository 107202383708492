import axios from 'axios';

export default {
	namespaced: true,
	state: {
		session_key:null,
		user: null,
		permisos:null,
		loggedin:false
	},

	mutations: {
		session_start(state, data){
			let token = btoa(JSON.stringify(data));
			state.session_key = token;
			localStorage.setItem("session.key", token);
			state.user = data;
			state.permisos = new Set(state.user.permisos);
			state.loggedin = true;
		},

		session_destroy(state){
			localStorage.removeItem("session.key");
			state.session_key = null;
			state.user = null;
			state.permisos = null;
			state.loggedin = false;
		}
	},

	actions: {
		async Login({commit},payload) {
			return new Promise((resolve, reject) => {
				axios.post('auth.login', payload ).then(response =>{
					let user = response.data.user[0];
					let roles = response.data.roles;

					let user_permisos = new Set(); // Usamos un Set en lugar de un arreglo
        
					// PERMISOS DE ROL PRINCIPAL
					let rol_principal = roles.find(r => r.id_role == user.rol_principal); // Usamos find en lugar de filter
					// ss
					if (rol_principal && rol_principal.permisos != null && rol_principal.permisos !== "") {
						if(rol_principal.permisos.includes(',')){
							rol_principal.permisos.split(',').forEach(permiso => user_permisos.add(permiso));
						} else {
							user_permisos.add(rol_principal.permisos)
						}
					}

					// PERMISOS DE ROLES SECUNDARIOS
					if (user.roles_secundarios == null || user.roles_secundarios == "") {
						user.roles_secundarios = [];
					} else {
						user.roles_secundarios = user.roles_secundarios.includes(',') ? user.roles_secundarios.split(',') : [user.roles_secundarios];
					}
					let roles_secundarios = roles.filter(r => user.roles_secundarios.includes(r.id_role));
					roles_secundarios.forEach(r => {
						if (r.permisos != null && r.permisos !== "") {
							if(r.permisos.includes(',')){
								r.permisos.split(',').forEach(permiso => user_permisos.add(permiso)); // Usamos add para agregar elementos al Set
							} else{
								user_permisos.add(r.permisos)
							}
						}
					});
					
					// PERMISOS ESPECIALES
					if (user.permisos_especiales != null && user.permisos_especiales !== "") {
						if(user.permisos_especiales.includes(',')){
							user.permisos_especiales.split(',').forEach(permiso => user_permisos.add(permiso)); // Usamos add para agregar elementos al Set
						} else {
							user_permisos.add(user.permisos_especiales)
						}
						
					}

					if(user.super_admin==1 || user_permisos.has('iniciar_sesion')){
						// Convertimos el Set nuevamente a un arreglo
						user.permisos = Array.from(user_permisos);
						commit('session_start', user);
						
						// commit('session_permissions', user_permisos);

						resolve(user) //token?
					} else {
						//ocurrio un error!!
						reject("Opps! No tienes permiso para acceder a esta App")
					}

				}).catch( error =>{
					console.log('valida error', error)
					reject(error.response.data)
				});
			
			});
		},

		getSessionData({commit}){
			return new Promise((resolve, reject) => {
				const token = localStorage.getItem("session.key");
				try {
					const decodedString = atob(token);
					const decodedPayload = JSON.parse(decodedString);
					commit('session_start', decodedPayload);
					resolve(true)
				} catch (error) {
					reject(error)
				}
			});	
		}
		
		
	},

	getters: {
		// getSessionKey: state=>state.session_key,
	}
}