<template>
    <div>
        <v-stepper v-model="paso" :items="items_menu_steps" prev-text="anterior" next-text="siguente">
            <template v-for="(step, index) in items_menu_steps" :v-slot:item="`item.${index}`" :key="index">
                <v-card v-show="index + 1 == paso" :title="step.title" flat>
                    <v-sheet border>
                        <v-table>
                            <thead>
                                <tr>
                                    <th  width="400px">Componente</th>
                                    <th><span>Buena</span></th>
                                    <th><span>Ajustar</span></th>
                                    <th><span>Reparar</span></th>
                                    <th><span>Req. cambio</span></th>
                                    <th><span>Bajo de aceite</span></th>
                                    <th><span>Req.<br/> cambio de aceite</span></th>
                                    <th><span>Req. Limpieza</span></th>
                                    <th><span>Req. Pintura</span></th>
                                    <th><span>Corrosión</span></th>
                                    <th width="400px">Observaciones</th>
                                    <th >Dictar</th>
                                    <th  width="400px">Imagenes</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="(comp, ix) in step.items" :key="ix">
                                    <td>
                                        {{ comp.subtitle }}

                                        <!-- subcampo_1 = DESCRIPCION COMPONENTE -->
                                        <!-- subcampo_2 = DESCRIPCION SUB COMPONENTE -->
                                        <!-- subcampo_3 = DESCRIPCION SUB COMPONENTE DOS -->
                                       
                                        <v-text-field density="compact" variant="outlined" v-if="comp.subcampo_1" v-model="comp.value_subcampo_1" :label="comp.subcampo_1"></v-text-field>
                                        <v-text-field density="compact" variant="outlined" v-if="comp.subcampo_2 && comp.subcampo_2!='(SI) o (NO)'" v-model="comp.value_subcampo_2" :label="comp.subcampo_2" ></v-text-field>

                                        <v-select  v-else-if="comp.subcampo_2=='(SI) o (NO)'" v-model="comp.value_subcampo_2" :label="comp.subcampo_2"
                                        :items="['SI', 'NO']"
                                         density="compact" variant="outlined"></v-select>


                                        <v-text-field density="compact" variant="outlined" v-if="comp.subcampo_3 && comp.subcampo_3!='DESGASTE (SI) o (NO)'" v-model="comp.value_subcampo_3" :label="comp.subcampo_3"></v-text-field>
                                      
                                        <v-select  v-else-if="comp.subcampo_3=='DESGASTE (SI) o (NO)'" v-model="comp.value_subcampo_3" :label="comp.subcampo_3"
                                        :items="['SI', 'NO']"
                                         density="compact" variant="outlined"></v-select>
                                        
                                    </td>
                                    <td>
                                        <v-checkbox v-model="comp.selected_r" value="1"></v-checkbox>
                                    </td>
                                    <td>
                                        <v-checkbox v-model="comp.selected_r" value="2"></v-checkbox>
                                    </td>
                                    <td>
                                        <v-checkbox v-model="comp.selected_r" value="3"></v-checkbox>
                                    </td>
                                    <td>
                                        <v-checkbox v-model="comp.selected_r" value="4"></v-checkbox>
                                    </td>
                                    <td>
                                        <v-checkbox v-model="comp.selected_r" value="5"></v-checkbox>
                                    </td>
                                    <td>
                                        <v-checkbox v-model="comp.selected_r" value="6"></v-checkbox>
                                    </td>
                                    <td>
                                        <v-checkbox v-model="comp.selected_r" value="7"></v-checkbox>
                                    </td>
                                    <td>
                                        <v-checkbox v-model="comp.selected_r" value="8"></v-checkbox>
                                    </td>
                                    <td>
                                        <v-checkbox v-model="comp.selected_r" value="9"></v-checkbox>
                                    </td>
                                   
                                    <td>
                                        <v-text-field v-model="comp.observaciones" label="Observaciones"></v-text-field>
                                    </td>
                                    <td>
                                        <!-- {{  comp.recording  }} -->
                                        <v-btn @click="TranscribirAudio(comp);" icon color="blue">
                                            <v-icon>
                                                {{ (typeof comp.recording == 'undefined' || comp.recording == false) ? 'mdi-microphone' : 'mdi-dots-horizontal' }}
                                            </v-icon>
                                        </v-btn>
                                    </td>
                                    <td>
                                       <ImagenViwerComponent :data_item="comp"/>
                                    </td>
                                </tr>
                            </tbody>
                        </v-table>
                    </v-sheet>


                </v-card>
            </template>
        </v-stepper>

    </div>
</template>
<script>
import elmentos_checklist from "./CheckListComponent.json";
import ImagenViwerComponent from "./ImagenViewerComponent.vue";
const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
export default {
    components:{
        ImagenViwerComponent
    },
    data() {
        return {
            paso: 1,
            steps: ['Step 1', 'Step 2', 'Step 3'],
            items_menu_steps: elmentos_checklist
        }
    },


    methods:{

        TranscribirAudio(item){
           
            item.recording = typeof item.recording == 'undefined' || item.recording == false

            let recognition = new SpeechRecognition();
                recognition.lang = "es-ES";
                recognition.continuous = true;
                if(item.recording){
                    recognition.start()
                    
                } else {
                    recognition.stop()
                }
                
            recognition.onresult = (event) => {
                let results = "";
                for (let i = 0; i < event.results.length; i++) {
                    const transcript = event.results[i][0].transcript;
                    results = results + transcript + "\n\r";
                  
                }

                item.observaciones = results;
                return item

            }
          
         


        },

        adjuntarImagen(item){
            console.log(item)
            
            if(item.imagenes_complement){
                item.imagenes_complement.push()
            }
       

        },

        OrdenarData(){

          let semi_bd =[
            {
                id:3,
                value_subcampo_1:"TEST",
                value_subcampo_2:"TEST 2",
                value_subcampo_3:"TEST 3",
                selected_r:4,
                observaciones:"EJEMPLO DE OBSERVACIONES"
            }
          ];

          



         let elementos =   this.items_menu_steps;

       /*   elementos.forEach(e=>{
            e.
         }); */

         
        }
    }
}
</script>
