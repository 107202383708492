<template>
    <div>


        <v-row no-gutters style="border-radius: 15px; border: solid 8px #000000; ">
            <v-col cols="12" md="3" style="background-color:#002060; color: #ffffff;">
            <center>
                <img :src="appconf.logo" :width="appconf.logo_width" :height="appconf.logo_height"/>
            </center>
            </v-col>
            <v-col v-html="appconf.ot_header" cols="12" md="6" style="background-color:#c00000; color: #ffffff;">
                
            </v-col>
            <v-col v-html="appconf.ot_iso" cols="12" md="3" style="background-color:#002060; color: #ffffff;">
                
                
            </v-col>
        </v-row>

        <br />

        <!-- si no hay una visita agendada para ese dia para ese cliente con estos tecnicos, se debe crear. -->

        <div style="padding-top: 10px; padding-left: 10px; padding-right: 10px;  border-radius: 15px;
            border:solid 8px #000; background-color: #f5f5f5;">

            <v-row v-if="detalles_os.estatus == 0 && hasPermission('programar_ot')" no-gutters>
                <v-col cols="2" class="pa-2">
                    <v-text-field v-model="fecha_visita_programada" label="Fecha de visita" type="date" variant="solo"
                        density="compact"></v-text-field>
                </v-col>
                <v-col cols="4" class="pa-2">
                    <v-select v-model="tecnicos_asignados" :items="tec_titulares" item-title="nombre" return-object item-value="id_usr" label="Titulares" density="compact" variant="solo"></v-select>
                </v-col>
                <v-col cols="4" class="pa-2">
                    <v-select v-model="auxiliares_asignados" :items="tec_auxiliares" item-title="nombre" return-object item-value="id_usr" label="Auxiliares" density="compact" variant="solo" multiple></v-select>
                </v-col>
                <v-col cols="2" class="pa-2">
                    <v-btn block color="warning" @click="modal = true">AGENDAR VISITA</v-btn>
                </v-col>
            </v-row>


            <v-row v-if="detalles_os.estatus == 1 && hasPermission('editar_orden')" no-gutters>
                <v-col cols="6">
                    <v-text-field label="Fecha y hora de inicio" type="datetime-local" variant="solo"
                        density="compact"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field label="Fecha y hora finalizado" type="datetime-local" variant="solo"
                        density="compact"></v-text-field>
                </v-col>
            </v-row>

        </div>

        <br />

        <div
            style="width: 100%; padding:10px; background-color: #002060; color: #fff;  border-radius: 15px; margin-bottom:10px;">
            <center>DATOS DEL CLIENTE</center>
        </div>
        <div style=" padding: 10px;  border-radius: 15px; border:solid 8px #000; background-color: #f5f5f5;">

            <v-row no-gutters>
                <v-col cols="6">
                    <v-text-field label="Nombre de la empresa" variant="solo" density="compact" v-model="detalles_os.nombre_cliente"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field label="Área de trabajo" variant="solo" density="compact"></v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6">
                    <v-text-field label="Dirección" variant="solo" density="compact"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field label="Línea" variant="solo" density="compact"></v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6">
                    <v-text-field label="Teléfono" variant="solo" density="compact"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field label="Máquina" variant="solo" density="compact"></v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6">
                    <v-text-field label="Nombre de contacto" variant="solo" density="compact"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field label="Nombre de proyecto" variant="solo" density="compact"></v-text-field>
                </v-col>
            </v-row>
        </div>
        <br />




        <v-row no-gutters style="border-radius: 15px; border: solid 8px #000000; ">
            <v-col cols="12" md="4" style="background-color:#002060; color: #ffffff;">
                <center> CUADRILLA</center>
                <v-radio-group>

                    <v-radio label="Mantenimiento" value="1"></v-radio>
                    <v-radio label="Operaciones" value="2"></v-radio>
                </v-radio-group>
            </v-col>
            <v-col cols="12" md="4" style="background-color:#c00000; color: #ffffff;">
                <center>
                    <br />

                    SERVICIO

                    <v-radio-group>
                        <v-radio label="Interno" value="1"></v-radio>
                        <v-radio label="Externo" value="2"></v-radio>
                    </v-radio-group>

                </center>
            </v-col>
            <v-col cols="12" md="4" style="background-color:#ffc000; color: #ffffff;">
                <center>
                    MOTIVO DE LA VISITA
                    <v-radio-group>
                        <div style="display: flex; justify-content: center;">
                            <v-radio label="Preventivo" value="1"></v-radio>
                            <v-radio label="Correctivo" value="5"></v-radio>
                        </div>
                        <div style="display: flex; justify-content: center;">
                            <v-radio label="Montaje" value="2"></v-radio>
                            <v-radio label="Garantía" value="6"></v-radio>
                        </div>
                        <div style="display: flex; justify-content: center;">
                            <v-radio label="Pruebas de carga" value="4"></v-radio>
                            <v-radio label="Levantamiento" value="3"></v-radio>
                        </div>
                    </v-radio-group>
                </center>

            </v-col>
        </v-row>

        <br />


        <v-row no-gutters style="border-radius: 15px; border: solid 8px #000000; ">
            <v-col cols="12" md="4" style="padding:10px; background-color:#ffc000; color: #ffffff;">

                DATOS GENERALES DE LA GRÚA
                <v-text-field label="Marca de la grúa" variant="solo" density="compact"></v-text-field>
                <v-text-field label="Tipo de grúa" variant="solo" density="compact"></v-text-field>
                <v-text-field label="Capacidad" variant="solo" density="compact"></v-text-field>
                <v-text-field label="Montada / Colgante" variant="solo" density="compact"></v-text-field>
                <v-text-field label="Trolley Manual/Eléctrica" variant="solo" density="compact"></v-text-field>
            </v-col>
            <v-col cols="12" md="4" style="padding:10px; background-color:#002060; color: #ffffff;">
                <center>
                    DATOS GENERALES ESTRUCTURA
                    <v-text-field label="Claro" variant="solo" density="compact" v-if="formato_uno==true"></v-text-field>
                    <v-text-field label="Giro" variant="solo" density="compact" v-if="formato_dos==true"></v-text-field>
                    <v-text-field label="Articulada" variant="solo" density="compact" v-if="detalles_os.nombre_tipo_unidad=='GRUA ARTICULADA'"></v-text-field>
                    <v-text-field label="Recorrido" variant="solo" density="compact" v-if="formato_uno==true"></v-text-field>
                    <v-text-field label="Largo de brazo" variant="solo" density="compact" v-if="formato_dos==true"></v-text-field>
                   
                </center>
            </v-col>
            <v-col cols="12" md="4" style="padding:10px; background-color:#c00000; color: #ffffff;">
                <center>
                    <v-text-field label="Numero de columnas" variant="solo" density="compact" v-if="formato_uno==true"></v-text-field>
                    <v-text-field label="Giro mecanico o electrico" variant="solo" density="compact"></v-text-field>
                    <v-text-field label="Claro entre columnas" variant="solo" density="compact" v-if="formato_uno==true"></v-text-field>
                    <v-text-field label="Tipo de alimentación" variant="solo" density="compact"></v-text-field>
                    <v-text-field label="Tipo de anclaje" variant="solo" density="compact"></v-text-field>
                    <v-text-field label="Radio de curvatura" variant="solo" density="compact" v-if="detalles_os.nombre_tipo_unidad=='GRUA ARTICULADA'"></v-text-field>

                </center>

            </v-col>
        </v-row>


        <br/>

        <v-row no-gutters style="border-radius: 15px; border: solid 8px #000000; ">
            <v-col cols="12" md="4" style="padding:10px; background-color:#002060; color: #ffffff;">

                DATOS GENERALES POLIPASTO
                <v-text-field label="Marca de polipasto" variant="solo" density="compact"></v-text-field>
                <v-text-field label="Voltaje" variant="solo" density="compact"></v-text-field>
                <v-text-field label="Modelo" variant="solo" density="compact"></v-text-field>
                <v-text-field label="Número de serie" variant="solo" density="compact"></v-text-field>
                <v-text-field label="Trolley Manual/Eléctrica" variant="solo" density="compact"></v-text-field>
            </v-col>
            <v-col cols="12" md="4" style="padding:10px; background-color:#c00000; color: #ffffff;">
                <center>
                    TIPO POLIPASTO
                    <v-radio-group>

                        <v-radio label="Eléctrico de cadena" value="1"></v-radio>
                        <v-radio label="Eléctrico de cable" value="5"></v-radio>
                        <v-radio label="Neumático" value="5"></v-radio>
                        <v-radio label="Manuak" value="5"></v-radio>


                    </v-radio-group>
                </center>
            </v-col>
            <v-col cols="12" md="4" style="padding:10px; background-color:#ffc000; color: #ffffff;">
                <center>
                    SISTEMA ELÉCTRICO

                    <v-text-field label="Tierra" variant="solo" density="compact"></v-text-field>
                    <v-text-field label="N° Líneas" variant="solo" density="compact"></v-text-field>
                    <v-text-field label="Tipo/Marca" variant="solo" density="compact"></v-text-field>
                    <v-text-field label="Amperaje" variant="solo" density="compact"></v-text-field>

                </center>

            </v-col>
        </v-row>

        <br />
        <div style="background-color: #c00000; padding: 10px; width: 100%; border-radius: 15px;
    border:solid 8px #000;">
            <v-textarea label="FALLAS REPORTADAS" variant="solo"></v-textarea>
        </div>

    </div>

    <v-dialog v-model="modal" persistent max-width="500">
        <v-card>
        <v-card-title class="subtitle-1 font-weight-black">
            ASIGNAR ORDEN DE SERVICIO
        </v-card-title>
        <v-card-subtitle class="subtitle-2 font-weight-black">¿ ESTA SEGURO DE QUERER CONTINUAR
            ?</v-card-subtitle>
        <v-divider class="my-0 py-3"></v-divider>
        <v-card-subtitle align="center" class="red--text font-weight-bold">
            SE AGENDARÁ UNA VISITA Y SE NOTIFICARÁ A LOS USUARIOS ASGINADOS
        </v-card-subtitle>
        <v-divider class="my-0 py-2"></v-divider>
        <v-card-actions>
            <v-spacer />
            <v-btn dark outlined color="gris" @click="modal = false">Regresar</v-btn>
            <v-btn dark color="error" :loading="agendar" :disabled="agendar" @click="agendar_visita()" >Continuar</v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import { mapState } from "vuex";
export default {
    props:{
        detalles: Object,
        tecnicos: Array,
        showAlert: Function,
        hasPermission:Function,
        appconf:Object
    },
    computed: {
        ...mapState("Session", ['session_key', 'user','permisos', 'loggedin']),
        ...mapState(['router']),
        screenWidth() {
        return window.innerWidth;
        },
        formato_uno(){
            //MONORRIEL, BIRRIEL, PORTICO, SEMI PORTICO
        if( this.detalles_os.nombre_tipo_unidad=='GRUA MONORRIEL'||this.detalles_os.nombre_tipo_unidad=='GRUA BIRRIEL'||this.detalles_os.nombre_tipo_unidad=='GRUA PORTICO'||this.detalles_os.nombre_tipo_unidad=='GRUA SEMI PORTICO'||this.detalles_os.nombre_tipo_unidad=='GRUA ARTICULADA'){
            return true;
        }
        else{
            return false;
        }


        },
        formato_dos(){
            if(this.detalles_os.nombre_tipo_unidad=='GRUA BANDERA'|| this.detalles_os.nombre_tipo_unidad=='GRUA RADIAL'||this.detalles_os.nombre_tipo_unidad=='GRUA ARTICULADA'){
                return true
            }
            else{
                return false
            }
        }
    },
    data() {
        return {
            text: "Caratula",

            _tecnicos:this.tecnicos,

            tecnicos_asignados:{},
            tec_titulares:[],
            detalles_os:{},
            auxiliares_asignados:[],
            tec_auxiliares:[],

            fecha_visita_programada:'',
            agendar:false,
            modal:false,
            comentarios:''
        }
    },

    watch: {
        tecnicos_asignados: {
            handler(newValue, oldValue) {
                this.tec_auxiliares = this._tecnicos.filter(us =>  newValue.id_usr !== us.id_usr);
                // this.tec_auxiliares = this._tecnicos.filter(us =>  !newValue.includes(us));
            },
            deep: true
        },

        auxiliares_asignados: {
            handler(newValue, oldValue) {
                this.tec_titulares = this._tecnicos.filter(us =>  !newValue.includes(us));
            },
            deep: true
        }
    },

    created(){
        
        this.detalles_os = this.detalles;

        this.tec_titulares = this._tecnicos;
        this.tec_auxiliares = this._tecnicos;
        
        // this.detalles_os.id_tecnicos_asignados
        this.tecnicos_asignados = this.tec_titulares.find(t => t.id_usr == this.detalles_os.id_tecnicos_asignados);

        // this.tecnicos_asignados = this.detalles_os.id_tecnicos_asignados != null ? (this.detalles_os.id_tecnicos_asignados.includes(',') ? this.detalles_os.id_tecnicos_asignados.split(',') : [this.detalles_os.id_tecnicos_asignados]) : [];
        // this.tecnicos_asignados = this.tec_titulares.length > 0 ? this.tec_titulares.filter(t => this.tecnicos_asignados.includes( String(t.id_usr) ) ) : [];
        
        this.auxiliares_asignados = this.detalles_os.id_auxiliares_asignados != null ? (this.detalles_os.id_auxiliares_asignados.includes(',') ? this.detalles_os.id_auxiliares_asignados.split(',') : [this.detalles_os.id_auxiliares_asignados]) : [];
        this.auxiliares_asignados = this.tec_titulares.length > 0 ? this.tec_titulares.filter(t => this.auxiliares_asignados.includes( String(t.id_usr) ) ) : [];
    },

    mounted(){

    },

    methods:{
        async agendar_visita(){
            this.agendar = true;

            // let tecnicos = this.tecnicos_asignados.map(t => t.id_usr);
            // tecnicos.join(',')
            let id_auxiliares_asignados = "";
            if(this.auxiliares_asignados.length > 0){
                let auxiliares = this.auxiliares_asignados.map(t => t.id_usr);
                id_auxiliares_asignados = auxiliares.join(',');
            }


            let datapost = {
                id_cliente:this.detalles_os.id_cliente,
                id_ot:this.detalles_os.id_ot,
                id_usr:this.user.id_usr,
                id_tecnicos_asignados: this.tecnicos_asignados.id_usr,
                id_auxiliares_asignados:id_auxiliares_asignados,
                fecha_visita_programada:this.fecha_visita_programada,
                comentarios:this.comentarios
            }

            this.$axios.post('orden.servicio.agendar.visita', datapost)

            .then(r => { 
                //change estatus? 
                //window.reload() 

                this.showAlert({
                activo: true, 
                texto:"Orden de servicio asignada con éxito", 
                color: "success", 
                vertical: true
                });

                this.detalles_os.estatus = 1;

                this.modal = false;

            }).catch((e) => {

                console.log(e)

            }).finally( () => {

                this.agendar = false;

            } )
        }
    }

    
}
</script>
<style>
/* https://bennettfeely.com/clippy/ */

.div_centro {
    background-color: #c00000;
    clip-path: polygon(0 0, 90% 0, 100% 100%, 10% 100%);
    padding: 2px;
}

.div_izq {
    background-color: #002060;
    clip-path: polygon(0 0, 63% 0, 100% 100%, 0 100%);

}

.div_der {
    background-color: #002060;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 33% 100%);
}

.contenedor_uno {
    background-color: #c00000;
    width: 100%;
    display: flex;
    justify-content: center;
    color: #fff;
    border-radius: 15px;
    border: solid 8px #000;

}

.t_1 {
    width: 20%;
}

.t_2 {
    width: 60%;
}

.t_3 {
    width: 20%;

}


/* TRES */



.div_centro_tres {
    background-color: #c00000;
    clip-path: polygon(0 0, 90% 0, 100% 100%, 10% 100%);
    padding: 2px;
}

.div_izq_tres {
    background-color: #002060;
    clip-path: polygon(0 0, 90% 0, 100% 100%, 0 100%);


}

.div_der_tres {
    background-color: #ffc000;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 9% 100%);

}

.contenedor_tres {
    background-color: #c00000;
    width: 100%;
    display: flex;
    justify-content: center;
    color: #fff;
    border-radius: 15px;
    border: solid 8px #000;

}

.t_1_tres {
    width: 33.5%;
}

.t_2_tres {
    width: 33%;
}

.t_3_tres {
    width: 33.7%;

}
</style>