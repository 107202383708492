<template>
  <div>
    <v-col cols="12" align="right">
      <h3 style="float: left;">AGENDA DE VISITAS</h3>
      <v-btn :block="screenWidth <= 500 ? true : false" elevation="0"
        :variant="tap_btn == 'calendario' ? 'outlined' : 'text'" size="large" 
        @click="tap_btn = 'calendario'">
        Calendario
      </v-btn>
      <v-btn :block="screenWidth <= 500 ? true : false" elevation="0"
        :variant="tap_btn == 'lista' ? 'outlined' : 'text'" size="large" 
        @click="tap_btn = 'lista'">
        Lista de visitas
      </v-btn>
      
    </v-col>
    <div style="background-color:#F5F5F5; padding:3px; margin-left:15px;margin-right:15px">
    </div>

    <div style="padding: 20px; margin: 10px; border-radius: 10px;">
      <Calendario  v-if="tap_btn == 'calendario'" :showAlert="showAlert" :hasPermission="hasPermission"/>
      <ListaVisitas  v-if="tap_btn == 'lista'" :showAlert="showAlert" :hasPermission="hasPermission"/>
    </div>


  </div>
</template>

<script>
import Calendario from "./Calendario.vue";
import ListaVisitas from "./ListaVisitas.vue";

import DetePikerInput from "@/components/DatePikerInput.vue";
import { mapState } from "vuex";
import moment from 'moment';
export default {
  components: {
    DetePikerInput,
    Calendario,
    ListaVisitas
  },
  setup() { },
  computed: {
    ...mapState("Session", ['session_key', 'user','permisos', 'loggedin']),
    ...mapState(['router']),
    screenWidth() {
      return window.innerWidth;
    }
  },

  props: {
    showAlert: Function,
    hasPermission: Function
  },
  data() {
    return {
      data: null,
      Loading: false,
      tap_btn: "calendario",
      visita:{},
      servicios_items:[],
      servicios_selected:[],
      estatus_compromiso:{}
    };
  },

  async created() {
    
  },



  methods: {

  
  },
};
</script>