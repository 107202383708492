<template>
    <div>



        <v-row no-gutters style="border-radius: 15px; border: solid 8px #000000; ">
            <v-col cols="12" md="3" style="background-color:#002060; color: #ffffff;">
                <!-- <center>
                    <br />
                    <h2>LOGO</h2>
                </center> -->
                <center>
                <img :src="appconf.logo" :width="appconf.logo_width" :height="appconf.logo_height"/>
            </center>
            </v-col>
            <v-col cols="12" md="6" style="background-color:#c00000; color: #ffffff;">
                <center><br />
                    <h1> DIAGNOSTICO FINAL</h1>
                </center>
            </v-col>
            <v-col v-html="appconf.ot_iso" cols="12" md="3" style="background-color:#ffc000; color: #ffffff;">
                
            </v-col>
        </v-row>
        <br />

        <div style="background-color: #c00000; padding: 10px; width: 100%; border-radius: 15px;
    border:solid 8px #000;">
            <!-- <v-textarea label="NOTAS" variant="solo"></v-textarea> -->

            <v-row>
                <v-col cols="12">
                    

                    <v-btn @click="this.$refs.diagnostico_final.reset();" icon color="blue" class="ml-3 mb-4">
                        <v-icon>
                            mdi-reload
                        </v-icon>
                    </v-btn>
<!-- 
                    <v-btn @click="this.$refs.diagnostico_final.reset();" class="ml-3 mb-4" icon color="blue">
                        <v-icon>
                            mdi-camera-burst
                        </v-icon>
                    </v-btn> -->

                  
                    
                    <!--      <vue-drawing-canvas 
                    ref="VueCanvasDrawing" 
                    v-model="draw" 
                    width="800"
                     height="600"
                     :background-image="backgroundImage"
                />

                    <v-btn @click="this.$refs.VueCanvasDrawing.reset();" icon color="blue">
                        <v-icon>
                            mdi-reload
                        </v-icon>
                    </v-btn>
                   -->
                    <center>
                        <vue-drawing-canvas :ref="id_canva" :canvasId="id_canva" :width="screenWidth"></vue-drawing-canvas>

                    </center>

                </v-col>
                <v-col cols="12">

                    <v-textarea v-model="transcript" variant="solo" label="Observaciones"></v-textarea>

                    <v-btn @click="recognition.start();" icon color="blue">
                        <v-icon>
                            mdi-microphone
                        </v-icon>
                    </v-btn>

                </v-col>
            </v-row>


        </div>


    </div>
</template>
<script>

import VueDrawingCanvas from 'vue-drawing-canvas';
export default {
    components: {
        VueDrawingCanvas
    },
    props: {
        appconf:Object
    },
    data() {
        return {
            transcript: '',
            recognition: null,
            draw: null,
            backgroundImage: null,
            id_canva: "diagnostico_final",


        }
    },
    computed: {
        screenWidth() {
            var ancho = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            if (ancho) {
                let resultado =0;
            
                let med=0;
                if(ancho>=1000){
                    med = ancho/12;
                    let estado= med*2;
                    resultado =ancho-estado-100;
                }else if(ancho < 1000){
                    resultado =ancho-100;
                }else{
                    resultado =200
                }
                return resultado;
            } else {
                return 200;
            }
        }


    },
    created() {

    },
    mounted() {




        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        // if ('webkitSpeechRecognition' in window) {
        this.recognition = new SpeechRecognition();//new webkitSpeechRecognition();
        this.recognition.lang = "es-ES";
        this.recognition.continuous = true;
        console.log(this.recognition);
        this.recognition.onresult = (event) => {
            let results = "";
            for (let i = 0; i < event.results.length; i++) {
                const transcript = event.results[i][0].transcript;
                results = results + transcript + "\n\r";
                // Haz algo con el texto reconocido, como mostrarlo en la interfaz de usuario.
            }

            this.transcript = results;

        }

        // this.recognition.start();
        // } else {
        // console.error('El reconocimiento de voz no está soportado por este navegador.');
        // }



    },
    methods: {
        async setImage(event) {
            let URL = window.URL;
            this.backgroundImage = URL.createObjectURL(event.target.files[0]);
            await this.$refs.diagnostico_final.redraw();
        },


    }
}
</script>