<template>
  <v-row class="pa-1 mb-5">
    <v-col cols="12" sm="2" lg="" class="py-0 my-0">
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Buscar visita"
        single-line
        hide-details
        filled dense
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="2" lg="" class="py-0 my-0">
      <v-select 
          v-model="estatus" :items="Estatus" item-title="nombre" item-value="id"  dense
            hide-details  placeholder="Estatus " return-object outlined append-icon="mdi-circle-slice-5"
        ></v-select>
    </v-col>
    <v-col cols="12" sm="2" lg="" class="py-0 my-0">
      <DetePikerInput @ObtenerFechaDP="fecha1_change" :label_dp="'Fecha desde'" :fecha_default="fecha1"/>
    </v-col>
    <v-col cols="12" sm="2" lg="" class="py-0 my-0">
      <DetePikerInput @ObtenerFechaDP="fecha2_change" :label_dp="'Fecha hasta'" :fecha_default="fecha2"/>
    </v-col>

    <!-- FALTA AGREGAR EL RANGO DE FECHAS. Y EXPORTACIONES ?  -->

    <v-col cols="12" sm="4" lg="" class="py-0 my-0  text-right">

      <!-- <v-btn  class="ma-1" dark color="green" @click="ImprimirExcel()"> <v-icon >mdi-microsoft-excel </v-icon> </v-btn> -->
      <!-- <v-btn  dark color="green" @click="generarExcel('solicitudes')"> <v-icon >mdi-microsoft-excel </v-icon> </v-btn> -->
      <v-btn  class=" ma-1" color="success" dark   v-if="hasPermission('agregar_visita')"   @click="modal = true">Agendar visita </v-btn>
      <v-btn  class=" ma-1" color="gray" text dark    @click="consulta()" ><v-icon>mdi-refresh</v-icon> </v-btn>
    </v-col>
  </v-row>
  <div class="text-center section">
    <v-calendar
      class="custom-calendar max-w-full"
      :masks="masks"
      :attributes="attributes"
      disable-page-swipe
      is-expanded
    >
      <template v-slot:day-content="{ day, attributes }">
        <div class="flex flex-col h-full z-10 overflow-hidden">
          <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
          <div class="flex-grow overflow-y-auto overflow-x-auto">
            <p
              v-for="attr in attributes"
              :key="attr.key"
              class="text-xs leading-tight rounded-sm p-1 mt-0 mb-1"
              :class="attr.customData.class"
            >
              {{ attr.customData.title }}
            </p>
          </div>
        </div>
      </template>
    </v-calendar>
  </div>
</template>

<script>
import DetePikerInput from "@/components/DatePikerInput.vue";
import moment from 'moment';
moment.locale('es-ES');  
export default {
  props:{
    showAlert: Function,
    hasPermission: Function
  },

  components:{
    DetePikerInput
  },

  data() {
    const month = new Date().getMonth();
    const year = new Date().getFullYear();
    return {

      fecha_actual:moment().format("YYYY-MM-DD"),

      fecha1: moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD"),
      
      fecha2: moment().subtract('months').endOf('months').format("YYYY-MM-DD"),

      masks: {
        weekdays: 'WWW',
      },
      attributes: [
        /*{
          key: 1,
          customData: {
            title: 'Lunch with mom.',
            class: 'bg-red-600 text-white',
          },
          dates: new Date(year, month, 1),
        },*/
      ],
    };
  },
  methods:{
    moment_format(date, format){
      return moment(date).format(format)
    },
    fecha1_change(fecha) {
      this.fecha1 = moment(fecha).format('YYYY-MM-DD');
    },

    fecha2_change(fecha) {
      this.fecha2 = moment(fecha).format('YYYY-MM-DD');
    },
  }
};
</script>

<style >
::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track {
  display: none;
}
.custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 90px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;
  border-radius: 0;
  width: 100%;
}
.custom-calendar.vc-container .vc-header {
  background-color: #f1f5f8;
  padding: 10px 0;
}
.custom-calendar.vc-container .vc-weeks {
  padding: 0;
}
.custom-calendar.vc-container .vc-weekday {
  background-color: var(--weekday-bg);
  border-bottom: var(--weekday-border);
  border-top: var(--weekday-border);
  padding: 5px 0;
}
.custom-calendar.vc-container .vc-day {
  padding: 0 5px 3px 5px;
  text-align: left;
  height: var(--day-height);
  min-width: var(--day-width);
  background-color: white;
}
.custom-calendar.vc-container .vc-day.weekday-1, .custom-calendar.vc-container .vc-day.weekday-7 {
  background-color: #eff8ff;
}
.custom-calendar.vc-container .vc-day:not(.on-bottom) {
  border-bottom: var(--day-border);
}
.custom-calendar.vc-container .vc-day:not(.on-bottom).weekday-1 {
  border-bottom: var(--day-border-highlight);
}
.custom-calendar.vc-container .vc-day:not(.on-right) {
  border-right: var(--day-border);
}
.custom-calendar.vc-container .vc-day-dots {
  margin-bottom: 5px;
}
</style>