<template>
  <div>
        <!-- <router-view v-if="Logeado"/> -->
    <v-row col="12"  class="pa-3" >
      <v-row class="pa-3">
        <v-col cols="12">

          <!-- <v-snackbar v-model="snackbar" :timeout="3000" top color= "red darken-4" > {{ text }}
            <v-btn color="white" text @click="snackbar = false"> <v-icon>clear</v-icon></v-btn>
          </v-snackbar> -->
<!-- 
          <v-snackbar v-model="alerta.activo" multi-line vertical top right :color="alerta.color" > 
            <strong> {{alerta.texto}} </strong>
            <template v-slot:action="{ attrs }">
              <v-btn color="white" text @click="alerta.activo = false" v-bind="attrs"> Cerrar </v-btn>
            </template>
          </v-snackbar> -->


          <v-row col="12" justify="center" class="pa-3" >

              <v-col cols="12" class="text-center my-8"> <!-- LOGO DE LA VISTA -->
                <v-card align="center" flat clas="pa-0 py-0">

                <img 
                    :src="appconf.logo" 
                    :width="appconf.logo_width" 
                    :height="appconf.logo_height"  
                    class="shrink "
                    transition="fab-transition"
                    contain
                /> <br>
                </v-card>
                
              </v-col>
            <v-col cols="12" class="py-0">
              <v-form v-model="valid" :lazy-validation="lazy" >
                <v-text-field  
                  v-model="usuario"
                  :rules="usarioRules"
                  label="Usuario" 
                  autocomplete="null"
                  append-icon="person" 
                  color="rosa" 
                  clearable 
                  filled
                  dense
                  class="font-weight-bold text-h6"
                >
                </v-text-field>
                <!-- CONTRASEÑA -->
                <v-text-field
                  :append-icon="show ? 'mdi-eye-off' : 'mdi-eye'"
                  :type="show ? 'text' : 'password'"
                  label="Contraseña"
                  v-model="password"
                  @click:append="show = !show"
                  autocomplete="null"
                  clearable
                  filled
                  dense
                  color="rosa"
                  :rules="contraRules"
                  @keyup.enter="iniciar_sesion(usuario, password)"
                  class="font-weight-bold text-h6"
                ></v-text-field> 
              

              <v-card-actions class="pa-0 mt-5"> <!-- INICIAR SESION -->
                <v-spacer></v-spacer>

                <v-btn block 
                        color="warning white--text"  
                        :disabled="!valid || iniciar" 
                        
                        @click="iniciar_sesion(usuario, password)"
                        :loading="iniciar"
                >
                  Iniciar Sesión
                </v-btn>

              </v-card-actions>
            </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "ViewLogin",
  // props: ["alerta"],
  
  props:{
    Login: Function,
    alerta: Object,
    appconf: Object
  },
  data() {
    return {
      value_scren: false,
      usuario: "",
      password: "",
      roles:[],
      show: false,
      usarioRules: [v => !!v || 'Es requerido'],
      contraRules: [v => !!v || 'Es requerido'],
      iniciar: false,
      valid:true,
      lazy:false,
      visible: false,
      timeout: 2000,
    };
  },

  async created(){
    await this.$axios.get("consulta.roles").then((r) => {
      this.roles = r.data;
    });
  },
  mounted() {
  },
  methods: {
    iniciar_sesion(u, p){
      this.iniciar = true
      this.Login({username:u, contrasena:p}).then((user) => {

        let id_usr = user.id_usr;

        this.$router.push({name:'HomeComponent'});
        document.addEventListener("deviceready", () => {
          window.cordova.exec(
            function (successMessage) {
              console.log(
                "Java code executed successfully:",
                successMessage
              );
            },
            function (errorMessage) {
              console.error("Error from Java:", errorMessage);
            },
            "NotificationsPushPlugin",
            "handleButtonClick",
            [id_usr]
          );
        });
        //emit al app?
        //alert vienbenido
      }).catch(error => {
        alert(error);
        //push alert?
      }).finally(() => {
        this.iniciar=false
      });
    }
  },
};
</script>