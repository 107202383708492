<template>
    <div>
        <img src="https://imgtr.ee/images/2023/08/28/4a96e5cb7c2c5466c4bdbf5f55de3aef.png" alt="4a96e5cb7c2c5466c4bdbf5f55de3aef.png" border="0">
    </div>
</template>


<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "NavSideBar",
  // props: {
  //   session: Object,
  //   salir: Function
  // },
  data() {
    return {
      drawer: false,
      
    //   roles:[
    //     {id:1, roleName:'Super admin'},
    //     {id:2, roleName:'Vendedor'},
    //     {id:3, roleName:'Cliente'},
    //     {id:4, roleName:'Técnico'},
    //     {id:5, roleName:'Administrador'}
    //   ]
    };
  },
  computed: {
    ...mapState("Session", ['session_key', 'session_data', 'loggedin']),
  },
  mounted() {
    //localStorage.getItem("session.data")
    // console.log(this.session);
    // console.log("session: "+ JSON.parse(localStorage.getItem("session.data") ));
  },
  created(){
    //this.session_data.role = this.roles.filter(r => r.id == this.session_data.id_role);
    //this.session_data.role = this.session_data.role[0]
  },
  methods: {
    ...mapMutations("Session", ['session_destroy']),
  },
};
</script>