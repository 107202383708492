<template>
    <div>
        <v-btn @click="modal = true" icon color="blue">
            <v-icon>
                mdi-image
            </v-icon>
        </v-btn>

        <v-dialog v-model="modal" width="700px">
            <v-card style="padding:10px;">
                <center>
                    <h2>Agregar imagenes</h2>
                </center>



                <v-file-input label="Subir Imagenes" accept="image/*" v-model="selectedImages" variant="outlined"
                    ref="fileInput" type="file" @change="handleFileUpload" multiple></v-file-input>

                <v-row no-gutters>
                    <v-col cols="12" md="12">
                        <v-row>
                            <v-col v-for="(image, index) in img_views" :key="index" cols="12" md="4">
                                <v-img :src="image" aspect-ratio="1"></v-img>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>



                <!--  <v-file-input accept="image/*" label="Imagenes" v-model="data_item.imagenes_item" @input="handleFileUpload()"
                    multiple variant="underlined"></v-file-input>
                <div v-for="(imagen, index) in data_item.imagenes_item" :key="index">
                    <img :src="imagen.url" alt="Imagen seleccionada">
                </div>
                {{ (typeof data_item.imagenes_item) }} -->
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
export default {
    /*  props: ["data_item"], */
    data() {
        return {
            modal: false,
            data_item: {
                imagenes_item: []
            },
            selectedImages: [],
            selectedImages: [],
            selectedFiles: [],
            img_views: [],
        }
    },
    watch: {
        selectedImages() {
            this.obtenerImagen();
        },
    },

     methods: {
    

    obtenerImagen() {
        this.img_views = [];
        this.selectedImages.forEach(img => {
            this.img_views.push(URL.createObjectURL(img))
        });
    },

    handleFileUpload(event) {
        this.selectedFiles = event.target.files;
    },


}
}
</script>