<template>
    <div class="card_contenedor">
    <div class="center_div">
        <span class="title_card_2">ESTRUCTURA</span>
    </div>
    <v-sheet class="pa-5">
        <v-row no-gutters>
            <v-col cols="12">
                <v-select 
                    v-model="item_lev.condiciones_estructura" 
                    label="Condiciones de la estructura"
                    :items="['Buena', 'Requiere ajuste', 'Requiere reparación', 'Cambio requerido', 'Bajo aceite, grasa o corrosión', 'Limpieza o pintura requerido']"
                    :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                ></v-select>

                <v-select 
                    v-model="item_lev.paralelismo_estructura" 
                    label="Paralelismo trabes o columna"
                    :items="['Buena', 'Requiere ajuste', 'Requiere reparación', 'Cambio requerido', 'Bajo aceite, grasa o corrosión', 'Limpieza o pintura requerido']"
                    :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                ></v-select>

                <v-select 
                    v-model="item_lev.posibles_fallas" 
                    label="Posibles fallas a vista"
                    :items="['Buena', 'Requiere ajuste', 'Requiere reparación', 'Cambio requerido', 'Bajo aceite, grasa o corrosión', 'Limpieza o pintura requerido']"
                    :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                ></v-select>


                <v-select 
                    v-model="item_lev.nivel_oxidacion" 
                    label="Nivel de oxidación"
                    :items="['Buena', 'Requiere ajuste', 'Requiere reparación', 'Cambio requerido', 'Bajo aceite, grasa o corrosión', 'Limpieza o pintura requerido']"
                    :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                ></v-select>


                <v-select 
                    v-model="item_lev.tipo_viga" 
                    label="Tipo de viga para recorrido (monoriel)(birriel)"
                    :items="['Buena', 'Requiere ajuste', 'Requiere reparación', 'Cambio requerido', 'Bajo aceite, grasa o corrosión', 'Limpieza o pintura requerido']"
                    :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                ></v-select>

                <v-select 
                    v-model="item_lev.estandarizaciones" 
                    label="Estandarización de capacidades"
                    :items="['Buena', 'Requiere ajuste', 'Requiere reparación', 'Cambio requerido', 'Bajo aceite, grasa o corrosión', 'Limpieza o pintura requerido']"
                    :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                ></v-select>

                <v-select 
                    v-model="item_lev.grietas" 
                    label="Aparición de grietas"
                    :items="['Buena', 'Requiere ajuste', 'Requiere reparación', 'Cambio requerido', 'Bajo aceite, grasa o corrosión', 'Limpieza o pintura requerido']"
                    :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                ></v-select>

                <v-select 
                    v-model="item_lev.condiciones_riel_cuadrado" 
                    label="Condiciones de riel cuadrado"
                    :items="['Buena', 'Requiere ajuste', 'Requiere reparación', 'Cambio requerido', 'Bajo aceite, grasa o corrosión', 'Limpieza o pintura requerido']"
                    :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                ></v-select>


                <v-select 
                    v-model="item_lev.condiciones_viga_trabe_carril" 
                    label="Condiciones de viga de trabe carril"
                    :items="['Buena', 'Requiere ajuste', 'Requiere reparación', 'Cambio requerido', 'Bajo aceite, grasa o corrosión', 'Limpieza o pintura requerido']"
                    :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                ></v-select>


                <v-select 
                    v-model="item_lev.condiciones_vida_puente" 
                    label="Condiciones de vida de puente (anexar tipo)"
                    :items="['Buena', 'Requiere ajuste', 'Requiere reparación', 'Cambio requerido', 'Bajo aceite, grasa o corrosión', 'Limpieza o pintura requerido']"
                    :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                ></v-select>


                <v-select 
                    v-model="item_lev.medida_piso" 
                    label="Medida de piso a parte inferior de viga"
                    :items="['Buena', 'Requiere ajuste', 'Requiere reparación', 'Cambio requerido', 'Bajo aceite, grasa o corrosión', 'Limpieza o pintura requerido']"
                    :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                ></v-select>


                <v-select 
                    v-model="item_lev.rieles" 
                    label="Juntas en los rieles"
                    :items="['Buena', 'Requiere ajuste', 'Requiere reparación', 'Cambio requerido', 'Bajo aceite, grasa o corrosión', 'Limpieza o pintura requerido']"
                    :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                ></v-select>

                <v-select 
                    v-model="item_lev.rieles_trabe" 
                    label="Juntas en los rieles de trabe"
                    :items="['Buena', 'Requiere ajuste', 'Requiere reparación', 'Cambio requerido', 'Bajo aceite, grasa o corrosión', 'Limpieza o pintura requerido']"
                    :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                ></v-select>


                <v-select 
                    v-model="item_lev.cartabones" 
                    label="Cartabones y refuerzos"
                    :items="['Buena', 'Requiere ajuste', 'Requiere reparación', 'Cambio requerido', 'Bajo aceite, grasa o corrosión', 'Limpieza o pintura requerido']"
                    :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                ></v-select>


                <v-select 
                    v-model="item_lev.tirantes" 
                    label="Tirantes"
                    :items="['Buena', 'Requiere ajuste', 'Requiere reparación', 'Cambio requerido', 'Bajo aceite, grasa o corrosión', 'Limpieza o pintura requerido']"
                    :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                ></v-select>


                <v-select 
                    v-model="item_lev.tirantes_brazo" 
                    label="Tirantes de brazo"
                    :items="['Buena', 'Requiere ajuste', 'Requiere reparación', 'Cambio requerido', 'Bajo aceite, grasa o corrosión', 'Limpieza o pintura requerido']"
                    :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                ></v-select>


                <v-select 
                    v-model="item_lev.arriostramiento" 
                    label="Puntos de arriostramiento"
                    :items="['Buena', 'Requiere ajuste', 'Requiere reparación', 'Cambio requerido', 'Bajo aceite, grasa o corrosión', 'Limpieza o pintura requerido']"
                    :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                ></v-select>


                <v-select 
                    v-model="item_lev.anclajestructura" 
                    label="Anclaje estructura al piso"
                    :items="['Buena', 'Requiere ajuste', 'Requiere reparación', 'Cambio requerido', 'Bajo aceite, grasa o corrosión', 'Limpieza o pintura requerido']"
                    :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                ></v-select>


                <v-select 
                    v-model="item_lev.tornilleria_columnas" 
                    label="Tornilleria de columnas"
                    :items="['Buena', 'Requiere ajuste', 'Requiere reparación', 'Cambio requerido', 'Bajo aceite, grasa o corrosión', 'Limpieza o pintura requerido']"
                    :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                ></v-select>

                <v-select 
                    v-model="item_lev.tornilleria_trabe_puente" 
                    label="Tornilleria de trabe y puente"
                    :items="['Buena', 'Requiere ajuste', 'Requiere reparación', 'Cambio requerido', 'Bajo aceite, grasa o corrosión', 'Limpieza o pintura requerido']"
                    :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                ></v-select>



                <v-select 
                    v-model="item_lev.desniveles_estructurales" 
                    label="Desniveles estructurales"
                    :items="['Buena', 'Requiere ajuste', 'Requiere reparación', 'Cambio requerido', 'Bajo aceite, grasa o corrosión', 'Limpieza o pintura requerido']"
                    :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                ></v-select>


                <v-select 
                    v-model="item_lev.vencimientos_vigas" 
                    label="Posibles vencimientos vigas"
                    :items="['Buena', 'Requiere ajuste', 'Requiere reparación', 'Cambio requerido', 'Bajo aceite, grasa o corrosión', 'Limpieza o pintura requerido']"
                    :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                ></v-select>


                <v-select 
                    v-model="item_lev.golpeteos_vigas" 
                    label="Golpeteos vencimientos vigas"
                    :items="['Buena', 'Requiere ajuste', 'Requiere reparación', 'Cambio requerido', 'Bajo aceite, grasa o corrosión', 'Limpieza o pintura requerido']"
                    :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                ></v-select>


                <v-select 
                    v-model="item_lev.golpeteos_recorridos" 
                    label="Golpeteos en recorrido"
                    :items="['Buena', 'Requiere ajuste', 'Requiere reparación', 'Cambio requerido', 'Bajo aceite, grasa o corrosión', 'Limpieza o pintura requerido']"
                    :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                ></v-select>


                <v-select 
                    v-model="item_lev.cabezales_troles" 
                    label="Cabazales y troles arranque uniforme"
                    :items="['Buena', 'Requiere ajuste', 'Requiere reparación', 'Cambio requerido', 'Bajo aceite, grasa o corrosión', 'Limpieza o pintura requerido']"
                    :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                ></v-select>


                <v-select 
                    v-model="item_lev.sujecion_trole" 
                    label="Puntos de sujeción trole"
                    :items="['Buena', 'Requiere ajuste', 'Requiere reparación', 'Cambio requerido', 'Bajo aceite, grasa o corrosión', 'Limpieza o pintura requerido']"
                    :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                ></v-select>


                <v-select 
                    v-model="item_lev.sujecion_cabezales" 
                    label="Puntos de sujeción cabezales"
                    :items="['Buena', 'Requiere ajuste', 'Requiere reparación', 'Cambio requerido', 'Bajo aceite, grasa o corrosión', 'Limpieza o pintura requerido']"
                    :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                ></v-select>

                <v-select 
                    v-model="item_lev.sujecion_puente" 
                    label="Puntos de sujecion puente"
                    :items="['Buena', 'Requiere ajuste', 'Requiere reparación', 'Cambio requerido', 'Bajo aceite, grasa o corrosión', 'Limpieza o pintura requerido']"
                    :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                ></v-select>


                <v-select 
                    v-model="item_lev.fijacion_polipasto" 
                    label="Fijación polipasto al trole"
                    :items="['Buena', 'Requiere ajuste', 'Requiere reparación', 'Cambio requerido', 'Bajo aceite, grasa o corrosión', 'Limpieza o pintura requerido']"
                    :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                ></v-select>

                <v-select 
                    v-model="item_lev.tipo_puente_claro" 
                    label="Tipo de puente en el claro (monorriel)(birriel)"
                    :items="['Buena', 'Requiere ajuste', 'Requiere reparación', 'Cambio requerido', 'Bajo aceite, grasa o corrosión', 'Limpieza o pintura requerido']"
                    :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                ></v-select>

                <v-select 
                    v-model="item_lev.tornilleria_trabe_puente" 
                    label="Tornilleria de trabe y puente"
                    :items="['Buena', 'Requiere ajuste', 'Requiere reparación', 'Cambio requerido', 'Bajo aceite, grasa o corrosión', 'Limpieza o pintura requerido']"
                    :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                ></v-select>

            </v-col>
        </v-row>
    </v-sheet>
  </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("Session", ['session_key', 'session_data', 'loggedin']),
    },
    props: {
        is_new: Boolean,
        restore_lev: Object,
        visita: Object,
        id_tipo_unidad: String,
        cargarLevantamientos: Function,
        data_unidad_nombre: Object,
    },
    data() {
        return {
            item_lev: {},
            selectedImages: [],
            selectedImages2: [],
            img_views: [],
            img_views2:[],
            selectedFiles: [],
            selectedFiles2: [],
            rules: [
                (value) => {
                    if (value) return true;

                    return "Este campo es obligatorio";
                },
            ],
            imagenUrl: null,
            data_unidad_nombre:this.is_new==true?this.data_unidad_nombre:this.restore_lev.tipo_unidad_nombre,
            img_borrar:[],
        }
    },
    watch: {
        
        data_unidad_nombre(){
            this.data_unidad_nombre=this.is_new==true?this.data_unidad_nombre:this.restore_lev.tipo_unidad_nombre
        }

    },
    created() {

        if (!this.is_new) {
            this.item_lev = this.restore_lev;
            this.item_lev.tipo_polipastos = this.restore_lev.tipo_polipastos == null ? [] : (this.restore_lev.tipo_polipastos.includes(';') ? this.restore_lev.tipo_polipastos.split(";") : this.restore_lev.tipo_polipastos);

        } else {
            this.item_lev.tipo_polipastos = []
        }

    },



    methods: {

    }
}
</script>

<style scoped>
.card_contenedor {

    margin-top: 20px;
    border: solid 2px #000;
    width: 100%;
    border-radius: 5px;
}

.title_card {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 5px;
    padding-top: 5px;
    letter-spacing: 2px;
    background-color: #FFD600;
}


.center_div {

    width: 100%;
    top: -25px;
    display: flex;
    justify-content: center;
}

.center_element {
    display: flex;
    justify-content: center
}


.color_letter {
    color: #000000;
    padding: 4px;
    background-color: #FFD600;
}

.color_spacer {
    color: #000000;
    padding: 4px;

}

.space_container {
    margin-top: -20px;
}

.mg_tn {
    margin-top: -30px;
}

.nota_contenedor {
    padding: 10px;
    border: solid 2px #000000;
    font-size: 18px;
    color: #5F5F5F;
    font-weight: bold;
}

.title_card_2 {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 5px;
    padding-top: 5px;
    letter-spacing: 2px;
    background-color: #0D3961;
    color: #fff
}

.imagen-opaca {
  opacity: 0.2; 
}
</style>