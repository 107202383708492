<template>
  <div>
    <v-row class="pa-0 ma-3" justify="center">
      <v-col cols="12">
        <h3> ORDENES DE SERVICIO </h3>
        <v-card class="mt-3" outlined>
          <v-card-actions>
            <v-text-field v-model="search" append-icon="search" label="Buscar orden de servicio" single-line
              hide-details></v-text-field>
            <v-spacer></v-spacer>
            <v-btn color="green" @click="data = {}; modal = true; es_nuevo = true" :es_nuevo="es_nuevo">Agregar </v-btn>
            <v-btn small class="gray" icon dark @click="consulta()"><v-icon>mdi-refresh</v-icon> </v-btn>
          </v-card-actions>

          <v-data-table v-model:items-per-page="itemsPerPage" :headers="headers" :items="items" :search="search"
            fixed-header hide-default-footer :loading="Loading" loading-text="Cargando... Por favor espere."
            no-data-text="Aún no hay móduos registrados" dense>
            <template v-slot:item.action="{ item }">
              <!-- <v-btn v-if="user.key_role != 'tecnico'" small text color="warning" dark
                @click="data = item.selectable; modal = true, es_nuevo = false"><v-icon> mdi-pencil </v-icon> </v-btn>

              <v-btn small text color="warning" dark
                @click="infoOT(item.selectable.id_ot)"><v-icon> mdi-send</v-icon> </v-btn> -->

                <v-btn small text color="warning" dark
                @click="infoOT(item.selectable.id_ot)"><v-icon> mdi-send</v-icon> </v-btn>
                
            </template>

            <template v-slot:item.estatus="{ item }">
              <v-chip :color="GetEstusColor(item.selectable.estatus)">

                {{ GetEstatus(item.selectable.estatus) }}
              </v-chip>
            </template>
          </v-data-table>

        </v-card>

        <v-dialog persistent v-model="modal" width="700px">
          <v-card class="pa-5">
            <Modal :user="user" :item="data" :es_nuevo="es_nuevo" :showAlert="showAlert"
              :consulta="consulta" :catalogos="catalogos" @modal="modal = $event" />
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>
    
<script>
import Modal from './ModalOrdenServicio.vue';
import { mapState } from "vuex";

export default {
  props: {
    showAlert: Function
  },
  components: {
    Modal
  },
  data() {
    return {
      tableName: 'modulos',
      search: '',
      itemsPerPage: 100,
      items: [],
      data: {},
      Loading: false,
      modal: false,
      es_nuevo: false,
      headers: [
        { title: '#', align: 'left', key: 'id_ot' },
        { title: 'Cliente', align: 'left', key: 'nombre_cliente' },
        { title: 'Servicio', align: 'left', key: 'nombre_servicio' },
        { title: 'Tipo unidad', align: 'left', key: 'nombre_tipo_unidad' },
        { title: 'Unidad', align: 'left', key: 'nombre_unidad' },
        { title: 'Solicitante', align: 'left', key: 'nombre_solicitante' },
        { title: 'Estatus', align: 'left', key: 'estatus' },
        { title: '', align: 'right', key: 'action', sortable: false },

      ],
      catalogos: []
    }
  },

  created() {
    this.consulta();
    this.obtenerCatalogos();
  },

  computed: {
    ...mapState("Session", ['session_key', 'user', 'loggedin']),
  },


  methods: {

    infoOT(item) {
      this.$router.push({ name: 'VistaOrdenSelected', params: { id_ot: item } });

    },

    async consulta() {
      let datapost = {
      }

      if(this.user.role_name == 'Cliente'){
        datapost = {...datapost, id_cliente:this.user.id_usr}
      }

      this.Loading = true;
      await this.$axios.post('obtener.ordenes.servicio', datapost).then((res) => {
        this.items = res.data;

      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.Loading = false;
      });
    },

    async obtenerCatalogos() {
      await this.$axios.get('obtener.catalogos.ordenes.servicio').then((res) => {

        this.catalogos = res.data;
      }).catch((err) => {
        console.log(err);
      })
    },

    GetEstusColor(e) {
      let c = "";
      switch (e) {
        case 0:
          c = "blue accent-4";
          break;
        case 1:
          c = "orange accent-4";
          break;
          c = "";
        case 2:
          c = "green accent-4";
          break;
        default:
          c = "blue accent-4";
          break;
      }
      return c;
    },
    GetEstatus(e) {
      let c = "";
      switch (e) {
        case 0:
          c = "Pendiente";
          break;
        case 1:
          c = "Programada";
          break;
          c = "";
        case 2:
          c = "Finalizada";
          break;
        default:
          c = "Pendiente";
          break;
      }
      return c;
    }
  }
}
</script>
    