<template>
  <div>
    <v-row class="pa-0 ma-3" justify="center">
  		<v-col cols="12">
				<v-card-actions class="font-weight-black headline"> 
					<v-row>
						<v-col cols="12" sm="6" lg="8">Clientes</v-col>
						<v-col cols="12" sm="6" lg="4">
							<v-select align="right"
                  v-model="filter" :items="filters" item-title="role_desc" item-value="id_role"  dense
                    hide-details  placeholder="Estatus " outlined append-icon="mdi-circle-slice-5"
                ></v-select> 
						</v-col>
					</v-row>	
				</v-card-actions>
				<v-card class="mt-3" outlined>
					<v-card-actions>
			      <v-text-field v-model="search" append-icon="search" label="Buscar cliente" single-line hide-details></v-text-field>
			      <v-spacer></v-spacer>
			      <v-btn small color="green" v-if="hasPermission('agregar_clientes')" dark @click="cliente = {}; modal = true">Agregar </v-btn>
			      <v-btn small class="gray" icon dark ><v-icon>mdi-refresh</v-icon> </v-btn>
			    </v-card-actions>
				
			    <v-data-table 
						v-model:items-per-page="itemsPerPage"
			      :headers="headers"
			      :items="clientes"
			      :search="search"
			      fixed-header
						hide-default-footer
						:loading ="Loading"
						loading-text="Cargando... Por favor espere."
						dense
			    >
			    	<template v-slot:item.action="{ item }" > 
			    		<v-btn  small text color="warning" dark @click="cliente = item.selectable; modal = true"><v-icon> mdi-account-edit </v-icon> </v-btn> 
				    </template>

						<template v-slot:item.estatus="{ item }" > 
			    		<span :style="'color:'+(item.selectable.estatus == 0 ? 'red' : 'green') + ' !important'">
								{{ item.selectable.rol_principal == 2 ? 'Cliente' : 'Prospecto' }} {{ item.selectable.estatus == 0 ? 'inactivo' : 'activo' }}
							</span>
				    </template>

			    </v-data-table>

			  </v-card>
				<!-- PAGINACION -->
				<!-- <div class="text-center pt-2">
					<v-pagination v-model="page" :length="pageCount"></v-pagination>
				</div> -->
				
				 <v-dialog persistent v-model="modal" width="700px" v-if="hasPermission('agregar_clientes')">	
		    	<v-card class="pa-5">
						<ClientesModal :cliente="cliente" :showAlert="showAlert" :consultaClientes="consultaClientes" @modal="modal = $event" :es_cita="false"/>
		    	</v-card>
		    </v-dialog>
  		</v-col>
  	</v-row>
  </div>
</template>

<script>
 /* eslint-disable */
	// import ControlClientes  from '@/views/Catalogos/Clientes/ControlClientes.vue';
	import ClientesModal from './ClientesModal.vue';
  import axios from 'axios'
	// import {mapGetters, mapActions} from 'vuex';

	export default {
		props: {
			showAlert: Function,
			hasPermission: Function
		},
		components: {
			ClientesModal
		},
		data () {
				return {

					page: 0,
					pageCount: 0,
					itemsPerPage: 100,
					filter:null,
					filters:[{
						id_role:null,
						role_desc:'Todos'
					}, {
						id_role:2,
						role_desc:'Clientes'
					}, {
						id_role:3,
						role_desc:'Prospectos'
					}],

					search: '',
					clientes:[],
					cliente:{},
					Loading:false,

					modal: false,

					param: 0,
					edit:'',
					
					headers:[
						{ title: '#'  					 , align: 'left'  , key: 'id_usr' },
						{ title: 'RFC'			 , align: 'left'  , key: 'rfc' 	},
						{ title: 'Nombre'	 , align: 'left'  , key: 'nombre' },
						{ title: 'Dirección', align: 'left'  , key: 'direccion' },
						{ title: 'Ciudad' 	 , align: 'left'  , key: 'ciudad' },
						{ title: 'Estado' 	 , align: 'left'  , key: 'estado' },
						{ title: 'CP' 	     , align: 'left'  , key: 'codigo_postal' },
						{ title: 'Tel.' 	     , align: 'left'  , key: 'telefono' },
						{ title: 'Correo' 	     , align: 'left'  , key: 'correo' },
						{ title: 'estatus'	 , align: 'left'  , key: 'estatus' 	},
						{ title: ''  			 , align: 'right' , key: 'action', sortable: false },

					],
				}
			},

			created(){
				this.consultaClientes();
				console.log(this.clientes);
				//this.clientes = await this.consultaClientes();
				// this.consultaClientes() // CONSULTAR CLIENTES A VUEX
			},

			watch: {
				filter(){
					this.consultaClientes();
				}
			},

			computed:{
				// tamanioPantalla () {
				// 	// console.log(this.$vuetify.breakpoint)
				// 	switch (this.$vuetify.breakpoint.name) {
				// 		case 'xs':
				// 			return this.$vuetify.breakpoint.height -300
				// 		break;
				// 		case 'sm': 
				// 			return this.$vuetify.breakpoint.height -300
				// 		break;
				// 		case 'md':
				// 			return this.$vuetify.breakpoint.height -300
				// 		break;
				// 		case 'lg':
				// 			return this.$vuetify.breakpoint.height -300
				// 		break;
				// 		case 'xl':
				// 			return this.$vuetify.breakpoint.height -300
				// 		break;
				// 	}
				// },
			},

			methods:{
				async consultaClientes(){
					this.Loading=true;
					axios.post('consulta.clientes', {id_role:this.filter}).then((res)=>{
						console.log(res.data);
						this.clientes = res.data;
					}).catch((err)=>{
						console.log(err);
					}).finally( ()=> {
						this.Loading=false;
					});
				},
				abrirModal(action, items){
					this.param = action;
					this.edit = items;
					this.dialog = true;
				},
				
				cambiaEstatus(data){
					const payload = { id: data.id, estatus: !data.estatus };
					axios.post('cambia.estatus',payload).then((res)=>{
            console.log(res);
						this.consultaClientes();
					}).catch((err)=>{
						console.log('err', err)
					})
				}
			}
	}
	 /* eslint-disable */
</script>