<template>
  <!-- busqueda del levantamiento es por cliente, tipo de unidad, unidad -->
  <!-- solo se veran los estatus pendientes ??  -->
</template>
  
<script>

  // var moment = require('moment'); 
  // moment.locale('es')

  import moment from 'moment';

  // import Modal from './NuevoLevantamientoModal.vue';
  // import axios from 'axios'

  import { mapState, mapMutations } from "vuex";


  export default {

    computed:{
      ...mapState("Session", ['session_key', 'user','permisos', 'loggedin']),
      ...mapState(['router']),
    },

    props: {
      showAlert: Function,
      hasPermission: Function
    },

    components: {
      // Modal
    },
    data () {
        return {
          

        }
      },
      
      created(){
        
      },

      watch: {
        
      },

      methods:{
        ...mapMutations(['RouterParams']),
        
      }
  }
</script>
