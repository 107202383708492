<template>
  <v-main class="pa-0 ma-3">
  	<v-row class="justify-center" no-gutters>
  		<v-col cols="12" >
				

					<v-row class="pa-1">
						<v-col cols="12" sm="2" lg="" class="py-0 my-0">
							<v-text-field
								v-model="search"
								append-icon="search"
								label="Buscar visita"
								single-line
								hide-details
								filled dense
							></v-text-field>
						</v-col>

            <v-col cols="12" sm="2" lg="" class="py-0 my-0">
              <v-select 
                  v-model="estatus" :items="Estatus" item-title="nombre" item-value="id"  dense
                    hide-details  placeholder="Estatus " return-object outlined append-icon="mdi-circle-slice-5"
                ></v-select>
            </v-col>
            <v-col cols="12" sm="2" lg="" class="py-0 my-0">
              <DetePikerInput @ObtenerFechaDP="fecha1_change" :label_dp="'Fecha desde'" :fecha_default="fecha1"/>
            </v-col>
            <v-col cols="12" sm="2" lg="" class="py-0 my-0">
              <DetePikerInput @ObtenerFechaDP="fecha2_change" :label_dp="'Fecha hasta'" :fecha_default="fecha2"/>
            </v-col>

            <!-- FALTA AGREGAR EL RANGO DE FECHAS. Y EXPORTACIONES ?  -->

						<v-col cols="12" sm="4" lg="" class="py-0 my-0  text-right">

							<!-- <v-btn  class="ma-1" dark color="green" @click="ImprimirExcel()"> <v-icon >mdi-microsoft-excel </v-icon> </v-btn> -->
							<!-- <v-btn  dark color="green" @click="generarExcel('solicitudes')"> <v-icon >mdi-microsoft-excel </v-icon> </v-btn> -->
 			      	<v-btn  class=" ma-1" color="success" dark   v-if="hasPermission('agregar_visita')"   @click="modal = true">Agendar visita </v-btn>
			      	<v-btn  class=" ma-1" color="gray" text dark    @click="consulta()" ><v-icon>mdi-refresh</v-icon> </v-btn>
						</v-col>
					</v-row>
					
			    <v-data-table v-model:items-per-page="itemsPerPage"
			      :headers="headersData()"
			      :items="items"
			      :search="search"
			      fixed-header
						hide-default-footer
						:loading ="Loading"
      			loading-text="Cargando... Por favor espere."
            no-data-text="Aún no hay citas registradas"
						dense
			    >

            <template v-slot:item.fecha_visita_programada="{ item }" > 
              {{  moment_format(item.selectable.fecha_visita_programada, 'LL') }}
              
            </template>
            <template v-slot:item.estatus="{ item }" > 
              
            </template>

            <template v-slot:item.action="{ item }" > 
              <v-btn  color="blue" class="ma-1"  small dark  @click="infoVisita(item.selectable)">
                <v-icon> mdi-eye </v-icon>
              </v-btn> 
            </template>


						<!-- <template v-slot:item.fecha_visita_programada="{ item }"> -->
              <!-- {{  moment(item.fecha_visita_programada, "YYYY-MM-DD HH:mm:ss").format('LL') }} -->
							<!-- <span v-if="item.fecha_visita_programada < fecha_actual"   class="red--text">    {{  moment(item.fecha_visita_programada).format('LL') }} </span>
							<span v-if="item.fecha_visita_programada === fecha_actual" class="orange--text"> {{  moment(item.fecha_visita_programada).format('LL') }} </span>
							<span v-if="item.fecha_visita_programada > fecha_actual"   class="green--text">  {{  moment(item.fecha_visita_programada).format('LL') }} </span> -->
						<!-- </template> -->

						<!-- <template v-slot:item.nota="{ item }" >
							<span class="font-weight-bold caption blue--text text-justify" v-if="item.nota"> {{ item.nota }} </span> 
							<span class="font-weight-bold caption blue--text text-justify" v-else> {{ item.nota }} </span> 
						</template> -->
			    </v-data-table>

        <v-dialog persistent v-model="modal" width="700px" >	
          <v-card class="pa-5">
            <Modal :showAlert="showAlert" :hasPermission="hasPermission" :consulta="consulta" @modal="modal = $event"/>
          </v-card>
        </v-dialog>
		
  		</v-col>
      
  	</v-row>
  </v-main>

  </template>
  
  <script>
    import DetePikerInput from "@/components/DatePikerInput.vue";
    import moment from 'moment';
    moment.locale('es-ES');  

    import Modal from './VisitasModal.vue';
    import axios from 'axios'

    import { mapState, mapMutations } from "vuex";

  
    export default {

      computed:{
        ...mapState("Session", ['session_key', 'user','permisos', 'loggedin']),
        ...mapState(['router']),
      },

      props: {
        showAlert: Function,
        hasPermission: Function
      },

      components: {
        Modal,
        DetePikerInput
      },

      data () {
          return {
           
            search: '',
            Loading:false,
            items:[],
            itemsPerPage: 100,
            
            data:{},
            
            modal: false,

            headers:null,
            
            estatus: {  id: null, nombre:'Todos'},
            Estatus:[ 
                      { id: null, nombre:'Todos'},
                      { id: 1, nombre:'Pendiente'},
                      { id: 2, nombre:'Asignado' },
                      { id: 3, nombre:'Finalizado'},
                      { id: 4, nombre:'Cancelado'}
                    ],

            fecha_actual:moment().format("YYYY-MM-DD"),

            fecha1: moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD"),
           
            fecha2: moment().subtract('months').endOf('months').format("YYYY-MM-DD")
            

          }
        },
        
        async created(){
          this.consulta();
        },
  
        

        watch: {
          estatus(){
            this.consulta();
          },
          fecha1(){
            this.consulta();
          },
          fecha2(){
            this.consulta();
          }
        },
  
        methods:{
          ...mapMutations(['RouterParams']),
          moment_format(date, format){
            return moment(date).format(format)
          },
          fecha1_change(fecha) {
            this.fecha1 = moment(fecha).format('YYYY-MM-DD');
          },

          fecha2_change(fecha) {
            this.fecha2 = moment(fecha).format('YYYY-MM-DD');
          },
    
          headersData(){
            let headers = [
              { title: '#'  					 , align: 'left'  , key: 'id_visita' },
              { title: 'Fecha'	 , align: 'left'  , key: 'fecha_visita_programada' },
              { title: 'Cliente'	 , align: 'left'  , key: 'nombre_cliente' }
            ];

            // if(this.hasPermission('agenda_filter_admin')){
              headers.push({ title: 'Responsable'	 , align: 'left'  , key: 'nombre_responsable' });
            // }

            headers.push(
              { title: 'Auxiliares'	 , align: 'left'  , key: 'auxs' },
              { title: 'Motivo'	 , align: 'left'  , key: 'tipo_visita' },
              { title: 'Nota'	 , align: 'left'  , key: 'comentarios' }
            );

            if(this.estatus.id == null){
              headers.push({ title: 'Estatus'	 , align: 'left'  , key: 'estatus' });
            }

            headers.push({ title: ''  			 , align: 'right' , key: 'action', sortable: false });

            return headers;
          },

          infoVisita(item){

            this.$router.push({name:'DetVisitaComponent', params:{id_visita:item.id_visita}});

            // console.log(JSON.parse(JSON.stringify(item)))

            // if(this.session_data.key_role !== 'tecnico'){
            //   this.data = item;
            //   this.modal = true;
            // } else {
            //   this.$router.push({name:'DetVisitaComponent', params:{id_compromiso:item.id_compromiso}});
            // }  
          },

          
          async consulta(){

            this.Loading=true;
            
            //un cliente no tiene acceso a esta pantalla.....

            let payload = {
              fecha_inicio:this.fecha1,
              fecha_fin:this.fecha2
            };

            if(this.estatus.id != null){
              payload = {...payload, estatus:this.estatus.id}
            }
            
            //si no tengo el permiso de ver las visitas de todos los responsables, consultar solo las que yo soy responsable y las que me han puesto como auxiliar.
            if(!this.hasPermission('agenda_filter_admin')){
              payload = {...payload, id_usuario_responsable: this.user.id_usr}
            }

            axios.post('visitas.consulta', payload).then((res)=>{
              this.items = res.data;
            }).catch((err)=>{
              console.log(err);
            }).finally( ()=> {
              this.Loading=false;
            });
          },
        }
    }
  </script>
  