<template>
    <div>
        <v-row no-gutters style="border-radius: 15px; border: solid 8px #000000; ">
            <v-col v-html="appconf.ot_iso" cols="12" md="6" style="background-color:#ffffff; color: #000000;">
                
            </v-col>
            <v-col cols="12" md="6" style="background-color:#c00000; color: #ffffff;">
                <center>
                    <img :src="appconf.logo" :width="appconf.logo_width" :height="appconf.logo_height" />
                </center>
            </v-col>
        </v-row>
        <br />
        <v-row no-gutters style="border-radius: 15px; border: solid 8px #000000; ">
            <v-col cols="12" md="6" style="background-color:#002060; color: #FFFFFF;  padding:10px;">
                <center>
                    ADVERTENCIAS IMPORTANTES
                    <v-radio-group>
                        <v-radio label="No se puede operar" value="1" density="compact"></v-radio>
                        <v-radio label="Operar sólo lo indispensable" value="2" density="compact"></v-radio>
                        <v-radio label="Operar bajo advertencia" value="3" density="compact"></v-radio>
                        <v-radio label="Otros..." value="4" density="compact"></v-radio>
                    </v-radio-group>
                </center>
            </v-col>
            <v-col cols="12" md="6" style="background-color:#ffc000; color: #ffffff; padding:10px;">
                <center>
                    PROBLEMAS CRÍTICOS
                    <v-radio-group>

                        <v-radio label="Estado de cable/cadena crítico" value="1" density="compact"></v-radio>
                        <v-radio label="Puntos de apoyo criticos" value="2" density="compact"></v-radio>
                        <v-radio label="Cargas axiales / No propias a la operacion" value="3" density="compact"></v-radio>
                        <v-radio label="Sobrecalentamiento de motor" value="4" density="compact"></v-radio>
                        <v-radio label="Contactores flameados" value="5" density="compact"></v-radio>
                    </v-radio-group>
                </center>
            </v-col>
        </v-row>
        <br />
        <v-row no-gutters style="border-radius: 15px; border: solid 8px #000000; ">
            <v-col cols="12" md="12" style="background-color:#002060; color: #000000;  padding:10px;">
                <v-textarea v-model="descrip_fallas" variant="solo" label="DESCRIPCIÓN DE FALLAS / ALCANCE:"></v-textarea>
            </v-col>
        </v-row>
        <br />
        <v-row no-gutters style="border-radius: 15px; border: solid 8px #000000; ">
            <v-col cols="12" md="12" style="background-color:#002060; color: #000000;  padding:10px;">
                <v-textarea v-model="descrip_diagnostico" variant="solo" label="DIAGNÓSTICO:"></v-textarea>
            </v-col>
        </v-row>
        <br />
        <v-row no-gutters style="border-radius: 15px; border: solid 8px #000000; ">
            <v-col cols="12" md="12" style="background-color:#c00000; color: #000000;  padding:10px;">
                <v-textarea v-model="descrip_observaciones" variant="solo" label="OBSERVACIONES"></v-textarea>
            </v-col>
        </v-row>
        <br />
        <v-row no-gutters style="border-radius: 15px; border: solid 8px #000000; ">
            <v-col cols="12" md="3" style="background-color:#ffc000; padding:10px;">
                <center>
                    <span style="color:#fff;">
                        Firma de autorización del cliente
                    </span><br/>
                    <vue-drawing-canvas :ref="id_canva" :canvasId="id_canva" :width="300"
                        :height="200"></vue-drawing-canvas>
<br/>
                        <v-btn @click="this.$refs.firma_cliente.reset();" icon color="blue">
                        <v-icon>
                            mdi-reload
                        </v-icon>
                    </v-btn>
                </center>
            </v-col>
            <v-col cols="12" md="3" style="background-color:#002060; color: #ffffff; padding:10px;">
                <v-radio-group>
                    <v-radio label="Cotizar" value="1" density="compact"></v-radio>
                    <v-radio label="Proceder" value="2" density="compact"></v-radio>
                    <v-radio label="Facturar" value="3" density="compact"></v-radio>
                </v-radio-group>
            </v-col>
            <v-col cols="12" md="3" style="background-color:#ffc000; color: #ffffff; padding:10px;">
                <v-text-field label="FECHA ENTREGA" variant="solo" density="compact"></v-text-field>
            </v-col>
            <v-col cols="12" md="3" style="background-color:#002060; color: #ffffff; padding:10px;">
             
              
                    <center>
                        <span style="color:#fff;">
                    RECIBIDO/VALIDACIÓN
                    </span><br/>
                    <vue-drawing-canvas :ref="id_canva_2" :canvasId="id_canva_2" :width="300"
                        :height="200"></vue-drawing-canvas>
                        <br/>
                        <small>Firma del tecnico</small>
                        <br/>
                        <v-btn @click="this.$refs.firma_tecnico.reset();" icon color="blue" >
                        <v-icon>
                            mdi-reload
                        </v-icon>
                    </v-btn>
                </center>
                
            </v-col>
        </v-row>

        <br />


    </div>
</template>
<script>
import VueDrawingCanvas from 'vue-drawing-canvas';
export default {
    components: {
        VueDrawingCanvas
    },
    props: {
        appconf:Object
    },
    data() {
        return {
            id_canva: "firma_cliente",
            id_canva_2:"firma_tecnico"
        }
    }
}
</script>