<template>
  <div v-if="!Loading">

    <v-col cols="12" align="right">

      <!-- <v-alert border="bottom" colored-border color="white"> -->
         <h1 style="float: left;">
          <v-btn icon color="warning" to="/cotizaciones">
            <v-icon> mdi-arrow-left </v-icon>
          </v-btn>

          Cotización 
          <span style="color: #1e88e5" @click="GenerarCot()"
            ># {{id_cotizacion}}</span
          >
        </h1>
        <v-btn :block="screenWidth <= 500 ? true : false" elevation="0"
          :variant="tap_btn == 'cotizacion' ? 'outlined' : 'text'" size="large" @click="tap_btn = 'cotizacion'">
          Cotización
        </v-btn>

        <v-btn :block="screenWidth <= 500 ? true : false" elevation="0" v-if="cotizacion.id_levantamiento !== null"
            :variant="tap_btn == 'levantamientos' ? 'outlined' : 'text'" size="large" @click="tap_btn = 'levantamientos'">
            Levantamiento
        </v-btn>

        <v-btn :block="screenWidth <= 500 ? true : false" elevation="0" v-if="cotizacion.id_ot !== null"
            :variant="tap_btn == 'servicios' ? 'outlined' : 'text'" size="large" @click="tap_btn = 'servicios'">
            ORDEN DE SERVICIO
        </v-btn>

        <!-- id_levantamiento -->
        
      <!-- </v-alert> -->
    </v-col>

    <div style="background-color:#F5F5F5; padding:3px; margin-left:15px;margin-right:15px">
    </div>

    <div style="padding: 20px; margin: 10px; border-radius: 10px;">
      
      <detalles v-if="tap_btn == 'cotizacion'" 
        :visita="visita"
        :cotizacion="cotizacion"
        :cotizacion_items="cotizacion_items"
        :tecnicos="tecnicos"
        :levantamiento="levantamiento"
        :showAlert="showAlert"
        :hasPermission="hasPermission"
      />
      
      <FormatoGlobal v-if="tap_btn == 'levantamientos'" 
        :is_new="false" 
        :restore_lev="levantamiento" 
        :visita="visita" 
        :id_tipo_unidad="levantamiento.id_tipo_unidad" 
        :component_name="levantamiento.component_name" 
        :data_unidad_nombre="levantamiento.tipo_unidad_nombre" 
        :cargarLevantamientos="cargarLevantamientos"
        :creator="false"
        :showAlert="showAlert"
        :hasPermission="hasPermission" />

        <!-- ordenes de servicio.... -->

      <!-- <levantamiento  v-if="tap_btn == 'levantamientos'" :visita="visita" :creator="creator" :showAlert="showAlert" :hasPermission="hasPermission"/> -->
      <!-- <CheckList  v-if="tap_btn == 'checklist'" :visita="visita"/> -->
      <!-- <DetallesUnidad  v-if="tap_btn == 'unidad'" :visita="visita"/> -->
    </div>
  </div>
  <!-- v-if="!Loading" -->
  <ScreenSplash v-else />
</template>
  
<script>

import CotizacionPDF from "@/mixins/CotizacionPDF.js";
import { mapState } from "vuex";
import ScreenSplash from '@/components/ScreenSplash.vue';

import FormatoGlobal from '@/views/Levantamientos/Formatos/FormatoGlobal.vue';
import detalles from "./Cotizacion/detalles.vue";

import { ref } from 'vue';

import moment from 'moment';
export default {
  mixins: [CotizacionPDF],
  components: {
    ScreenSplash,
    FormatoGlobal,
    detalles
    // LevantamientoPrincipal,
    // DetePikerInput
  },

  computed: {
    ...mapState("Session", ['session_key', 'user','permisos', 'loggedin']),
    ...mapState(['router']),
    screenWidth() {
      return window.innerWidth;
    }
  },
  props: {
    id_cotizacion: String,
    hasPermission: Function,
    showAlert:Function
  },
  data() {
    return {
        tap_btn:'cotizacion',

        Loading: false,
        cotizacion:null,
        cotizacion_items:[],
        visita:null,
        levantamiento: null,
        tecnicos:[]

    };
  },

  async created() {
    await this.cargarCotizacion();
  },

  methods: {


    async cargarCotizacion(){
        
      this.Loading=true;

      await this.$axios.post('consulta.detcotizacion', {id_cotizacion:this.id_cotizacion}).then(r=>{
        
        this.cotizacion = r.data.cotizacion;
        this.cotizacion_items = r.data.cotizacion_items;
        this.cotizacion.iva_porcentaje = this.cotizacion.iva_porcentaje == null ? 16 : this.cotizacion.iva_porcentaje;
        this.visita = r.data.visita;
        this.levantamiento = r.data.levantamiento;
        this.tecnicos = r.data.usuarios;

      }).catch(e=>{console.log(e.data)}).finally( () => {
        this.Loading = false;

        if(this.user.role_name == 'Cliente' && this.cotizacion.id_cliente != this.user.id_usr){
          this.$router.push({name:'CotizacionesComponent'});
          console.log('cliente cot:', this.cotizacion.id_cliente);
          console.log('user cliente:', this.user.id_usr);
          console.log('role name: ', this.user.role_name );
        }

      });
    },

    GenerarCot(){
      this.GenerarCotizacion(this.cotizacion, this.cotizacion_items);
    },

  }
}
</script>