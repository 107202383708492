<template>
  <div v-if="!Loading">

    <v-col cols="12" align="right">

      <!-- <v-alert border="bottom" colored-border color="white"> -->
         <h1 style="float: left;">
            <v-btn icon color="warning" to="/unidades">
              <v-icon> mdi-arrow-left </v-icon>
            </v-btn>

            Unidad 
            <span style="color: #1e88e5">
              {{ unidad.clave_unidad }}
            </span>
        </h1>
        <v-btn :block="screenWidth <= 500 ? true : false" elevation="0"
          :variant="tap_btn == 'detalles' ? 'outlined' : 'text'" size="large" @click="tap_btn = 'detalles'">
          Detalles unidad
        </v-btn>

        <v-btn :block="screenWidth <= 500 ? true : false" elevation="0" 
            :variant="tap_btn == 'levantamientos' ? 'outlined' : 'text'" size="large" @click="tap_btn = 'levantamientos'">
            Levantamiento
        </v-btn>

        <v-btn :block="screenWidth <= 500 ? true : false" elevation="0" 
            :variant="tap_btn == 'servicios' ? 'outlined' : 'text'" size="large" @click="tap_btn = 'servicios'">
            Servicios Realizados
        </v-btn>

        <!-- <v-btn :block="screenWidth <= 500 ? true : false" elevation="0" 
            :variant="tap_btn == 'levantamientos' ? 'outlined' : 'text'" size="large" @click="tap_btn = 'levantamientos'">
            Preventivos/Plan de manteimiento
        </v-btn> -->
        
      <!-- </v-alert> -->
    </v-col>

    <div style="background-color:#F5F5F5; padding:3px; margin-left:15px;margin-right:15px">
    </div>

    <div style="padding: 20px; margin: 10px; border-radius: 10px;">
      
      <!-- <detalles v-if="tap_btn == 'detalles'" 
        :showAlert="showAlert"
        :hasPermission="hasPermission"
      />
      
      -->
      <!-- props que es solo vista, editable? -->
      <FormatoGlobal v-if="tap_btn == 'levantamientos'" 
        :is_new="false" 
        :restore_lev="levantamiento" 
        :visita="visita" 
        :id_tipo_unidad="levantamiento.id_tipo_unidad" 
        :component_name="levantamiento.component_name" 
        :data_unidad_nombre="levantamiento.tipo_unidad_nombre" 
        
        :creator="false"
        :showAlert="showAlert"
        :hasPermission="hasPermission" /> 

      <!-- <levantamiento  v-if="tap_btn == 'levantamientos'" :visita="visita" :creator="creator" :showAlert="showAlert" :hasPermission="hasPermission"/> -->
      <!-- <CheckList  v-if="tap_btn == 'checklist'" :visita="visita"/> -->
      <!-- <DetallesUnidad  v-if="tap_btn == 'unidad'" :visita="visita"/> -->
    </div>
  </div>

  <ScreenSplash v-else />
</template>

<script>

import { mapState } from "vuex";
import ScreenSplash from '@/components/ScreenSplash.vue';
import FormatoGlobal from "@/views/Levantamientos/Formatos/FormatoGlobal.vue";

import moment from 'moment';
export default {

  components: {
    ScreenSplash,
    FormatoGlobal
  },

  computed: {
    ...mapState("Session", ['session_key', 'user','permisos', 'loggedin']),
    ...mapState(['router']),
    screenWidth() {
      return window.innerWidth;
    }
  },
  props: {
    id_unidad: String
  },
  data() {
    return {
        tap_btn:'detalles',
        unidad:{},
        levantamiento:{},
        Loading:false,

    };
  },

  async created() {
    this.Loading=true;
    this.$axios.post('consulta.detunidad', {id_unidad:this.id_unidad}).then((res)=>{
        this.unidad = res.data.unidad;
        this.levantamiento = res.data.levantamiento;
        
    }).catch((err)=>{
        console.log(err);
    }).finally( ()=> {
        this.Loading=false;
    });

  },
  mounted(){
    // this.Loading = false
  },
  methods: {


  }
}
</script>