import Vue from 'vue'
import Vuex from 'vuex'

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'
import numeroALetras from "numero-a-letras";
window.convertir=require('numero-a-letras');
import moment from 'moment';
moment.locale('es');

export default {
    data() {
        return {

        }
    },
    methods: {


        GenerarCotizacion(cotizacion, items_conceptps) {
            let fecha_actual = moment().format('MMMM DD YYYY') +" ";

            let columns = [
                { header: "Cant.", dataKey: "cantidad" },
                { header: "Unid.", dataKey: "unidad" },
                { header: "Atrib.", dataKey: "atrb" },
                { header: "Clave", dataKey: "clave" },
                { header: "Descripción", dataKey: "concepto" },
                { header: "P.Unitario", dataKey: "precio_unitario" },
                { header: "Importe", dataKey: "importe" },
                { header: "Mon", dataKey: "modena" }

            ];


            items_conceptps.forEach(e => {
                e.importe=e.cantidad * e.precio_unitario;
                e.modena=cotizacion.moneda;
                e.atrb="";
                e.cleve="";
            });
            let tdetalle = items_conceptps;


            var doc = new jsPDF();

            const headerX = 10;
            const headerY = 10;

            doc.addImage('https://s21sistem.com/assets/img/logo.png', headerX, headerY, 0, 30);


            doc.setFontSize(20);
            doc.text("Cotizacion", 160, 20);

            doc.setFontSize(12);
            doc.text("Folio:", 155, 30);
            doc.setTextColor(0, 163, 232);
            doc.text(String(cotizacion.id_cotizacion), 170, 30);
            doc.setTextColor(0, 0, 0);
            doc.setFontSize(10);
            doc.text("Emitido a", 140, 35);
            doc.setTextColor(0, 163, 232);
            doc.text(fecha_actual, 158, 35);
            doc.setTextColor(0, 0, 0);
            doc.setFontSize(10);
            doc.text("T.C. :", 140, 41);
            doc.text("20.0500", 160, 41);

            doc.setDrawColor(0);
            doc.setFillColor(237, 237, 239);

            doc.rect(10, 48, 20, 5, "F");
            doc.rect(10, 80, 190, 5, "F");

            doc.setFontSize(8);
            doc.text("Cotizado a:", 10, 52);
            doc.setTextColor(0, 163, 232);
            doc.text(cotizacion.cliente, 10, 58, { align: 'justify', lineHeightFactor: 1, maxWidth: 90 });
            doc.setTextColor(0, 0, 0);
            doc.text("Contacto: " + cotizacion.responsable, 10, 63, { align: 'justify', lineHeightFactor: 1, maxWidth: 90 });
            doc.text("ATN: "+ cotizacion.responsable, 10, 68, { align: 'justify', lineHeightFactor: 1, maxWidth: 90 });
            doc.text("En atención a su amable solicitud, a continuación, me permito enviar a Ud. la siguiente cotización:", 10, 73, { align: 'justify', lineHeightFactor: 1, maxWidth: 90 });


            doc.text("Sucursal:", 135, 52);
            doc.text("QRO", 148, 52);
            doc.text("Alamcén:", 135, 55);
            doc.text("General", 148, 55);
            doc.text("CÓDIGO:", 135, 59);
            doc.setTextColor(255, 0, 0);
            doc.text("F-VT-03", 148, 59);
            doc.setTextColor(0, 0, 0);
            doc.text("Otros datos", 10, 84);

            doc.text("ATENCIÓN A:", 12, 90);
            doc.text("CEL VENDEDOR:", 12, 95);
            doc.text("MONEDA COTIZADA:", 12, 100);
            doc.text("FORMA DE PAGO:", 12, 105);

            doc.text(cotizacion.responsable, 50, 90);
            doc.text("4424790788", 50, 95);
            doc.text(cotizacion.moneda?cotizacion.moneda: "", 50, 100);
            doc.text(cotizacion.forma_de_pago?cotizacion.forma_de_pago:"", 50, 105);


            doc.text("VIGENCIA COTIZACIÓN:", 100, 90);
            doc.text("LUGAR DE ENTREGA: PANTA LEON", 100, 95);


            doc.autoTable({
                showHead: "firstPage",
                startY: 110,
                columns:columns,
                body: tdetalle,
                margin: { left: 10, right: 10 },
                theme: 'striped',
                columnStyles: {
                    4: { cellWidth: 50 },
                    7: { cellWidth: 30 },
                },
                styles: { fontSize: 7 }, headStyles: { fillColor: [237, 237, 239], textColor: [0, 0, 0], valign: 'middle' },
            });


            let tb_det = [
                { title: "SUBTOTAL   : ", value: "$ "+cotizacion.subtotal },
                { title: "IVA ("+cotizacion.iva_porcentaje+ "%)      : ",  value:"$ "+cotizacion.iva_monto },
                { title: "TOTAL          : ", value: "$ "+cotizacion.total }
            ];

            let title_cost = 140
            let tabla_ant = doc.lastAutoTable.finalY;
            let tabla_dos = tabla_ant + 10;

            doc.setDrawColor(0);
            doc.setFillColor(237, 237, 239);
            doc.rect(title_cost, tabla_ant + 6, 60, 5, "F");
            doc.rect(title_cost, tabla_ant + 16, 60, 5, "F");

            doc.setFontSize(9);
        
            doc.text(tb_det[0].title + tb_det[0].value, title_cost, tabla_dos , { align: 'justify', lineHeightFactor: 1, maxWidth: 90 });
            doc.text(tb_det[1].title + tb_det[1].value, title_cost, tabla_dos +5, { align: 'justify', lineHeightFactor: 1, maxWidth: 90 });
            doc.text(tb_det[2].title + tb_det[2].value, title_cost, tabla_dos + 10, { align: 'justify', lineHeightFactor: 1, maxWidth: 90 });

            doc.text(convertir.NumerosALetras(cotizacion.total), 200, tabla_dos + 25, { align: 'right', lineHeightFactor: 1, maxWidth: 90 });

            doc.setDrawColor(0);
            doc.setFillColor(237, 237, 239);
            doc.rect(10, tabla_dos + 35, 20, 5, "F");
            doc.text("Peso total", 11, tabla_dos + 39, { align: 'justify', lineHeightFactor: 1, maxWidth: 90 });
            doc.setFontSize(10);
            doc.text(cotizacion.comentarios_cotizador?cotizacion.comentarios_cotizador:"", 11, tabla_dos + 44, { align: 'justify', lineHeightFactor: 1, maxWidth: 150 });


            function addFooter() {
                const totalPages = doc.internal.getNumberOfPages();
                for (let i = 1; i <= totalPages; i++) {
                  doc.setPage(i);
                  doc.setFontSize(6);
                  doc.line(10,285, 200, 285);
                  doc.text('S21 SISTEM SA DE CV RFC:XXXXXX', 10, 288); 
                  doc.text('Monterrey, Nuevo León, México CP:64500', 10, 291); 
                  doc.text('Lada xx Tel(s) xxxxxxx, --jaleman@s21sistem.com, www.s21sistem.com', 10, 294); 
                  doc.text('No. página / Página totales', 150, 290);
                  doc.text('PAGE' + i + ' / ' + totalPages, 150, 294);
                }
              }

              addFooter();

            window.open(doc.output('bloburl'));


            var file = doc.output('blob');
            var fd = new FormData();
            fd.append('mypdf', file);
            fd.append('id_cotizacion', cotizacion.id_cotizacion);
            fd.append('data_cotizacion', JSON.stringify(cotizacion));

           



            this.$axios.post("enviar.pdf.cotizacion", fd)
            .then((res) => {
             console.log(res.body)
            })
            .catch((error) => {
            console.log(error)
            });

        },


    }
}


