<template>
    <v-content class="pa-0">
      <v-form validate-on="submit lazy" @submit.prevent="submit">
          <v-row justify="center" no-gutters>
              <v-col cols="12">
  
                  <v-card-actions class="pa-0" >
                      <h3> <strong> {{ nuevoRegistro ? 'NUEVA ORDEN DE SERVICIO':'ACTUALIZAR ORDEN DE SERVICIO' }}  </strong></h3> 
                      <v-spacer></v-spacer>
                      <v-btn color="error" fab small @click="$emit('modal',false)" ><v-icon>mdi-close</v-icon></v-btn>
                  </v-card-actions>
  
                  <v-row class="mt-3">
  
                      <v-col cols="12" >
                        <v-select :rules="rules" v-model="item.id_cliente" :items="catalogos.clientes" item-title="nombre" item-value="id_usr"
                        :loading="Loading" loading-text="Cargando clientes" :disabled="Loading" label="Cliente" ></v-select>
                      </v-col>

                      <v-col cols="12" >
                        <v-select :rules="rules" v-model="item.id_servicio" :items="catalogos.servicios" item-title="nombre_servicio" item-value="id_servicio"
                        :loading="Loading" loading-text="Cargando clientes" :disabled="Loading" label="Servicio"></v-select>
                      </v-col>

                      <v-col cols="12" >
                        <v-select  :rules="rules" v-model="item.id_tipo_unidad" :items="catalogos.tipos_unidades" item-title="tipo_unidad_nombre" item-value="id_tipo_unidad"
                        :loading="Loading" loading-text="Cargando clientes" :disabled="Loading" label="Tipo Unidad" ></v-select>
                      </v-col>

                      <v-col cols="12" >
                        <v-select :rules="rules" v-model="item.id_unidad" :items="unidades_filtradas" item-title="clave_unidad" item-value="id_unidad"
                        :loading="Loading" loading-text="Cargando clientes" :disabled="Loading" label="Unidad" ></v-select>
                      </v-col>

                     


                      <v-col cols="12" >
                        <v-select :rules="rules" v-model="item.id_tecnicos_asignados" :items="catalogos.tecnicos" item-title="nombre" item-value="id_usr"
                        :loading="Loading" loading-text="Cargando clientes" :disabled="Loading" label="Tecnicos asignados" multiple ></v-select>
                      </v-col>


                      <v-col cols="12" >
                          <v-textarea v-model="item.comentarios"
                          style="margin-top:15px" label="Observaciones" density="compact" variant="outlined"></v-textarea>
                        </v-col>

  
                     
                  </v-row>
  
                  <v-card-actions class="pa-0 mt-3">
                      <v-spacer></v-spacer>
                       <v-btn  
                           :disabled="saving" 
                           persistent 
                           :loading="saving"
                           dark center 
                           class="white--text" 
                           color="blue" 
                           type="submit">
               {{  nuevoRegistro ? 'GUARDAR' : 'GUARDAR CAMBIOS' }}
            </v-btn> 
                  </v-card-actions>
  
              </v-col>
          </v-row>
        </v-form>
      </v-content >
  </template>
  
  <script>
  export default {
    
 
  
    props: {
      item: Object,
      showAlert: Function,
      consulta: Function,
      catalogos: Array,
      es_nuevo:Boolean,
      session_data:Object
    },
  
    data() {
      return {
        nuevoRegistro: this.es_nuevo,
        saving:false,
        rules: [
                (value) => {
                    if (value) return true;

                    return "Este campo es obligatorio";
                },
            ],
      };
    },

  
    created() {
      if (this.nuevoRegistro==false) {
          this.item.id_tecnicos_asignados = !this.item.id_tecnicos_asignados || this.item.id_tecnicos_asignados == null || this.item.id_tecnicos_asignados.length==0 ? [] : this.item.id_tecnicos_asignados.split(';').map(numero => parseInt(numero));
      } else {
          this.item.id_tecnicos_asignados = []
      }
      },

    computed:{

        unidades_filtradas(){
            let temp= this.item.id_unidad;
            this.item.id_unidad=null;
            let find =[];
            if(this.item.id_cliente&&this.item.id_cliente!=''&&this.item.id_tipo_unidad&&this.item.id_tipo_unidad!=''){
                find = this.catalogos.unidades.filter(fn=>fn.id_cliente==this.item.id_cliente && fn.id_tipo_unidad == this.item.id_tipo_unidad);
              if(temp!=null){
                  let result = find.filter(fn=>fn.id_unidad==temp);
                  result.length>0?this.item.id_unidad = temp:this.item.id_unidad=null;
                }
           
            }
            return find;
        }

        
    },
  
   
    methods: {


      async submit(event) {
            const results = await event;
            if (results.valid == true) {
                this.updcreated();
            }else{
                window.showAlert({
                        activo: true,
                        texto: "Falta llenar algun campo",
                        color: "red accent-4",
                        vertical: true,
                    });
            }
        },

      async updcreated() {
        if(this.nuevoRegistro==true){
          this.item.id_usr_creador=this.session_data.id_usr;
        }

        var me = this;
        this.saving = true;
        let payload = {
          table:'ot',
          foreignkey:'id_ot',
          protectedkeys:[
            'id_ot',
            'nombre_cliente',
            'nombre_servicio',
            'nombre_tipo_unidad',
            'nombre_unidad',
            'nombre_solicitante',
            'fecha_creacion'
          ],
          data: this.item
        }
        await this.$axios
          .post("ordenes.servicio.updcreated", payload)
          .then((r) => {
           
  
            this.showAlert({
              activo: true, 
              texto: this.nuevoRegistro ? "Servicio agregado con éxito!" : "Datos actualizados con éxito!", 
              color: "success", 
              vertical: true
            });
  
            
              this.consulta();
          
  
            me.$emit('modal',false);
  
          })
          .catch((e) => {
            console.log(e);
            this.showAlert({
              activo: true, 
              texto:"Lo sentimos! Algo salio mal...", 
              color: "error", 
              vertical: true
            });
          }).finally( () => {
            this.saving = false;
            
          } );
      },
    },
  };
  </script>