import { createRouter, createWebHashHistory } from 'vue-router';

import HomeComponent from "@/views/HomeComponent.vue";

import ClientesComponent from "@/views/Clientes/ClientesComponent.vue";

import LevantamientosComponent from "@/views/Levantamientos/LevantamientosComponent.vue";

import VisitasComponent from "@/views/Visitas/VisitasComponent.vue";
import DetVisitaComponent from "@/views/Visitas/DetVisitaComponent.vue";

import CotizacionesComponent from "@/views/Cotizaciones/CotizacionesComponent.vue";
import DetCotizacionComponent from "@/views/Cotizaciones/DetCotizacionComponent.vue";


import OrdenesDeServicio from "@/views/OrdenesServicio/OrdenesComponent.vue";
import VistaOrdenSelected from "@/views/OrdenesServicio/VistaOrdenSelected.vue";



import UnidadesComponent from "@/views/Unidades/UnidadesComponent.vue";
import DetUnidadComponent from "@/views/Unidades/DetUnidadComponent.vue";

// import ServiciosComponent from "@/views/Catalogos/ServiciosComponent.vue";

// import TipoUnidadesComponent from "@/views/Catalogos/TipoUnidadesComponent.vue";

// import UsuariosComponent from "@/views/Admin/UsuariosComponent.vue";
// import RolesComponent from "@/views/Admin/RolesComponent.vue";

// import VisitasComponent from "@/views/Agenda/VisitasComponent.vue";
// import DetVisitaComponent from "@/views/Agenda/DetVisitaComponent.vue";



const routes = [
  { path: '/', name: 'HomeComponent', component: HomeComponent, meta:{permission:null},  props: true },
  { path: '/clientes', name: 'ClientesComponent', meta:{permission:'ver_clientes'}, component: ClientesComponent,   props: true },
  { path: '/visitas', name: 'VisitasComponent',meta:{permission:'ver_agenda'}, component: VisitasComponent,   props: true },
  { path: '/visitas/:id_visita', name: 'DetVisitaComponent',meta:{permission:'ver_visita'}, component: DetVisitaComponent,   props: true },
  { path: '/levantamientos', name: 'LevantamientosComponent',meta:{permission:'ver_lenvatamientos'}, component: LevantamientosComponent,   props: true },
  { path: '/cotizaciones', name: 'CotizacionesComponent',meta:{permission:'ver_cotizaciones'}, component: CotizacionesComponent,   props: true },
  { path: '/cotizaciones/:id_cotizacion', name: 'DetCotizacionComponent',meta:{permission:'ver_detcot'}, component: DetCotizacionComponent,   props: true },
  { path: '/ordenes-servicio', name: 'OrdenesDeServicio',meta:{permission:'ver_ordenes'}, component: OrdenesDeServicio,   props: true },
  { path: '/ordenes-servicio/:id_ot', name: 'VistaOrdenSelected',meta:{permission:'ver_orden'}, component: VistaOrdenSelected,   props: true },
  { path: '/unidades', name: 'UnidadesComponent',meta:{permission:'ver_unidades'}, component: UnidadesComponent,   props: true },
  { path: '/unidades/:id_unidad', name: 'DetUnidadComponent',meta:{permission:'ver_unidad'}, component: DetUnidadComponent,   props: true },

  // { path: '/catalogo/servicios', name: 'ServiciosComponent',meta:{permission:null}, component: ServiciosComponent,   props: true },
  // { path: '/catalogo/tipounidades', name: 'TipoUnidadesComponent',meta:{permission:null}, component: TipoUnidadesComponent,   props: true },
  // { path: '/catalogo/unidades', name: 'UnidadesComponent',meta:{permission:null}, component: UnidadesComponent,   props: true },
  // { path: '/admin/roles', name: 'RolesComponent',meta:{permission:null},component: RolesComponent,   props: true },
  // { path: '/admin/usuarios', name: 'UsuariosComponent',meta:{permission:null}, component: UsuariosComponent,   props: true },
  // { path: '/agenda/citas/:id_compromiso', name: 'DetVisitaComponent',meta:{permission:null}, component: DetVisitaComponent,   props: true },
  
  

];

// import VistaHome from "@/views/Principal/MenuPrincipal.vue";
// import ScanerCamara from "@/views/Principal/ScanerCamara.vue";
// import UserSetting from "@/views/Principal/UserSetting.vue";
// import ReciboMaterial from "@/views/Principal/ReciboMaterial.vue";

// const routes = [
//   { path: '/', name: 'VistaHome', component: VistaHome,   props: true },
//   { path: '/scaner-camara', name: 'ScanerCamara', component: ScanerCamara,   props: true },
//   { path: '/recibo', name: 'ReciboMaterial', component: ReciboMaterial, props: true },
//   { path: '/user-setting', name: 'UserSetting', component: UserSetting, props: true }
// ];

//history: createWebHistory(process.env.BASE_URL)
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});


// router.beforeEach((to, from, next) => {
//   // Aquí debes implementar la lógica para verificar los permisos del usuario.
//   // Puedes acceder a la información del usuario actual desde tu estado de Vuex o donde lo tengas almacenado.
//   console.log(to);

//   if(to.meta.permission && to.meta.permission != null && ){
//     // to.meta.permission
//   }
//   // const usuarioTienePermiso = true; // Reemplaza esto con tu lógica real de verificación de permisos.

//   // if (!usuarioTienePermiso) {
//   //   // Si el usuario no tiene permiso, redirige a Home
//   //   next({ name: 'HomeComponent' });
//   // } else {
//   //   // Si el usuario tiene permiso, permite la navegación
//   //   next();
//   // }
// });

export default router;