import { createStore } from 'vuex';
import Session from '@/modules/Session'

const store = createStore({
  state: {
    router: {
      params:null,
    }
  },
  mutations: {
    
    RouterParams(state, newParams){
      state.params = newParams;
    }
  },
  getters: {
    getParams: state=>state.params
  },
  actions: {
    //.. son las que pasan por metodos??
  }
  ,
  modules: {
    Session
  }
});

export default store;