<template>

  <div class="card_contenedor">
    <div class="center_div">
        <span class="title_card_2">DIMENSIONES BALANCIN</span>
    </div>
    <br />

    <div class="center_element">
        <v-img :src="require('@/assets/ImgFormatos/formato_2_maq_1.png')" width="600px" height="400px" />
    </div>
    <v-sheet class="pa-5">
        <v-row no-gutters>
            <v-col cols="12" md="6">
                <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                    label="Ancho abertura del enganche superior"
                    v-model="item_lev.dim_balancin_ancho_aber_eng_sup"></v-text-field>
                <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                    label="Altura abertura del enganche superior"
                    v-model="item_lev.dim_balancin_altura_aber_eng_sup"></v-text-field>
                <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                    label="Altura del enganche superior"
                    v-model="item_lev.dim_balancin_altura_eng_sup"></v-text-field>
                <v-text-field  :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                    label="Altura patin" v-model="item_lev.dim_balancin_altura_patin"></v-text-field>

            </v-col>
            <v-col cols="12" md="6">
                <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                    label="Extención maximo" class="ml-3" v-model="item_lev.dim_balancin_ext_max"></v-text-field>
                <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                    label="Espesor del enganche superior" class="ml-3"
                    v-model="item_lev.dim_balancin_espesor_eng_sup"></v-text-field>
                <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                    label="Cap. Max carga señalada" class="ml-3"
                    v-model="item_lev.dim_balancin_cap_max_carg_senaletica"></v-text-field>
                <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                    label="Cap. Max carga de área de trabajo" class="ml-3"
                    v-model="item_lev.dim_balancin_cap_max_carg_de_area_trabajo"></v-text-field>
            </v-col>
        </v-row>
    </v-sheet>
  </div>
  <div class="card_contenedor">
    <div class="center_div">
        <span class="title_card_2">DIMENSIONES CARGA</span>
    </div>
    <br />

    <v-sheet class="pa-5">
      <v-row no-gutters>
          <v-col cols="12" md="4">
              <div class="center_element">
                  <v-img :src="require('@/assets/ImgFormatos/formato_2_maq_2.png')" width="200px"
                      height="300px" />
              </div>

          </v-col>
          <v-col cols="12" md="8">
              <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                  label="Altura total de la pieza" class="ml-3"
                  v-model="item_lev.dim_carg_altura_total_pieza"></v-text-field>
              <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                  label="Ancho de pieza" class="ml-3" v-model="item_lev.dim_carg_ancho_pieza"></v-text-field>
              <v-text-field  :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                  label="Profundidad de pieza" class="ml-3"
                  v-model="item_lev.dim_carg_profund_pieza"></v-text-field>
              <v-text-field :rules="rules" :readonly="item_lev.estatus_cot != null" density="compact" variant="outlined"
                  label="Cap. Max carga señalada" class="ml-3"
                  v-model="item_lev.dim_carg_cap_max_carg_senalierica"></v-text-field>

          </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("Session", ['session_key', 'session_data', 'loggedin']),
    },
    props: {
        is_new: Boolean,
        restore_lev: Object,
        visita: Object,
        id_tipo_unidad: String,
        cargarLevantamientos: Function,
        data_unidad_nombre: Object,
    },
    data() {
        return {
            item_lev: {},
            selectedImages: [],
            selectedImages2: [],
            img_views: [],
            img_views2:[],
            selectedFiles: [],
            selectedFiles2: [],
            rules: [
                (value) => {
                    if (value) return true;

                    return "Este campo es obligatorio";
                },
            ],
            imagenUrl: null,
            data_unidad_nombre:this.is_new==true?this.data_unidad_nombre:this.restore_lev.tipo_unidad_nombre,
            img_borrar:[],
        }
    },
    watch: {
        
        data_unidad_nombre(){
            this.data_unidad_nombre=this.is_new==true?this.data_unidad_nombre:this.restore_lev.tipo_unidad_nombre
        }

    },
    created() {

        if (!this.is_new) {
            this.item_lev = this.restore_lev;
            this.item_lev.tipo_polipastos = this.restore_lev.tipo_polipastos == null ? [] : (this.restore_lev.tipo_polipastos.includes(';') ? this.restore_lev.tipo_polipastos.split(";") : this.restore_lev.tipo_polipastos);

        } else {
            this.item_lev.tipo_polipastos = []
        }

    },



    methods: {

    }
}
</script>

<style scoped>
.card_contenedor {

    margin-top: 20px;
    border: solid 2px #000;
    width: 100%;
    border-radius: 5px;
}

.title_card {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 5px;
    padding-top: 5px;
    letter-spacing: 2px;
    background-color: #FFD600;
}


.center_div {

    width: 100%;
    top: -25px;
    display: flex;
    justify-content: center;
}

.center_element {
    display: flex;
    justify-content: center
}


.color_letter {
    color: #000000;
    padding: 4px;
    background-color: #FFD600;
}

.color_spacer {
    color: #000000;
    padding: 4px;

}

.space_container {
    margin-top: -20px;
}

.mg_tn {
    margin-top: -30px;
}

.nota_contenedor {
    padding: 10px;
    border: solid 2px #000000;
    font-size: 18px;
    color: #5F5F5F;
    font-weight: bold;
}

.title_card_2 {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 5px;
    padding-top: 5px;
    letter-spacing: 2px;
    background-color: #0D3961;
    color: #fff
}

.imagen-opaca {
  opacity: 0.2; 
}
</style>