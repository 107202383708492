<template>
    <v-main class="pa-0 ma-3">
        <v-row class="justify-center" no-gutters>
            <v-col cols="12" >
                  <v-card class="mt-3 pa-3" outlined  >
                      <v-row class="pa-1 py-0">
                          <v-col cols="12" sm="8" md="6">
                              <v-card-actions class="font-weight-black headline  py-0 mt-1 " > LISTA DE COTIZACIONES </v-card-actions>
                          </v-col>
  
                            <v-col cols="12" sm="4" md="2" align="right">
                                <v-select
                                    v-model="estatus" :items="Estatus" item-title="nombre" item-value="id"  dense
                                    hide-details  placeholder="Estatus " return-object outlined append-icon="mdi-circle-slice-5"
                                ></v-select> 
                            </v-col>

                            <!-- agregar filtro de fechas...?... cliente,? -->
                      
                      </v-row>
  
                      <v-row class="pa-1">
                          <v-col cols="12" sm="6" class="py-0 my-0">
                              <v-text-field
                                  v-model="search"
                                  append-icon="search"
                                  label="Buscar cotización"
                                  single-line
                                  hide-details
                                  filled dense
                              ></v-text-field>
                          </v-col>
  
                          <v-col cols="12" sm="6" class="py-0 my-0  text-right">
                              <!-- <v-btn  class="ma-1" dark color="green" @click="ImprimirExcel()"> <v-icon >mdi-microsoft-excel </v-icon> </v-btn> -->
                              <!-- <v-btn  dark color="green" @click="generarExcel('solicitudes')"> <v-icon >mdi-microsoft-excel </v-icon> </v-btn> -->
                         <!-- <v-btn v-if="session_data.key_role !== 'cliente'"  class=" ma-1" color="success" dark      @click="data = {}; modal = true">Agendar visita </v-btn> -->
                        <v-btn  class=" ma-1" color="gray" text dark    @click="consulta()" ><v-icon>mdi-refresh</v-icon> </v-btn>
                          </v-col>
                      </v-row>
                      
                  <v-data-table v-model:items-per-page="itemsPerPage"
                    :headers="headers"
                    :items="items"
                    :search="search"
                    fixed-header
                          hide-default-footer
                          :loading ="Loading"
                    loading-text="Cargando... Por favor espere."
              no-data-text="Aún no hay cotizaciones registradas"
                          dense
                  >
  
                          <template v-slot:item.action="{ item }" > 
                              <v-btn  color="green" class="ma-1"  small dark  @click="infoVisita(item.selectable)">
                                      <v-icon> mdi-eye </v-icon>
                                  </v-btn> 
                          </template>
  
  
                          <!-- <template v-slot:item.fecha_visita_programada="{ item }"> -->
                <!-- {{  moment(item.fecha_visita_programada, "YYYY-MM-DD HH:mm:ss").format('LL') }} -->
                              <!-- <span v-if="item.fecha_visita_programada < fecha_actual"   class="red--text">    {{  moment(item.fecha_visita_programada).format('LL') }} </span>
                              <span v-if="item.fecha_visita_programada === fecha_actual" class="orange--text"> {{  moment(item.fecha_visita_programada).format('LL') }} </span>
                              <span v-if="item.fecha_visita_programada > fecha_actual"   class="green--text">  {{  moment(item.fecha_visita_programada).format('LL') }} </span> -->
                          <!-- </template> -->
  
                          <!-- <template v-slot:item.nota="{ item }" >
                              <span class="font-weight-bold caption blue--text text-justify" v-if="item.nota"> {{ item.nota }} </span> 
                              <span class="font-weight-bold caption blue--text text-justify" v-else> {{ item.nota }} </span> 
                          </template> -->
                  </v-data-table>
                </v-card>
  
  
          <v-dialog persistent v-model="modal" width="700px" >	
            <v-card class="pa-5">
              <!-- <Modal :data="data" :showAlert="showAlert" :consulta="consulta" @modal="modal = $event"/> -->
            </v-card>
          </v-dialog>
  
          
            </v-col>
        
        </v-row>
    </v-main>
  
    </template>
    
    <script>
  
      // var moment = require('moment'); 
      // moment.locale('es')
  
      import moment from 'moment';
  
    //   import Modal from './VisitasModal.vue';
      import axios from 'axios'
  
      import { mapState, mapMutations } from "vuex";
  
    
      export default {
  
        computed:{
          ...mapState("Session", ['session_key', 'user','permisos', 'loggedin']),
          ...mapState(['router']),
        },
  
        props: {
          showAlert: Function,
          hasPermission: Function
        },
        components: {
        //   Modal
        },
        data () {
            return {
              tableName:'cotizaciones',
              search: '',
              itemsPerPage: 100,
              items:[],
              data:{},
              Loading:false,
              modal: false,
              headers:null,
              
              estatus: {},
              Estatus:[],
  
              fecha_actual:moment().format("YYYY-MM-DD"),
  
              fecha1: moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD"),
              fechamodal1:false,
              fecha2: moment().subtract('months').endOf('months').format("YYYY-MM-DD"),
              fechamodal2:false,
              fecha_actual: '',
  
            }
          },
          
          async created(){
            this.headers=this.headersData();
            if(this.user.role_name !== 'Cliente'){
              this.Estatus=[
                { id: 0, nombre:'Pendiente'},
                { id: 1, nombre:'Enviada al cliente' },
                { id: 2, nombre:'Autorizada'},
                { id: 3, nombre:'Rechazada'},
                { id: 4, nombre:'Cancelada'}
              ];
              this.estatus = {  id: 0, nombre:'Pendiente'};
            } else {
              this.Estatus=[
                { id: 1, nombre:'Pendiente' },
                { id: 2, nombre:'Autorizadas'},
                { id: 3, nombre:'Rechazadas'}
              ];
              this.estatus = {  id: 1, nombre:'Pendiente'};
            }
            
            this.consulta();

            

            // if(this.session_data.key_role == 'tecnico'){
            //   this.estatus = this.Estatus[1];  
            // } else {
            //   this.estatus = this.Estatus[0];
            // }
          },
    
          
  
          watch: {
            estatus(){
              this.consulta();
            }
          },
    
          methods:{
            ...mapMutations(['RouterParams']),
            headersData(){
              let headers = [
                { title: 'Fecha'	 , align: 'left'  , key: 'date_created' 	},
                { title: 'No. cotización'  					 , align: 'left'  , key: 'id_cotizacion' },
                { title: 'Responsable'	 , align: 'left'  , key: 'responsable' }
              ];
  
              //responsables: (quien agendo la visita) ??
              //tecnico titular (responsables)
              //auxiliar
  
  
              if(this.user.role_name !== 'Cliente'){
                headers.push({ title: 'Cliente'	 , align: 'left'  , key: 'cliente' });
              }
  
              headers.push(
                
                // { title: 'Servicio'			 , align: 'left'  , key: 'servicio' 	},
                // { title: 'Tipo solicitud'	 , align: 'left'  , key: 'tipo_cot' 	},

                
                { title: ''  			 , align: 'right' , key: 'action', sortable: false }
              );
  
              return headers;
            },
  
            infoVisita(item){
                this.$router.push({name:'DetCotizacionComponent', params:{id_cotizacion:item.id_cotizacion}});
            },
  
            
            async consulta(){
              this.Loading=true;
  
              let payload = {
                estatus:this.estatus.id,
                id_cliente:this.user.role_name == 'Cliente' ? this.user.id_usr : null
              }
  
              axios.post('cotizaciones.consulta', payload).then((res)=>{
                this.items = res.data;
              }).catch((err)=>{
                console.log(err);
              }).finally( ()=> {
                this.Loading=false;
              });
            },
          }
      }
    </script>
    