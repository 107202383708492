<template>
    <div>



        <v-row no-gutters>
            <v-col cols="12" md="3" class="fondo_amarillo">
                <br /><br /><br />
            </v-col>
            <v-col cols="12" md="6" class="fondo_negro">

                <center>
                    <h2>CHECK LIST NEUMÁTICO</h2>
                </center>

            </v-col>
            <v-col cols="12" md="3" class="fondo_amarillo">
                <br /><br /><br />
            </v-col>
        </v-row>

        <v-row no-gutters class="mt-2">
            <v-col cols="12" md="3" class="fondo_amarillo">
                <center> DATOS DEL CLIENTE</center>

            </v-col>
            <v-col cols="12" md="3" class="fondo_negro">

                <center>DATOS DE LA GRUA</center>

            </v-col>
            <v-col cols="12" md="3" class="fondo_amarillo">

                <center>DATOS DEL POLIPASTO</center>


            </v-col>
            <v-col cols="12" md="3" class="fondo_negro">
                <center>MANTENIMIENTO</center>

            </v-col>
        </v-row>


        <v-row no-gutters>
            <v-col cols="12" md="3" class="pa-2">

                <v-text-field label="Cliente"  density="compact"></v-text-field>
                <v-text-field label="Ubicación"  density="compact"></v-text-field>
                <v-text-field label="Contacto"  density="compact"></v-text-field>
                <v-text-field label="Telefono"  density="compact"></v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="pa-2">


                <v-text-field label="Modelo de grúa"  density="compact"></v-text-field>
                <v-text-field label="Capacidad de carga"  density="compact"></v-text-field>
                <v-text-field label="Izaje"  density="compact"></v-text-field>
                <v-text-field label="Claro"  density="compact"></v-text-field>
                <v-text-field label="Recorrido"  density="compact"></v-text-field>

            </v-col>
            <v-col cols="12" md="3" class="pa-2">


                <v-text-field label="Marca"  density="compact"></v-text-field>
                <v-text-field label="Modelo"  density="compact"></v-text-field>
                <v-text-field label="Serie"  density="compact"></v-text-field>
                <v-text-field label="Capacidad"  density="compact"></v-text-field>
                <v-text-field label="Elevación"  density="compact"></v-text-field>

            </v-col>
            <v-col cols="12" md="3" class="pa-2">
                <v-text-field label="Número de maquina"  density="compact"></v-text-field>
                <v-text-field label="Planta"  density="compact"></v-text-field>
                <v-text-field label="Línea"  density="compact"></v-text-field>
            </v-col>
        </v-row>


        <v-row no-gutters>
            <v-col cols="12" md="12" class="fondo_negro">
                <center>
                    MANTENIMIENTO PREVENTIVO DE POLIPASTO NEUMÁTICO
                </center>
            </v-col>

        </v-row>





        <v-stepper v-model="paso" :items="items_menu_steps" prev-text="anterior" next-text="siguente">
            <template v-for="(step, index) in items_menu_steps" :v-slot:item="`item.${index}`" :key="index">
                <v-card v-show="index + 1 == paso" :title="step.title" flat>


                    <v-sheet border>
                        <v-table>
                            <thead>

                                <tr>
                                    <th>CHECKLIST POLIPASTO</th>
                                    <th colspan="4">
                                        <center>ACCIONES PREVENTIVAS</center>
                                    </th>
                                    <th colspan="4">
                                        <center>PATOLÓGIAS</center>
                                    </th>
                                    <th colspan="2">
                                        <center>PINTURA</center>
                                    </th>
                                    <th colspan="3">
                                        <center>ESTADO</center>
                                    </th>

                                </tr>

                                <tr>
                                    <th>Componente</th>
                                    <th><span>Ajuste</span></th>
                                    <th><span>Lubricación</span></th>
                                    <th><span>Apriete</span></th>
                                    <th><span>Limpieza</span></th>

                                    <th><span>Humedad</span></th>
                                    <th><span>Desgaste</span></th>
                                    <th><span>Fisuras</span></th>
                                    <th><span>Deformaciones</span></th>
                                    <th><span>Agrietamiento</span></th>
                                    <th><span>Descamación</span></th>
                                    <th><span>Optimo</span></th>
                                    <th><span>Reparar</span></th>
                                    <th><span>Cambiar</span></th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="(comp, ix) in step.items" :key="ix">
                                    <td>
                                        {{ comp.subtitle }}
                                    </td>
                                    <td>
                                        <v-checkbox v-model="comp.selected_r" value="1"></v-checkbox>
                                    </td>
                                    <td>
                                        <v-checkbox v-model="comp.selected_r" value="2"></v-checkbox>
                                    </td>
                                    <td>
                                        <v-checkbox v-model="comp.selected_r" value="3"></v-checkbox>
                                    </td>
                                    <td>
                                        <v-checkbox v-model="comp.selected_r" value="4"></v-checkbox>
                                    </td>
                                    <td>
                                        <v-checkbox v-model="comp.selected_r" value="5"></v-checkbox>
                                    </td>
                                    <td>
                                        <v-checkbox v-model="comp.selected_r" value="6"></v-checkbox>
                                    </td>
                                    <td>
                                        <v-checkbox v-model="comp.selected_r" value="7"></v-checkbox>
                                    </td>
                                    <td>
                                        <v-checkbox v-model="comp.selected_r" value="8"></v-checkbox>
                                    </td>
                                    <td>
                                        <v-checkbox v-model="comp.selected_r" value="9"></v-checkbox>
                                    </td>
                                    <td>
                                        <v-checkbox v-model="comp.selected_r" value="10"></v-checkbox>
                                    </td>
                                    <td>
                                        <v-checkbox v-model="comp.selected_r" value="11"></v-checkbox>
                                    </td>
                                    <td>
                                        <v-checkbox v-model="comp.selected_r" value="12"></v-checkbox>
                                    </td>
                                    <td>
                                        <v-checkbox v-model="comp.selected_r" value="13"></v-checkbox>
                                    </td>


                                </tr>
                            </tbody>
                        </v-table>
                    </v-sheet>
                </v-card>
            </template>
        </v-stepper>

        <br />
        <v-row no-gutters class="mt-2">
            <v-col cols="12" md="2" class="fondo_negro">
                Prueba de carga
                Funcionamiento
                <v-radio-group>
                    <v-radio label="Correcto" value="1"></v-radio>
                    <v-radio label="Incorrecto" value="2"></v-radio>
                </v-radio-group>

            </v-col>
            <v-col cols="12" md="2" class="fondo_negro">
                Prueba de frenado
                Funcionamiento
                <v-radio-group>
                    <v-radio label="Correcto" value="1"></v-radio>
                    <v-radio label="Incorrecto" value="2"></v-radio>
                </v-radio-group>

            </v-col>
            <v-col cols="12" md="2" class="fondo_negro">
                Velocidad de subida
                Funcionamiento
                <v-radio-group>
                    <v-radio label="Correcto" value="1"></v-radio>
                    <v-radio label="Incorrecto" value="2"></v-radio>
                </v-radio-group>
            </v-col>
            <v-col cols="12" md="2" class="fondo_negro">
                Velocidad de bajada
                Funcionamiento
                <v-radio-group>
                    <v-radio label="Correcto" value="1"></v-radio>
                    <v-radio label="Incorrecto" value="2"></v-radio>
                </v-radio-group>
            </v-col>
            <v-col cols="12" md="3">
                Nombre y firma de supervisor
            </v-col>
        </v-row>

        <v-row no-gutters class="mt-2">
            <v-col cols="12" md="2" class="pa-2">
                <v-textarea label="Observaciones"></v-textarea>
            </v-col>
            <v-col cols="12" md="2" class="pa-2">
                <v-textarea label="Observaciones"></v-textarea>
            </v-col>
            <v-col cols="12" md="2" class="pa-2">
                <v-textarea label="Observaciones"></v-textarea>
            </v-col>
            <v-col cols="12" md="2" class="pa-2">
                <v-textarea label="Observaciones"></v-textarea>
            </v-col>
            <v-col cols="12" md="3">
                Nombre y firma de supervisor
            </v-col>
        </v-row>

        <v-row no-gutters class="mt-2">
           <!--  <v-col cols="12" md="3" class="fondo_negro">
                <center> DATOS DEL CLIENTE</center>
            </v-col>
            <v-col cols="12" md="3" class="fondo_amarillo">
                <center>DATOS DE LA GRUA</center>
            </v-col> -->
            <v-col cols="12" md="12" class="fondo_negro">
                <center>DATOS DEL POLIPASTO</center>
            </v-col>
        </v-row>

        <v-row no-gutters class="mt-2">
           <!--  <v-col cols="12" md="3">
                <v-text-field label="Cliente" variant="solo" density="compact"></v-text-field>
                <v-text-field label="Cliente" variant="solo" density="compact"></v-text-field>
                <v-text-field label="Cliente" variant="solo" density="compact"></v-text-field>
                <v-text-field label="Cliente" variant="solo" density="compact"></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
                <v-text-field label="Cliente" variant="solo" density="compact"></v-text-field>
                <v-text-field label="Cliente" variant="solo" density="compact"></v-text-field>
                <v-text-field label="Cliente" variant="solo" density="compact"></v-text-field>
                <v-text-field label="Cliente" variant="solo" density="compact"></v-text-field>
                <v-text-field label="Cliente" variant="solo" density="compact"></v-text-field>
            </v-col> -->
            <v-col cols="12" md="12" class="fondo_negro">

                <v-row no-gutters class="mt-2">
                    <v-col cols="12" md="4">
                        TIPO DE SERVICIO
                        <v-radio-group>
                            <v-radio label="PREVENTIVO" value="1"></v-radio>
                            <v-radio label="CORRECTIVO" value="2"></v-radio>
                            <v-radio label="INTALACIÓN" value="3"></v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-radio-group>
                            <v-radio label="REVISIÓN" value="1"></v-radio>
                            <v-radio label="LEVANTAMIENTO" value="2"></v-radio>
                            <v-radio label="PRUEBA DE CARGA" value="3"></v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-radio-group>
                            <v-radio label="MONITOREO" value="1"></v-radio>
                            <v-radio label="CURSO DE CAP" value="2"></v-radio>
                            <v-radio label="ENTREGA DE PROYECTO" value="3"></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>


            </v-col>
        </v-row>

        <v-row no-gutters class="mt-2">
            <v-col cols="12" md="12" class="fondo_amarillo">
                <center>MANTENIMIENTO E INSPECCIÓN PREVENTIVA ESTRUCTURAL</center>
            </v-col>
        </v-row>

        <v-sheet border>
            <v-table>
                <thead>
                    <tr>
                        <th style="background-color:#000000; color:#ffffff;"></th>
                        <th colspan="6" style="background-color:#000000; color:#ffffff;">
                            <center>ACTIVIADES PREVENTIVAS</center>
                        </th>
                        <th colspan="2" style="background-color:#000000; color:#ffffff;">
                            <center>PINTURA</center>
                        </th>
                        <th colspan="2" style="background-color:#000000; color:#ffffff;">
                            <center>ESTADO</center>
                        </th>
                    </tr>
                    <tr>
                        <th>CHECKLIST ESTRUCTURAL</th>
                        <th>Fisura</th>
                        <th>Feformación</th>
                        <th>Fractura</th>
                        <th>O (P)</th>
                        <th>O (G)</th>
                        <th>o (S)</th>
                        <th>Agrietamiento</th>
                        <th>Descamación</th>
                        <th>Óptimo</th>
                        <th>Dañado</th>
                    </tr>
                </thead>

                <tbody v-for="comp, index in tb_check_estr" :key="index">
                    <tr>
                        <td>
                            {{ comp.title }}
                        </td>
                        <td>
                            <v-checkbox v-model="comp.selected_r" value="1"></v-checkbox>
                        </td>
                        <td>
                            <v-checkbox v-model="comp.selected_r" value="2"></v-checkbox>
                        </td>
                        <td>
                            <v-checkbox v-model="comp.selected_r" value="3"></v-checkbox>
                        </td>
                        <td>
                            <v-checkbox v-model="comp.selected_r" value="4"></v-checkbox>
                        </td>
                        <td>
                            <v-checkbox v-model="comp.selected_r" value="5"></v-checkbox>
                        </td>
                        <td>
                            <v-checkbox v-model="comp.selected_r" value="6"></v-checkbox>
                        </td>
                        <td>
                            <v-checkbox v-model="comp.selected_r" value="7"></v-checkbox>
                        </td>
                        <td>
                            <v-checkbox v-model="comp.selected_r" value="8"></v-checkbox>
                        </td>
                        <td>
                            <v-checkbox v-model="comp.selected_r" value="9"></v-checkbox>
                        </td>
                        <td>
                            <v-checkbox v-model="comp.selected_r" value="10"></v-checkbox>
                        </td>
                    </tr>
                </tbody>
            </v-table>
        </v-sheet>


        <v-sheet border>
            <v-table>
                <thead>
                    <tr>
                        <th style="background-color:#ffc000; color:#ffffff;"></th>
                        <th colspan="6" style="background-color:#ffc000; color:#ffffff;">
                            <center>ACTIVIADES PREVENTIVAS</center>
                        </th>
                        <th colspan="2" style="background-color:#ffc000; color:#ffffff;">
                            <center>PINTURA</center>
                        </th>
                        <th colspan="2" style="background-color:#ffc000; color:#ffffff;">
                            <center>ESTADO</center>
                        </th>
                    </tr>
                    <tr>
                        <th>CHECKLIST ESTRUCTURAL</th>
                        <th>Fisura</th>
                        <th>Feformación</th>
                        <th>Fractura</th>
                        <th>O (P)</th>
                        <th>O (G)</th>
                        <th>o (S)</th>
                        <th>Agrietamiento</th>
                        <th>Descamación</th>
                        <th>Óptimo</th>
                        <th>Dañado</th>
                    </tr>
                </thead>

                <tbody v-for="comp, index in tb_check_trole" :key="index">
                    <tr>
                        <td>
                            {{ comp.title }}
                        </td>
                        <td>
                            <v-checkbox v-model="comp.selected_r" value="1"></v-checkbox>
                        </td>
                        <td>
                            <v-checkbox v-model="comp.selected_r" value="2"></v-checkbox>
                        </td>
                        <td>
                            <v-checkbox v-model="comp.selected_r" value="3"></v-checkbox>
                        </td>
                        <td>
                            <v-checkbox v-model="comp.selected_r" value="4"></v-checkbox>
                        </td>
                        <td>
                            <v-checkbox v-model="comp.selected_r" value="5"></v-checkbox>
                        </td>
                        <td>
                            <v-checkbox v-model="comp.selected_r" value="6"></v-checkbox>
                        </td>
                        <td>
                            <v-checkbox v-model="comp.selected_r" value="7"></v-checkbox>
                        </td>
                        <td>
                            <v-checkbox v-model="comp.selected_r" value="8"></v-checkbox>
                        </td>
                        <td>
                            <v-checkbox v-model="comp.selected_r" value="9"></v-checkbox>
                        </td>
                        <td>
                            <v-checkbox v-model="comp.selected_r" value="10"></v-checkbox>
                        </td>
                    </tr>
                </tbody>
            </v-table>
        </v-sheet>


        <v-row no-gutters class="mt-2">
            <v-col cols="12" md="12" class="fondo_amarillo">
                <center>DETERMINAR EL ESTADO DE LA ESTRUCTURA</center>
            </v-col>
        </v-row>

        <v-row no-gutters class="mt-2">
            <v-col cols="12" md="3">
                <center>
                    <div style="background-color:#ffc000; color:#ffffff; padding: 10px;">ESCALA DE CONDICIÓN</div>

                    <!-- MEQUEDE AQUI -->
                    <v-sheet border>
                        <v-table>
                            <thead>
                                <tr>
                                    <td style="background-color:#ffc000; color:#ffffff; padding: 10px;">CONDICIÓN</td>
                                    <td style="background-color:#ffc000; color:#ffffff; padding: 10px;">PUNTUACIÓN</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Satisfactorio</td>
                                    <td>6</td>
                                </tr>
                                <tr>
                                    <td>Aceptable</td>
                                    <td>5.0-5</td>
                                </tr>
                                <tr>
                                    <td>Satisfactorio</td>
                                    <td>6</td>
                                </tr>
                                <tr>
                                    <td>Satisfactorio</td>
                                    <td>6</td>
                                </tr>
                                <tr>
                                    <td>Satisfactorio</td>
                                    <td>6</td>
                                </tr>
                                <tr>
                                    <td>Satisfactorio</td>
                                    <td>6</td>
                                </tr>

                            </tbody>
                        </v-table>
                    </v-sheet>
                </center>
            </v-col>
            <v-col cols="12" md="3">
                <center>
                    <div style="background-color:#000000; color:#ffffff; padding: 10px;">TABLA DE PATOLOGÍAS</div>



                    <v-sheet border>
                        <v-table>
                            <thead>
                                <tr>
                                    <td style="background-color:#000000; color:#ffffff; padding: 10px;">Físicas</td>
                                    <td>
                                        <p>Humedad</p>
                                        <p>Decoloración</p>
                                        <p>Suciedad</p>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Mecánica</td>
                                    <td>
                                        <p>Agrietamiento</p>
                                        <p>Deformación</p>
                                        <p>Fisuramiento</p>
                                        <p>Fractura</p>
                                        <p>Desprendimiento</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="background-color:#000000; color:#ffffff; padding: 10px;">Físicas</td>
                                    <td>
                                        <p>Oxidación</p>
                                        <p>Corrosión</p>
                                    </td>
                                </tr>
                            </tbody>
                        </v-table>
                    </v-sheet>
                </center>
            </v-col>
            <v-col cols="12" md="3">
                <center>

                    <div style="background-color:#ffc000; color:#ffffff; padding: 10px;">GRADO DE CORROSIÓN</div>


                    <v-sheet border>
                        <v-table>
                            <thead>
                                <tr>
                                    <td style="background-color:#ffc000; color:#ffffff; padding: 10px;"># de puntuación</td>
                                    <td style="background-color:#ffc000; color:#ffffff; padding: 10px;">% de superficie
                                        corroida</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>9</td>
                                    <td>.1 a .3%</td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>.3 a 1%</td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>1 a 3%</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>3 a 10%</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>10 al 16%</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>16 a 33%</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>33 a 50%</td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>50 a 100%</td>
                                </tr>
                            </tbody>
                        </v-table>
                    </v-sheet>


                </center>
            </v-col>
            <v-col cols="12" md="3">
                <center>
                    <div style="background-color:#000000; color:#ffffff; padding: 10px;">GRADO DE DESCAMACIÓN</div>


                    <v-sheet border>
                        <v-table>
                            <thead>
                                <tr>
                                    <td style="background-color:#000000; color:#ffffff; padding: 10px;">GRADO</td>
                                    <td style="background-color:#000000; color:#ffffff; padding: 10px;">% DESCAMACIÓN</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>10</td>
                                    <td>Sin descamación</td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>De .1 a .3%</td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>De 1 a 3%</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>De 10 a 16%</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>De 33 a 50%</td>
                                </tr>
                                <tr>
                                    <td>0</td>
                                    <td>Más de 50%</td>
                                </tr>
                            </tbody>
                        </v-table>
                    </v-sheet>
                </center>
            </v-col>
        </v-row>

        <v-row no-gutters class="mt-2">
            <v-col cols="12" md="12" class="fondo_amarillo">
                <center>FACTORES EXTERNOS QUE AFECTAN A LA ESTRUCTURA</center>
            </v-col>
        </v-row>

        <center> <v-textarea label="Observaciones"></v-textarea></center>

        <v-row no-gutters class="mt-2">
            <v-col cols="12" md="6" class="fondo_negro">
                <center>PUNTOS IMPORTANTES PARA EL SIG MANTENIMIENTO

                </center>
            </v-col>
            <v-col cols="12" md="6" class="fondo_negro">
                <center>CONCLUSIONES

                </center>
            </v-col>
        </v-row>

        <v-row no-gutters class="mt-2">
            <v-col cols="12" md="6">

                <center> <v-textarea label=""></v-textarea></center>

            </v-col>
            <v-col cols="12" md="6">
                <center> <v-textarea label=""></v-textarea></center>
            </v-col>
        </v-row>





        <v-row no-gutters class="mt-2">
            <v-col cols="12" md="12" class="fondo_negro">
                <center>RECOLECCIÓN DE DATOS DE GANCHO</center>
            </v-col>
        </v-row>

        <v-row no-gutters class="mt-2">
            <v-col cols="12" md="6">
                <center>

                    <div style="background-color:#ffc000; color:#ffffff; padding: 10px;">GANCHO DE SUSPENCIÓN</div>
                    <v-row no-gutters class="mt-2">
                        <v-col cols="12" md="6">
                            <v-text-field label="Capacidad Normal" variant="solo" density="compact"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="Máxima Torción 10'" variant="solo" density="compact"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="Apertura de garganta" variant="solo" density="compact"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="Seguro" variant="solo" density="compact"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="Asiento de gancho" variant="solo" density="compact"></v-text-field>
                        </v-col>
                    </v-row>


                </center>
            </v-col>
            <v-col cols="12" md="6">
                <center>
                    <div style="background-color:#ffc000; color:#ffffff; padding: 10px;">GANCHO DE CARGA</div>

                    <v-row no-gutters class="mt-2">
                        <v-col cols="12" md="6">
                            <v-text-field label="Capacidad Normal" variant="solo" density="compact"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="Máxima Torción 10'" variant="solo" density="compact"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="Apertura de garganta" variant="solo" density="compact"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="Seguro" variant="solo" density="compact"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field label="Asiento de gancho" variant="solo" density="compact"></v-text-field>
                        </v-col>
                    </v-row>
                </center>
            </v-col>


        </v-row>

        <v-row no-gutters class="mt-2">
            <v-col cols="12" md="12" class="fondo_negro">
                <center>CÁLCULO DE MANTENIMIENTO A POLIPASTOS</center>
            </v-col>
        </v-row>

        <v-row no-gutters class="mt-2">
            <v-col cols="12" md="2">
                <center>
                    OPERACIÓN DEL POLIPASTO
                    <hr />
                    Normal- Anual
                    <hr />
                    Severa - Semestral
                    <hr />
                    Extrema - Trimestral

                </center>
            </v-col>
            <v-col cols="12" md="3">
                <center>
                    RELACIÓN DE % DE OPERACIÓN
                    <hr />
                    De 1% a 25% del ciclo de trabajo
                    <hr />
                    De 26% a 50% del ciclo de trabajo
                    <hr />
                    Mayor de 50% del ciclo de trabajo

                </center>
            </v-col>
            <v-col cols="12" md="3">
                <center>
                    RELACIÓN PORCENTAL DE CARGA
                    <hr />
                    Menor al 30% de la capacidad normal
                    <hr />
                    Hasta el 60% de la capacidad normal
                    <hr />
                    Mayor de 60% de la capacidad normal

                </center>
            </v-col>
            <v-col cols="12" md="2">
                <center>
                    TIEMPO ENTRE SERVICIOS
                    <hr />
                    3200 HORAS
                    <hr />
                    1600 HORAS
                    <hr />
                    700 HORAS

                </center>
            </v-col>
            <v-col cols="12" md="2">
                <center>
                    GRASA PARA ENGRANAJES
                    <hr />
                    200 HORAS

                </center>
            </v-col>
        </v-row>


        <v-row no-gutters class="mt-2">
            <v-col cols="12" md="12" class="fondo_negro">
                <center>


                    <v-sheet border>
                        <v-table>
                            <thead>
                                <tr>
                                    <td style="background-color:#000000; color:#ffffff; padding: 10px;"></td>
                                    <td style="background-color:#000000; color:#ffffff; padding: 10px;"></td>
                                    <td style="background-color:#000000; color:#ffffff; padding: 10px;" colspan="2">CARGA
                                    </td>
                                    <td style="background-color:#000000; color:#ffffff; padding: 10px;" colspan="2">
                                        OPERACIÓN</td>
                                    <td style="background-color:#000000; color:#ffffff; padding: 10px;" colspan="2">% DE
                                        OPERACIÓN</td>
                                    <td style="background-color:#000000; color:#ffffff; padding: 10px;" colspan="3">TIPO DE
                                        SERVICIO</td>

                                </tr>
                                <tr>
                                    <td style="background-color:#000000; color:#ffffff; padding: 10px;"></td>
                                    <td style="background-color:#000000; color:#ffffff; padding: 10px;">PROCESOS</td>
                                    <td>Nominal</td>
                                    <td>Real</td>
                                    <td>Jornada</td>
                                    <td>Proceso</td>
                                    <td>Porcentaje <br />de operación</td>
                                    <td>Normal</td>
                                    <td>Severo</td>
                                    <td>Extremo</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td><v-text-field label="" variant="solo" density="compact"
                                            width="400px"></v-text-field></td>
                                    <td> <v-checkbox value="1"></v-checkbox></td>
                                    <td><v-checkbox value="1"></v-checkbox></td>
                                    <td><v-checkbox value="1"></v-checkbox></td>
                                    <td><v-checkbox value="1"></v-checkbox></td>
                                    <td><v-checkbox value="1"></v-checkbox></td>
                                    <td><v-checkbox value="1"></v-checkbox></td>
                                    <td><v-checkbox value="1"></v-checkbox></td>
                                    <td><v-checkbox value="1"></v-checkbox></td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td><v-text-field label="" variant="solo" density="compact"></v-text-field></td>
                                    <td> <v-checkbox value="1"></v-checkbox></td>
                                    <td><v-checkbox value="1"></v-checkbox></td>
                                    <td><v-checkbox value="1"></v-checkbox></td>
                                    <td><v-checkbox value="1"></v-checkbox></td>
                                    <td><v-checkbox value="1"></v-checkbox></td>
                                    <td><v-checkbox value="1"></v-checkbox></td>
                                    <td><v-checkbox value="1"></v-checkbox></td>
                                    <td><v-checkbox value="1"></v-checkbox></td>

                                </tr>

                            </tbody>
                        </v-table>
                    </v-sheet>

                </center>
            </v-col>
        </v-row>


        <v-row no-gutters class="mt-2">
            <v-col cols="12" md="12" class="fondo_negro">
                <center>OBSERVACIONES Y RECOMENDACIONES</center>
            </v-col>
        </v-row>


        <v-row no-gutters class="mt-2">
            <v-col cols="12" md="3">
                INSTALACIÓN
            </v-col>
            <v-col cols="12" md="8">

                <v-text-field label="" variant="solo" density="compact"></v-text-field>


            </v-col>
        </v-row>

        <v-row no-gutters class="mt-2">
            <v-col cols="12" md="3">
                OPERACIÓN
            </v-col>
            <v-col cols="12" md="8">
                <v-text-field label="" variant="solo" density="compact"></v-text-field>
            </v-col>
        </v-row>

        <v-row no-gutters class="mt-2">
            <v-col cols="12" md="3">
                MANTENIMIENTO
            </v-col>
            <v-col cols="12" md="8">
                <v-text-field label="" variant="solo" density="compact"></v-text-field>
            </v-col>
        </v-row>



        <v-row no-gutters class="mt-2">
            <v-col cols="12" md="12" class="fondo_negro">
                <center>POSIBLES FALLAS Y RECOMENDACIONES</center>
            </v-col>
            <v-col cols="12" md="12" class="fondo_amarillo">
                <center>TABLA PARA LA RESOLUCIÓN DE PROBLEMAS</center>
            </v-col>
        </v-row>

        <v-row no-gutters class="mt-2">
            <v-col cols="12" md="6">
                <center>


                    <h2>INSPECCIONAR</h2>

                    <v-sheet border>
                        <v-table>
                            <thead>
                            </thead>
                            <tbody>
                                <tr class="fondo_amarillo">
                                    <td colspan="4">EL POLIPASTO NO OPERA</td>
                                </tr>

                                <tr v-for="item, index in p_fallas_polipasto">
                                    <td>{{ item.title }}</td>
                                    <td><v-checkbox value="1"></v-checkbox></td>
                                </tr>
                                <tr class="fondo_negro">
                                    <td colspan="4">NO SOSTIENE LA CARGA</td>
                                </tr>

                                <tr v-for="item, index in p_fallas_carga">
                                    <td>{{ item.title }}</td>
                                    <td><v-checkbox value="1"></v-checkbox></td>
                                </tr>

                                <tr class="fondo_amarillo">
                                    <td colspan="4">NO SUBE LA CARGA</td>
                                </tr>

                                <tr v-for="item, index in p_fallas_no_carga">
                                    <td>{{ item.title }}</td>
                                    <td><v-checkbox value="1"></v-checkbox></td>
                                </tr>
                                <tr class="fondo_negro">
                                    <td colspan="4">LEVANTA LA CARGA SIN QUE SE ACTIVE UP</td>
                                </tr>


                                <tr v-for="item, index in p_fallas_levanta">
                                    <td>{{ item.title }}</td>
                                    <td><v-checkbox value="1"></v-checkbox></td>
                                </tr>
                                <tr class="fondo_amarillo">
                                    <td colspan="4">BAJA CARGA SIN ACTIVAR DOWN</td>
                                </tr>

                                <tr v-for="item, index in p_fallas_baja">
                                    <td>{{ item.title }}</td>
                                    <td><v-checkbox value="1"></v-checkbox></td>
                                </tr>
                                <tr class="fondo_negro">
                                    <td colspan="4">OPERA EN LA DIRECCIÓN CONTRARIA</td>
                                </tr>

                                <tr v-for="item, index in p_fallas_opera">
                                    <td>{{ item.title }}</td>
                                    <td><v-checkbox value="1"></v-checkbox></td>
                                </tr>
                            </tbody>
                        </v-table>
                    </v-sheet>

                </center>


            </v-col>

            <v-col cols="12" md="6">
                <center>
                    <h2>RECOMENDACIONES</h2>
                    <v-sheet border>
                        <v-table>
                            <thead>
                            </thead>
                            <tbody>
                                <tr class="fondo_amarillo">
                                    <td colspan="4">OPERACIONES</td>
                                </tr>

                                <tr v-for="item, index in p_fallas_polipasto_rec">
                                    <td>{{ item.title }}</td>
                                    <td><v-checkbox value="1"></v-checkbox></td>
                                </tr>
                                <tr class="fondo_amarillo">
                                    <td colspan="4">SEGURIDAD</td>
                                </tr>

                                <tr v-for="item, index in p_fallas_carga_rec">
                                    <td>{{ item.title }}</td>
                                    <td><v-checkbox value="1"></v-checkbox></td>
                                </tr>

                                <tr class="fondo_amarillo">
                                    <td colspan="4">SALUD</td>
                                </tr>

                                <tr v-for="item, index in p_fallas_no_carga_rec">
                                    <td>{{ item.title }}</td>
                                    <td><v-checkbox value="1"></v-checkbox></td>
                                </tr>


                                <tr v-for="item, index in p_fallas_levanta_rec">
                                    <td>{{ item.title }}</td>
                                    <td><v-checkbox value="1"></v-checkbox></td>
                                </tr>


                                <tr v-for="item, index in p_fallas_baja_rec">
                                    <td>{{ item.title }}</td>
                                    <td><v-checkbox value="1"></v-checkbox></td>
                                </tr>


                                <tr v-for="item, index in p_fallas_opera_rec">
                                    <td>{{ item.title }}</td>
                                    <td><v-checkbox value="1"></v-checkbox></td>
                                </tr>
                            </tbody>
                        </v-table>
                    </v-sheet>
                </center>
            </v-col>
        </v-row>

        <br />
        <div class="fondo_negro">
            <h2>ADVERTENCIAS DE OPERACIÓN</h2>
            <v-row no-gutters class="mt-2">
                <v-col cols="12" md="3">
                    <center>
                        <v-radio-group>
                            <v-radio label="No se puede operar" value="1"></v-radio>
                            <v-radio label="Operar solo lo indispensable" value="2"></v-radio>
                            <v-radio label="Otros..." value="2"></v-radio>
                        </v-radio-group>
                    </center>
                </v-col>

                <v-col cols="12" md="9">
                    <center>
                        <v-textarea label="Observaciones"></v-textarea>
                    </center>
                </v-col>
            </v-row>
        </div>
    </div>
</template>
<script>
import infochecklist from "./CheckListNeumatico.json"
export default {
    data() {
        return {
            items_menu_steps: infochecklist,
            paso: 1,
            tb_check_estr: [
                { title: "Tornilos y tuercas de anclaje" },
                { title: "Inclinación de columnas (°)" },
                { title: "Nivel de vigas carril" },
                { title: "Desgaste en rieles de vigas" },
                { title: "Paralelismo de vigas carril" },
                { title: "Cuadraturas de vigas carril" },
                { title: "Alineación de vigas carril" },
                { title: "Tornullos, permis y tuercas de anclaje" },
                { title: "Cordones de soldadura" }
            ],

            tb_check_trole: [
                { title: "Ruedas de desplazamiento" },
                { title: "Perno de suspensión" },
                { title: "Accesorios de suspensión" },
                { title: "Pasador de chaveta" },
                { title: "Tuerca de perno de suspensión" },
                { title: "Separadores" }
            ],
            p_fallas_polipasto: [
                { title: "Perdida de aire alrededor del pistón (ZA)" },
                { title: "Cubierta y/o empaque dañados (ZA)" },
                { title: "La alimentación suministrada no es suficiente" },
                { title: "Fuga de aire en alguna conexión o manguera" },
                { title: "El colgante no funciona" },
                { title: "Palanca de control atascada" },
                { title: "Mala relación del movimiento de flecha y engrane" },
                { title: "Entrada de aire tapada" },
                { title: "Motor de polipasto dañado" }
            ],


            p_fallas_carga: [
                { title: "Pérdida de aire en tornillos de ajuste" },
                { title: "Pérdida de aire en válvula DN de colgante" },
                { title: "Mangueras gastadas o cortadas" },
                { title: "Fugas en conexiones de mangueras" },
                { title: "La alimentación suministrada no es la necesaria" },
                { title: "Capacidad nominal excedida" },
                { title: "Componentes de freno gastados" }
            ],

            p_fallas_no_carga: [
                { title: "Fuga de aire en alguna conexión o manguera" },
                { title: "Capacidad nominal excedida" },
                { title: "Falta de presión de aire" }
            ],

            p_fallas_levanta: [
                { title: "Ajuste de equilibrio de gancho, fijado muy alto" },
                { title: "Pérdida de aure en válvula UP de colgante" },
            ],

            p_fallas_baja: [
                { title: "Pérdida de aire en manguera colgante" },
                { title: "Freno de polipasto mal ajustado" },
                { title: "Pérdida de aire en valvula DN de colgante" },
                { title: "Mangueras mal instaladas" }
            ],

            p_fallas_opera: [
                { title: "Mangueras mal instaladas" }
            ],



            /* RECOMENDACIONES */




            p_fallas_polipasto_rec: [
                { title: "Realizar inspecciones diarias al polipasto" },
                { title: "Si el polipasto no responde a los mandos (No operar)" },
                { title: "No exceder la capacidad de carga nominal" },
                { title: "No balancear la carga" },
                { title: "No dejar la carga suspendida" },
                { title: "No enrollar la cádena en la carga" },
                { title: "Evitar movimientos bruscos e incontrolados de la carga" },
                { title: "Para modelos balancer ZA, el aire debe ser limpio y seco" },

            ],


            p_fallas_carga_rec: [
                { title: "No utilizar polipasto con cadena torcida, girada o dañana" },
                { title: "Utilizar el equipo de protección necesario para la operación" },
                { title: "Instalar válvula de cierre al alcance del operador" },
                { title: "No levantar cargas sobre personas" },
                { title: "No levantar a personas con el polipasto" },
                { title: "Inspeccionar el radio de la operación del polipasto antes de operar" },
                { title: "El sistema debe contar con cable de seguridad" },

            ],

            p_fallas_no_carga_rec: [
                { title: "El operador no debe de contar con alguna indiscapacidad que pueda afectar la operación del polipasto" },


            ],

            p_fallas_levanta_rec: [
                { title: "El operador no debe de sufrir de alguna enfermedad que le impida operar el polipasto (Ataques epilécticos)" },

            ],

            p_fallas_baja_rec: [
                { title: "El operador no tiene que estar bajo la influencia de laugna sustancia alcoh+olicas o alguna groga" },

            ],

            p_fallas_opera_rec: [
                { title: "El operador debe de respetar el número de horas laborales permitidas por el dia, de no ser asi puede afectar en la operación del polipasto" }
            ],















        }
    }
}
</script>
<style>
.fondo_amarillo {
    background-color: #ffc000;
    color: #ffffff;
}

.fondo_negro {
    background-color: #000000;
    color: #ffffff;
}
</style>